import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { findTingkatRisiko, formatDate } from "../../../constants/helper";
import DatePicker from "react-datepicker";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../components";
import { Container, Card, Form, Row, Col, InputGroup } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  IconButton,
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { makeStyles } from "@mui/styles";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahPengajuan = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noAju, setNoAju] = useState("");
  const [tanggalAju, setTanggalAju] = useState(new Date());
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [kodeTingkatRisiko, setKodeTingkatRisiko] = useState("");
  const [kodeTingkatRisikoData, setKodeTingkatRisikoData] = useState({});
  const [ketResikoAju, setKetResikoAju] = useState("");
  const [noSbg, setNoSbg] = useState("");
  const [tglKontrak, setTglKontrak] = useState("");
  const [tglJtTempo, setTglJtTemp] = useState("");
  const [bungaPerBulanAju, setBungaPerBulanAju] = useState("");
  const [pinjamanAju, setPinjamanAju] = useState("");
  const [biayaAdmAju, setBiayaAdmAju] = useState(`${setting.feeAdmGadai}`);

  const [cifCustomer, setCifCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [noKkCustomer, setNoKkCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState("");
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [agamaCustomer, setAgamaCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [ketKelurahan, setKetKelurahan] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [ketKecamatan, setKetKecamatan] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [ketKabupaten, setKetKabupaten] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [ketProvinsi, setKetProvinsi] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");

  const [kodeCOA, setKodeCOA] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [namaJenis, setNamaJenis] = useState("");
  const [bungaPerBulanJenis, setBungaPerBulanJenis] = useState("");

  // Data Penilaian
  const [hargaPasarSetempatOtr, setHargaPasarSetempatOtr] = useState("0");
  const [taksiran, setTaksiran] = useState("0");
  const [maksimalPemberianPinjaman, setMaksimalPemberianPinjaman] =
    useState("0");

  const [customers, setCustomers] = useState([]);
  const [coas, setCoas] = useState([]);
  const [marketings, setMarketings] = useState([]);
  const [tingkatRisikos, setTingkatRisikos] = useState([]);
  const [jenisJaminans, setJenisJaminans] = useState([]);
  const [kelurahans, setKelurahans] = useState([]);
  const [kecamatans, setKecamatans] = useState([]);
  const [kabupatens, setKabupatens] = useState([]);
  const [provinsis, setProvinsis] = useState([]);

  // Data Jaminan
  const [reloadJumlahListJaminan, setReloadJumlahListJaminan] = useState(0);
  const [listJaminan, setListJaminan] = useState([
    {
      namaJam: "",
      ketJam: "",
      hargaPasarSetempatOtrJam: "",
      hargaTafsirJam: "",
      maksimalPemberianPinjamanJam: "",
    },
  ]);

  const [error, setError] = useState(false);
  const [searchTermCustomer, setSearchTermCustomer] = useState("");
  const [openCustomer, setOpenCustomer] = useState(false);
  const [isPickCustomer, setIsPickCustomer] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermCustomer(query);
  };

  const handleClickOpenCustomer = () => {
    setOpenCustomer(true);
  };

  const handleCloseCustomer = () => {
    setOpenCustomer(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));

  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));
  let tingkatRisikoOptions = tingkatRisikos.map((tingkatRisiko) => ({
    label: `${tingkatRisiko.kodeTingkatRisiko} - ${tingkatRisiko.namaTingkatRisiko}`,
  }));

  const jenisKelaminOption = ["LAKI-LAKI", "PEREMPUAN"];
  const agamaOption = [
    "ISLAM",
    "PROTESTAN",
    "KATOLIK",
    "HINDU",
    "BUDHA",
    "KHONGHUCU",
    "LAIN",
  ];
  const statusPerkawinanOption = ["BELUM MENIKAH", "(JANDA/DUDA)", "MENIKAH"];
  const kewarganegaraanOption = ["WNI", "WNA"];
  let kelurahanOptions = kelurahans.map((kelurahan) => ({
    label: `${kelurahan.id} - ${kelurahan.namaKelurahan}`,
  }));
  let kecamatanOptions = kecamatans.map((kecamatan) => ({
    label: `${kecamatan.id} - ${kecamatan.namaKecamatan}`,
  }));
  let kabupatenOptions = kabupatens.map((kabupaten) => ({
    label: `${kabupaten.id} - ${kabupaten.namaKabupaten}`,
  }));
  let provinsiOptions = provinsis.map((provinsi) => ({
    label: `${provinsi.id} - ${provinsi.namaProvinsi}`,
  }));

  const jenisResikoOption = ["RENDAH", "SEDANG", "TINGGI"];

  useEffect(() => {
    getAreaData();
    getCoasData();
    getMarketingsData();
    getTingkatRisikosData();
    getJenisJaminansData();
    getPengajuanNextKode();
    getCustomerNextKode();
  }, []);

  useEffect(() => {
    getCustomersData();
  }, [page, searchTermCustomer]);

  const getAreaData = async (kodeUnit) => {
    const allKelurahan = await axios.post(`${tempUrl}/kelurahans`, {
      _id: user.id,
      token: user.token,
    });
    setKelurahans(allKelurahan.data);

    const allKecamatan = await axios.post(`${tempUrl}/kecamatans`, {
      _id: user.id,
      token: user.token,
    });
    setKecamatans(allKecamatan.data);

    const allKabupaten = await axios.post(`${tempUrl}/kabupatens`, {
      _id: user.id,
      token: user.token,
    });
    setKabupatens(allKabupaten.data);

    const allProvinsi = await axios.post(`${tempUrl}/provinsis`, {
      _id: user.id,
      token: user.token,
    });
    setProvinsis(allProvinsi.data);

    setJenisKelaminCustomer(jenisKelaminOption[0]);
    setAgamaCustomer(agamaOption[0]);
    setStatusPerkawinanCustomer(statusPerkawinanOption[0]);
    setKewarganegaraanCustomer(kewarganegaraanOption[0]);
  };

  const findKelurahan = async (kelurahanId) => {
    if (kelurahanId) {
      const response = await axios.post(
        `${tempUrl}/kelurahans/${kelurahanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKelurahan(kelurahanId);
      if (kelurahanId == "99999999") {
        setKodeProvinsi("");
        setKodeKabupaten("");
        setKodeKecamatan("");
        setKodePos("");
        return;
      }
      if (response.data) {
        setKodeProvinsi(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupaten(
          `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
        );
        setKodeKecamatan(
          `${response.data.kecamatan.id} - ${response.data.kecamatan.namaKecamatan}`
        );
        setKodePos(response.data.kodePos);
      }
    }
  };

  const findKecamatan = async (kecamatanId) => {
    kecamatanId = kecamatanId.split(" ", 1)[0];
    if (kecamatanId) {
      const response = await axios.post(
        `${tempUrl}/kecamatans/${kecamatanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKecamatan(kecamatanId);
      if (kecamatanId == "999999") {
        setKodeProvinsi("");
        setKodeKabupaten("");
        setKodeKecamatan(
          `${response.data.id} - ${response.data.namaKecamatan}`
        );
        return;
      }
      if (response.data) {
        setKodeProvinsi(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupaten(
          `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
        );
        setKodeKecamatan(
          `${response.data.id} - ${response.data.namaKecamatan}`
        );
      }
    } else {
      setKodeKecamatan("");
      setKodeKabupaten("");
      setKodeProvinsi("");
    }
  };

  const findKabupaten = async (kabupatenId) => {
    kabupatenId = kabupatenId.split(" ", 1)[0];
    if (kabupatenId) {
      const response = await axios.post(
        `${tempUrl}/kabupatens/${kabupatenId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKabupaten(kabupatenId);
      if (kabupatenId == "9999") {
        setKodeProvinsi("");
        setKodeKabupaten(
          `${response.data.id} - ${response.data.namaKabupaten}`
        );
        return;
      }
      if (response.data) {
        setKodeProvinsi(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupaten(
          `${response.data.id} - ${response.data.namaKabupaten}`
        );
      }
    } else {
      setKodeKabupaten("");
      setKodeProvinsi("");
    }
  };

  const findProvinsi = async (provinsiId) => {
    provinsiId = provinsiId.split(" ", 1)[0];
    if (provinsiId) {
      const response = await axios.post(`${tempUrl}/provinsis/${provinsiId}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeProvinsi(provinsiId);
      if (response.data) {
        setKodeProvinsi(`${response.data.id} - ${response.data.namaProvinsi}`);
      }
    } else {
      setKodeProvinsi("");
    }
  };

  const getCustomersData = async () => {
    const response = await axios.post(
      `${tempUrl}/customersPerCabangPagination?search_query=${searchTermCustomer}&page=${page}&limit=${limit}`,
      {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setCustomers(response.data.customers);
    setPage(response.data.page);
    setPages(response.data.totalPage);
    setRows(response.data.totalRows);
  };

  const getCoasData = async () => {
    const response = await axios.post(`${tempUrl}/COAsKasBank`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
    setKodeCOA(setting.kasPenerimaanGadai);
    setJenisResikoAju(jenisResikoOption[0]);
  };

  const getMarketingsData = async () => {
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
  };

  const getTingkatRisikosData = async () => {
    const response = await axios.post(`${tempUrl}/tingkatRisikos`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setTingkatRisikos(response.data);
  };

  const getJenisJaminansData = async () => {
    setNamaJenis("");
    const response = await axios.post(`${tempUrl}/jenisJaminans`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setJenisJaminans(response.data);
    setNamaJenis(response.data[0].namaJenis);
    setBungaPerBulanJenis(response.data[0].bungaPerBulanJenis);
  };

  const changeJenisJaminan = async (namaJenis) => {
    const response = await axios.post(`${tempUrl}/jenisJaminanByNama`, {
      namaJenis,
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setNamaJenis(namaJenis);
    setBungaPerBulanJenis(response.data.bungaPerBulanJenis);
  };

  const getPengajuanNextKode = async () => {
    const response = await axios.post(`${tempUrl}/pengajuanNextKode`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setNoAju(response.data);
  };

  const getCustomerNextKode = async () => {
    const response = await axios.post(`${tempUrl}/customerNextKode`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCifCustomer(response.data);
  };

  const findTingkatRisikoByKode = async (kodeTingkatRisiko) => {
    const response = await axios.post(`${tempUrl}/tingkatRisikoByKode`, {
      kodeTingkatRisiko,
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    if (response.data) {
      setKodeTingkatRisikoData(response.data);
    } else {
      setKodeTingkatRisikoData({
        tppuTingkatRisiko: "",
        tpptTingkatRisiko: "",
        ppspmTingkatRisiko: "",
      });
    }
  };

  const getCustomerByNik = async (nikCustomer) => {
    const response = await axios.post(`${tempUrl}/customerByNik`, {
      nikCustomer: nikCustomer,
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    if (response.data) {
      setCifCustomer(response.data.cifCustomer);
      setNoKkCustomer(response.data.noKkCustomer);
      setNamaCustomer(response.data.namaCustomer);
      setTempatLahirCustomer(response.data.tempatLahirCustomer);
      setTanggalLahirCustomer(formatDate(response.data.tanggalLahirCustomer));
      setAgamaCustomer(response.data.agamaCustomer);
      setJenisKelaminCustomer(response.data.jenisKelaminCustomer);
      setNoTeleponCustomer(response.data.noTeleponCustomer);
      setAlamatCustomer(response.data.alamatCustomer);
      setKodeKelurahan(
        `${response.data.kelurahan.id} - ${response.data.kelurahan.namaKelurahan}`
      );
      setKodeKecamatan(
        `${response.data.kecamatan.id} - ${response.data.kecamatan.namaKecamatan}`
      );
      setKodeKabupaten(
        `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
      );
      setKodeProvinsi(
        `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
      );
      setKodePos(response.data.kelurahan.kodePos);
      setStatusPerkawinanCustomer(response.data.statusPerkawinanCustomer);
      setPekerjaanCustomer(response.data.pekerjaanCustomer);
      setKewarganegaraanCustomer(response.data.kewarganegaraanCustomer);
      setIsPickCustomer(true);
    } else {
      setCifCustomer("");
      setNoKkCustomer("");
      setNamaCustomer("");
      setTempatLahirCustomer("");
      setTanggalLahirCustomer(new Date());
      setJenisKelaminCustomer(jenisKelaminOption[0]);
      setAgamaCustomer(agamaOption[0]);
      setNoTeleponCustomer("");
      setAlamatCustomer("");
      setKodeKelurahan("");
      setKodeKecamatan("");
      setKodeKabupaten("");
      setKodeProvinsi("");
      setKodePos("");
      setStatusPerkawinanCustomer(statusPerkawinanOption[0]);
      setPekerjaanCustomer("");
      setKewarganegaraanCustomer(kewarganegaraanOption[0]);
      getCustomerNextKode();
      setIsPickCustomer(false);
    }
  };

  const savePengajuan = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      kodeCOA.length !== 0 &&
      kodeMarketing.length !== 0 &&
      kodeTingkatRisiko.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);

        await axios.post(`${tempUrl}/saveCustomerAndPengajuanAndAllJaminan`, {
          // 01. Data Customer
          isPickCustomer,
          nikCustomer,
          noKkCustomer,
          namaCustomer,
          tempatLahirCustomer,
          tanggalLahirCustomer,
          jenisKelaminCustomer,
          agamaCustomer,
          noTeleponCustomer,
          alamatCustomer,
          alamatTTCustomer: alamatCustomer,
          statusPerkawinanCustomer,
          pekerjaanCustomer,
          kewarganegaraanCustomer,

          kelurahanId: kodeKelurahan,
          provinsiId: kodeProvinsi.split(" ", 1)[0],
          kabupatenId: kodeKabupaten.split(" ", 1)[0],
          kecamatanId: kodeKecamatan.split(" ", 1)[0],

          ketProvinsi,
          ketKabupaten,
          ketKecamatan,
          ketKelurahan,
          kodePos,

          kelurahanTTId: kodeKelurahan,
          provinsiTTId: kodeProvinsi.split(" ", 1)[0],
          kabupatenTTId: kodeKabupaten.split(" ", 1)[0],
          kecamatanTTId: kodeKecamatan.split(" ", 1)[0],

          ketProvinsiTT: ketProvinsi,
          ketKabupatenTT: ketKabupaten,
          ketKecamatanTT: ketKecamatan,
          ketKelurahanTT: ketKelurahan,
          kodePosTT: kodePos,

          // 02. Data Pengajuan & Jaminan
          nikCustomer,
          noSbg,
          kodeCOA,
          kodeMarketing,
          namaJenis,

          tanggalAju,
          jenisResikoAju,
          kodeTingkatRisiko,
          ketResikoAju,
          bungaPerBulanAju: bungaPerBulanJenis,
          pinjamanAju: pinjamanAju,
          biayaAdmAju: biayaAdmAju,

          // Data Penilaian
          hargaPasarSetempatOtr,
          taksiran,
          maksimalPemberianPinjaman,

          listJaminan,

          kodeCabang: user.cabang.id,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarPengajuan");
      } catch (error) {
        alert(error);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Gadai</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Pengajuan</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={savePengajuan}>
        <Card>
          <Card.Header>Data Nasabah</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Pengajuan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control required />}
                          selected={tanggalAju}
                          onChange={(date) => setTanggalAju(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        CIF :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={cifCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nikCustomer}
                          onChange={(e) => {
                            setNikCustomer(e.target.value.toUpperCase());
                            getCustomerByNik(e.target.value.toUpperCase());
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            No. KK :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={noKkCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            No. KK <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={noKkCustomer}
                              onChange={(e) =>
                                setNoKkCustomer(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Nama :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={namaCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Nama <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={namaCustomer}
                              onChange={(e) =>
                                setNamaCustomer(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Tempat Lahir :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tempatLahirCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Tempat Lahir <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={tempatLahirCustomer}
                              onChange={(e) =>
                                setTempatLahirCustomer(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Tgl. Lahir :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tanggalLahirCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Tgl. Lahir <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              customInput={<Form.Control />}
                              selected={tanggalLahirCustomer}
                              onChange={(date) => setTanggalLahirCustomer(date)}
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Jenis Kelamin :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={jenisKelaminCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Jenis Kelamin <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              required
                              value={jenisKelaminCustomer}
                              onChange={(e) => {
                                setJenisKelaminCustomer(e.target.value);
                              }}
                            >
                              {jenisKelaminOption.map((jenisKelamin) => (
                                <option value={jenisKelamin}>
                                  {jenisKelamin}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Agama :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={agamaCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Agama <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              required
                              value={agamaCustomer}
                              onChange={(e) => {
                                setAgamaCustomer(e.target.value);
                              }}
                            >
                              {agamaOption.map((agama) => (
                                <option value={agama}>{agama}</option>
                              ))}
                            </Form.Select>
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            No. Telp / HP :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={noTeleponCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            No. Telp / HP <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={noTeleponCustomer}
                              onChange={(e) =>
                                setNoTeleponCustomer(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kas <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={coaOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeCOA.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeCOA(value.split(" ", 1)[0]);
                          }}
                          defaultValue={setting.kasPenerimaanGadai}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Alamat <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              required
                              value={alamatCustomer}
                              onChange={(e) =>
                                setAlamatCustomer(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKelurahan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kelurahanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeKelurahan.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findKelurahan(value.split(" ", 1)[0]);
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {!isPickCustomer && (
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label
                          column
                          sm="4"
                          style={textRight}
                        ></Form.Label>
                        <Col sm="8">
                          <Form.Control
                            disabled={kodeKelurahan !== "99999999"}
                            value={ketKelurahan}
                            onChange={(e) =>
                              setKetKelurahan(e.target.value.toUpperCase())
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kecamatanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeKecamatan.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onChange={(e, value) => {
                                if (value) {
                                  findKecamatan(value.label);
                                } else {
                                  findKecamatan("");
                                }
                              }}
                              value={kodeKecamatan}
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {!isPickCustomer && (
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label
                          column
                          sm="4"
                          style={textRight}
                        ></Form.Label>
                        <Col sm="8">
                          <Form.Control
                            disabled={
                              kodeKecamatan.split(" ", 1)[0] !== "999999"
                            }
                            value={ketKecamatan}
                            onChange={(e) =>
                              setKetKecamatan(e.target.value.toUpperCase())
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupaten}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kabupatenOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeKabupaten.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onChange={(e, value) => {
                                if (value) {
                                  findKabupaten(value.label);
                                } else {
                                  findKabupaten("");
                                }
                              }}
                              value={kodeKabupaten}
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {!isPickCustomer && (
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label
                          column
                          sm="4"
                          style={textRight}
                        ></Form.Label>
                        <Col sm="8">
                          <Form.Control
                            disabled={kodeKabupaten.split(" ", 1)[0] !== "9999"}
                            value={ketKabupaten}
                            onChange={(e) =>
                              setKetKabupaten(e.target.value.toUpperCase())
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={provinsiOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error && kodeProvinsi.length === 0 && true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onChange={(e, value) => {
                                if (value) {
                                  findProvinsi(value.label);
                                } else {
                                  findProvinsi("");
                                }
                              }}
                              value={kodeProvinsi}
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {!isPickCustomer && (
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label
                          column
                          sm="4"
                          style={textRight}
                        ></Form.Label>
                        <Col sm="8">
                          <Form.Control
                            disabled={kodeProvinsi.split(" ", 1)[0] !== "99"}
                            value={ketProvinsi}
                            onChange={(e) =>
                              setKetProvinsi(e.target.value.toUpperCase())
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePos} disabled readOnly />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              type="number"
                              value={kodePos}
                              disabled={
                                kodeKelurahan.split(" ", 1)[0] !== "99999999"
                              }
                              onChange={(e) =>
                                setKodePos(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Status Perkawinan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={statusPerkawinanCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Status Perkawinan <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              required
                              value={statusPerkawinanCustomer}
                              onChange={(e) => {
                                setStatusPerkawinanCustomer(e.target.value);
                              }}
                            >
                              {statusPerkawinanOption.map(
                                (statusPerkawinan) => (
                                  <option value={statusPerkawinan}>
                                    {statusPerkawinan}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kewarganegaraan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kewarganegaraanCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Kewarganegaraan <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              required
                              value={kewarganegaraanCustomer}
                              onChange={(e) => {
                                setKewarganegaraanCustomer(e.target.value);
                              }}
                            >
                              {kewarganegaraanOption.map((kewarganegaraan) => (
                                <option value={kewarganegaraan}>
                                  {kewarganegaraan}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Marketing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={marketingOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error && kodeMarketing.length === 0 && true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeMarketing(value.split(" ", 1)[0]);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Resiko <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={jenisResikoAju}
                          onChange={(e) => {
                            setJenisResikoAju(e.target.value);
                          }}
                        >
                          {jenisResikoOption.map((jenisResiko) => (
                            <option value={jenisResiko}>{jenisResiko}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      {isPickCustomer ? (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Pekerjaan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={pekerjaanCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Form.Label column sm="4" style={textRight}>
                            Pekerjaan <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={pekerjaanCustomer}
                              onChange={(e) =>
                                setPekerjaanCustomer(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Card style={{ marginTop: 10, marginBottom: 10 }}>
                  <Card.Header>Tingkat Risiko</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kelompok Kerja <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={tingkatRisikoOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeTingkatRisiko.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                setKodeTingkatRisiko(value.split(" ", 1)[0]);
                                findTingkatRisikoByKode(value.split(" ", 1)[0]);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPU :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                kodeTingkatRisikoData.tppuTingkatRisiko
                              )}
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPT :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                kodeTingkatRisikoData.tpptTingkatRisiko
                              )}
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            PPSPM :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                kodeTingkatRisikoData.ppspmTingkatRisiko
                              )}
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={ketResikoAju}
                          onChange={(e) =>
                            setKetResikoAju(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Pinjaman</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. SBG <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={noSbg}
                      required
                      onChange={(e) => {
                        setNoSbg(e.target.value.toUpperCase());
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Kontrak :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglKontrak} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. J. Tempo :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglJtTempo} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Jaminan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Select
                      required
                      value={namaJenis}
                      onChange={(e) => {
                        changeJenisJaminan(e.target.value);
                      }}
                    >
                      {jenisJaminans.map((jenisJaminan) => (
                        <option value={jenisJaminan.namaJenis}>
                          {jenisJaminan.namaJenis}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bunga / Bln (%) :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={`${bungaPerBulanJenis} %`}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pinjaman Rp. <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={pinjamanAju}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setPinjamanAju(
                          values.formattedValue
                            .split(".")
                            .join("")
                            .replace(/,/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bunga / Bln Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(
                        (bungaPerBulanJenis * pinjamanAju) /
                        100
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bi. Administrasi <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="4">
                    {setting.isOpenFeeAdmGadai === true ? (
                      <>
                        <InputGroup className="mb-3">
                          <NumericFormat
                            required
                            value={biayaAdmAju}
                            decimalSeparator={","}
                            thousandSeparator={"."}
                            customInput={Form.Control}
                            onValueChange={(values) => {
                              setBiayaAdmAju(
                                values.formattedValue
                                  .split(".")
                                  .join("")
                                  .replace(/,/g, "")
                              );
                            }}
                          />
                          <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                        </InputGroup>
                      </>
                    ) : (
                      <>
                        <Form.Control
                          value={`${setting.feeAdmGadai} %`}
                          disabled
                          readOnly
                        />
                      </>
                    )}
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      value={((biayaAdmAju * pinjamanAju) / 100).toLocaleString(
                        "de-DE"
                      )}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penilaian</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Harga Pasar Setempat/Otr <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={hargaPasarSetempatOtr}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setHargaPasarSetempatOtr(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Taksiran <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={taksiran}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setTaksiran(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Maksimal Pemberian Pinjaman <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={maksimalPemberianPinjaman}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setMaksimalPemberianPinjaman(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Agunan</Card.Header>
          <Card.Body>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                color="info"
                sx={{
                  bgcolor: "info.light",
                  textTransform: "none",
                  marginRight: 2,
                  marginBottom: 2,
                }}
                onClick={() => {
                  listJaminan.push({
                    namaJam: "",
                    ketJam: "",
                    hargaPasarSetempatOtrJam: "",
                    hargaTafsirJam: "",
                    maksimalPemberianPinjamanJam: "",
                  });
                  setReloadJumlahListJaminan(Math.floor(Math.random() * 1000));
                }}
              >
                {"+ Input Agunan"}
              </Button>
            </Box>
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Keterangan
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Harga Pasar
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Harga Tafsiran
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Maksimal Pemberian Pinjaman
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listJaminan.map((jaminan, index) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Form.Control
                          value={jaminan.namaJam}
                          onChange={(e) => {
                            jaminan.namaJam = e.target.value.toUpperCase();
                            setReloadJumlahListJaminan(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={jaminan.ketJam}
                          onChange={(e) => {
                            jaminan.ketJam = e.target.value.toUpperCase();
                            setReloadJumlahListJaminan(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          value={jaminan.hargaPasarSetempatOtrJam}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            jaminan.hargaPasarSetempatOtrJam =
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "");

                            setReloadJumlahListJaminan(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          value={jaminan.hargaTafsirJam}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            jaminan.hargaTafsirJam = values.formattedValue
                              .split(".")
                              .join("")
                              .replace(/,/g, "");

                            setReloadJumlahListJaminan(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          value={jaminan.maksimalPemberianPinjamanJam}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            jaminan.maksimalPemberianPinjamanJam =
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "");

                            setReloadJumlahListJaminan(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            listJaminan.splice(index, 1);
                            setReloadJumlahListJaminan(
                              Math.floor(Math.random() * 1000)
                            );
                          }}
                        >
                          <DeleteOutlineIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/daftarPengajuan")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openCustomer}
        onClose={handleCloseCustomer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Customer`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Alamat
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      No. Telp / HP
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer, index) => (
                    <TableRow
                      key={customer._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        const findTotalPengajuanPerCustomer = await axios.post(
                          `${tempUrl}/pengajuanByCustomerId`,
                          {
                            customerId: customer.id,
                            _id: user.id,
                            token: user.token,
                            kodeCabang: user.cabang.id,
                          }
                        );
                        if (findTotalPengajuanPerCustomer.data.length >= 3) {
                          alert(
                            `Customer ${customer.cifCustomer} telah memiliki pengajuan yang belum approve 3 kali!`
                          );
                        } else {
                          setCifCustomer(customer.cifCustomer);
                          setNikCustomer(customer.nikCustomer);
                          setNamaCustomer(customer.namaCustomer);
                          setTempatLahirCustomer(customer.tempatLahirCustomer);
                          setTanggalLahirCustomer(
                            formatDate(customer.tanggalLahirCustomer)
                          );
                          setJenisKelaminCustomer(
                            customer.jenisKelaminCustomer
                          );
                          setNoTeleponCustomer(customer.noTeleponCustomer);
                          setAlamatCustomer(customer.alamatCustomer);
                          setKodeKelurahan(
                            `${customer.kelurahan.id} - ${customer.kelurahan.namaKelurahan}`
                          );
                          setKodeKecamatan(
                            `${customer.kecamatan.id} - ${customer.kecamatan.namaKecamatan}`
                          );
                          setKodeKabupaten(
                            `${customer.kabupaten.id} - ${customer.kabupaten.namaKabupaten}`
                          );
                          setKodeProvinsi(
                            `${customer.provinsis.id} - ${customer.provinsis.namaProvinsi}`
                          );
                          setKodePos(customer.kelurahan.kodePos);
                          setStatusPerkawinanCustomer(
                            customer.statusPerkawinanCustomer
                          );
                          setPekerjaanCustomer(customer.pekerjaanCustomer);
                          setKewarganegaraanCustomer(
                            customer.kewarganegaraanCustomer
                          );
                        }
                        handleCloseCustomer();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {customer.namaCustomer}
                      </TableCell>
                      <TableCell>{customer.alamatCustomer}</TableCell>
                      <TableCell>{customer.noTeleponCustomer}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahPengajuan;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

import React, { useState, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrlFico } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Box, Typography, ButtonGroup, Button } from "@mui/material";
import { Container, Form, Table } from "react-bootstrap";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const Neraca = () => {
  const { user, dispatch, setting } = useContext(AuthContext);
  const tableRef = useRef(null);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [neracasData, setNeracasData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanNeraca",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);

      let tempPersenLoading = parseInt(persenLoading);
      tempPersenLoading += 40;
      setPersenLoading(tempPersenLoading.toString());

      let neracas = await axios.post(`${tempUrlFico}/neracas`, {
        groupAktivaLancar: setting.groupAktivaLancar,
        groupAktivaTetap: setting.groupAktivaTetap,
        groupKewajibanHutang: setting.groupKewajibanHutang,
        groupModalSaham: setting.groupModalSaham,
        dariTanggal: user.tutupperiode.dariTanggal,
        sampaiTanggal: user.tutupperiode.sampaiTanggal,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setNeracasData(neracas.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setPersenLoading("0");
    }
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const list = [];
  let tempSubTotal = 0;
  let tempTotal = 0;

  let keys = Object.keys(neracasData);

  for (let j = 0; j < Object.keys(neracasData).length; j++) {
    list.push(
      <tr>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="4">
          {neracasData[keys[j]][0].jenisCOA} :
        </td>
      </tr>
    );
    let groupByKodeGroupCOA = neracasData[keys[j]].reduce((group, neraca) => {
      const { namaGroupCOA } = neraca;
      group[namaGroupCOA] = group[namaGroupCOA] ?? [];
      group[namaGroupCOA].push(neraca);
      return group;
    }, {});
    let keysSubGroup = Object.keys(groupByKodeGroupCOA);

    for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
      list.push(
        <>
          <tr>
            <td
              style={{
                fontWeight: 700,
                paddingLeft: "10px",
                border: "1px solid black",
              }}
              colspan="4"
            >
              {groupByKodeGroupCOA[keysSubGroup[j]][0].namaGroupCOA} :
            </td>
          </tr>
        </>
      );
      for (let k = 0; k < groupByKodeGroupCOA[keysSubGroup[j]].length; k++) {
        tempSubTotal += parseInt(
          groupByKodeGroupCOA[keysSubGroup[j]][k].jumlah
        );
        list.push(
          <tr>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {groupByKodeGroupCOA[keysSubGroup[j]][k].kodeCOA}
            </td>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {groupByKodeGroupCOA[keysSubGroup[j]][k].namaCOA}
            </td>
            {isCetakExcel === false ? (
              <td
                style={{
                  textAlign: "right",
                  width: "25%",
                  border: "1px solid black",
                }}
              >
                {groupByKodeGroupCOA[keysSubGroup[j]][k].jumlah > 0
                  ? parseInt(
                      groupByKodeGroupCOA[keysSubGroup[j]][k].jumlah
                    ).toLocaleString("en-EN")
                  : `(${parseInt(groupByKodeGroupCOA[keysSubGroup[j]][k].jumlah)
                      .toLocaleString("en-EN")
                      .slice(1)})`}
              </td>
            ) : (
              <td
                style={{
                  textAlign: "right",
                  width: "25%",
                  border: "1px solid black",
                }}
              >
                {groupByKodeGroupCOA[keysSubGroup[j]][k].jumlah > 0
                  ? parseInt(groupByKodeGroupCOA[keysSubGroup[j]][k].jumlah)
                  : `(${parseInt(
                      groupByKodeGroupCOA[keysSubGroup[j]][k].jumlah
                    )})`}
              </td>
            )}
            <td style={{ width: "25%", border: "1px solid black" }}></td>
          </tr>
        );
      }
      list.push(
        <tr>
          <td
            style={{
              fontWeight: 700,
              paddingLeft: "10px",
              border: "1px solid black",
            }}
          >
            SUBTOTAL {groupByKodeGroupCOA[keysSubGroup[j]][0].namaGroupCOA} :
          </td>
          <td style={{ border: "1px solid black" }}></td>
          {isCetakExcel === false ? (
            <td
              style={{
                width: "25%",
                textAlign: "right",
                borderTop: "1px solid black",
                fontWeight: 700,
                border: "1px solid black",
              }}
            >
              {tempSubTotal > 0
                ? tempSubTotal.toLocaleString("en-EN")
                : `(${tempSubTotal.toLocaleString("en-EN").slice(1)})`}
            </td>
          ) : (
            <td
              style={{
                width: "25%",
                textAlign: "right",
                borderTop: "1px solid black",
                fontWeight: 700,
                border: "1px solid black",
              }}
            >
              {tempSubTotal > 0 ? tempSubTotal : `(${tempSubTotal})`}
            </td>
          )}
          <td style={{ width: "25%", border: "1px solid black" }}></td>
        </tr>
      );
      tempTotal += tempSubTotal;
      tempSubTotal = 0;
    }
    list.push(
      <tr>
        <td style={{ fontWeight: 700, border: "1px solid black" }}>
          TOTAL {neracasData[keys[j]][0].jenisCOA} :
        </td>
        <td style={{ width: "25%", border: "1px solid black" }}></td>
        <td style={{ width: "25%", border: "1px solid black" }}></td>
        {isCetakExcel === false ? (
          <td
            style={{
              width: "25%",
              textAlign: "right",
              fontWeight: 700,
              border: "1px solid black",
            }}
          >
            {tempTotal > 0
              ? tempTotal.toLocaleString("en-EN")
              : `(${tempTotal.toLocaleString("en-EN").slice(1)})`}
          </td>
        ) : (
          <td
            style={{
              width: "25%",
              textAlign: "right",
              fontWeight: 700,
              border: "1px solid black",
            }}
          >
            {tempTotal > 0 ? tempTotal : `(${tempTotal})`}
          </td>
        )}
      </tr>
    );
    if (neracasData[keys[j]][0].jenisCOA === "AKTIVA") {
      tempTotal = 0;
    }
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanNeraca",
    sheet: "LaporanNeraca",
  });

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Neraca</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Button variant="contained" startIcon={<SearchIcon />} type="submit">
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-EN", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMonth() + 1).toLocaleString("en-EN", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-EN", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMinutes() + 1).toLocaleString("en-EN", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getSeconds()} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Neraca
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-EN", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(dariTanggal.getMonth() + 1).toLocaleString("en-EN", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${dariTanggal.getFullYear()} `}
            S/D :
            {` ${sampaiTanggal.getDate().toLocaleString("en-EN", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(sampaiTanggal.getMonth() + 1).toLocaleString("en-EN", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${sampaiTanggal.getFullYear()}`}
          </p>
          <table class="styled-table" ref={tableRef}>
            <tbody>
              <tr>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}></th>
                <th style={{ border: "1px solid black" }}></th>
              </tr>
              {list}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default Neraca;

const subTitleText = {
  fontWeight: "900",
};

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

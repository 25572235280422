import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { findTingkatRisiko, formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, usePagination } from "../../../components";
import { ShowTableApproval } from "../../../components/ShowTable";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  ButtonGroup,
  Button,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import jsPDF from "jspdf";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const TampilTebus = () => {
  const { screenSize } = useStateContext();
  const reportTemplateRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const [noAju, setNoAju] = useState("");
  const [tanggalAju, setTanggalAju] = useState(new Date());
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [tingkatRisiko, setTingkatRisiko] = useState("");
  const [tingkatRisikoData, setTingkatRisikoData] = useState({});
  const [ketResikoAju, setKetResikoAju] = useState("");
  const [noSbg, setNoSbg] = useState("");
  const [noKwitansi, setNoKwitansi] = useState("");
  const [tglKontrak, setTglKontrak] = useState("");
  const [tglJtTempo, setTglJtTempo] = useState("");
  const [tglJual, setTglJual] = useState("");
  const [bungaPerBulanAju, setBungaPerBulanAju] = useState(0);
  const [pinjamanAju, setPinjamanAju] = useState(0);
  const [nilaiTopup, setNilaiTopup] = useState(0);
  const [biayaAdmAju, setBiayaAdmAju] = useState(0);

  const [cifCustomer, setCifCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState("");
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");

  // Data Tebus
  const [tebusId, setTebusId] = useState("");
  const [denda, setDenda] = useState(0);
  const [potongan, setPotongan] = useState("0");
  const [totalPinjaman, setTotalPinjaman] = useState("");
  const [totalBunga, setTotalBunga] = useState("");
  const [bayar, setBayar] = useState("");
  let newDate = new Date();
  const [tglBayar, setTglBayar] = useState(formatDate(newDate));

  const [kodeCOA, setKodeCOA] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [namaJenis, setNamaJenis] = useState("");
  const [bungaPerBulanJenis, setBungaPerBulanJenis] = useState("");

  // Data Penilaian
  const [hargaPasarSetempatOtr, setHargaPasarSetempatOtr] = useState("0");
  const [taksiran, setTaksiran] = useState("0");
  const [maksimalPemberianPinjaman, setMaksimalPemberianPinjaman] =
    useState("0");

  const [isPost, setIsPost] = useState("");
  const [jaminans, setJaminans] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = jaminans.slice(indexOfFirstPost, indexOfLastPost);

  const count = Math.ceil(jaminans.length / PER_PAGE);
  const _DATA = usePagination(jaminans, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.5 },
    });
  };

  useEffect(() => {
    getPengajuanById();
    getJaminansPerPengajuan();
  }, []);

  const getPengajuanById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/pengajuans/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setNoAju(response.data.noAju);
    setTanggalAju(formatDate(response.data.tanggalAju));
    setJenisResikoAju(response.data.jenisResikoAju);
    setTingkatRisiko(
      `${response.data.tingkatrisiko.kodeTingkatRisiko} - ${response.data.tingkatrisiko.namaTingkatRisiko}`
    );
    setTingkatRisikoData(response.data.tingkatrisiko);
    setKetResikoAju(response.data.ketResikoAju);
    setNoSbg(response.data.noSbg);
    setNoKwitansi(response.data.noKwitansi);
    setTglKontrak(formatDate(response.data.tglKontrak));
    setTglJtTempo(formatDate(response.data.tglJtTempo));
    setTglJual(formatDate(response.data.tglJual));

    setBungaPerBulanAju(response.data.bungaPerBulanAju);
    setPinjamanAju(response.data.pinjamanAju);
    setNilaiTopup(response.data.nilaiTopup);
    setBiayaAdmAju(response.data.biayaAdmAju);

    setCifCustomer(response.data.customer.cifCustomer);
    setNikCustomer(response.data.customer.nikCustomer);
    setNamaCustomer(response.data.customer.namaCustomer);
    setTempatLahirCustomer(response.data.customer.tempatLahirCustomer);
    setTanggalLahirCustomer(
      formatDate(response.data.customer.tanggalLahirCustomer)
    );
    setJenisKelaminCustomer(response.data.customer.jenisKelaminCustomer);
    setNoTeleponCustomer(response.data.customer.noTeleponCustomer);
    setAlamatCustomer(response.data.customer.alamatCustomer);

    setKodeKelurahan(
      `${response.data.customer.kelurahan.id} - ${response.data.customer.kelurahan.namaKelurahan}`
    );
    setKodeKecamatan(
      `${response.data.customer.kecamatan.id} - ${response.data.customer.kecamatan.namaKecamatan}`
    );
    setKodeKabupaten(
      `${response.data.customer.kabupaten.id} - ${response.data.customer.kabupaten.namaKabupaten}`
    );
    setKodeProvinsi(
      `${response.data.customer.provinsis.id} - ${response.data.customer.provinsis.namaProvinsi}`
    );
    setKodePos(response.data.customer.kelurahan.kodePos);

    setStatusPerkawinanCustomer(
      response.data.customer.statusPerkawinanCustomer
    );
    setPekerjaanCustomer(response.data.customer.pekerjaanCustomer);
    setKewarganegaraanCustomer(response.data.customer.kewarganegaraanCustomer);

    setKodeCOA(`${response.data.coa.kodeCOA} - ${response.data.coa.namaCOA}`);
    setKodeMarketing(
      `${response.data.marketing.kodeMarketing} - ${response.data.marketing.namaMarketing}`
    );
    setNamaJenis(response.data.jenisjaminan.namaJenis);
    setBungaPerBulanJenis(response.data.jenisjaminan.bungaPerBulanJenis);

    // Data Penilaian
    setHargaPasarSetempatOtr(response.data.hargaPasarSetempatOtr);
    setTaksiran(response.data.taksiran);
    setMaksimalPemberianPinjaman(response.data.maksimalPemberianPinjaman);

    const findTebus = await axios.post(`${tempUrl}/tebusByNoSbg`, {
      noSbg: response.data.noSbg,
      kodeCabang: user.cabang.id,
      _id: user.id,
      token: user.token,
    });
    setTebusId(findTebus.data.id);
    setDenda(findTebus.data.denda);
    setPotongan(findTebus.data.potongan);
    setTotalBunga(findTebus.data.bunga);
    setTotalPinjaman(findTebus.data.totalPinjaman);
    setBayar(findTebus.data.bayar);
    setTglBayar(formatDate(findTebus.data.tglBayar));
    setIsPost(findTebus.data.isPost);
    setLoading(false);
  };

  const getJaminansPerPengajuan = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/jaminans`, {
      pengajuanId: id,
      _id: user.id,
      token: user.token,
    });
    setJaminans(response.data);
    setLoading(false);
  };

  const deleteTebus = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteTebus/${tebusId}`, {
        pengajuanId: id,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      navigate("/daftarTebus");
    } catch (error) {
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Gadai</h3>
      <h5 style={{ fontWeight: 400 }}>Data Tebus</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarTebus")}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>

      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              setPreviewPdf(!previewPdf);
            }}
          >
            PDF
          </Button>
        </ButtonGroup>
      </Box>

      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} style={cetakContainer}>
            <p style={cetakCenter}>{setting.namaPerusahaan}</p>
            <p style={cetakCenter}>{setting.alamatPerusahaan}</p>
            <p style={cetakCenter}>({setting.kabupatenPerusahaan})</p>
            <p style={cetakCenter}>{setting.kotaPerusahaan}</p>
            <p style={cetakCenter}>NO. TELP. {setting.teleponPerusahaan}</p>
            <hr />
            <p style={cetakCenter}>BUKTI TEBUS GADAI</p>
            <p style={cetakCenter}>{namaCustomer.split(" ")[0]}</p>
            <p style={cetakCenter}>Tanggal : {tanggalAju}</p>
            <p style={cetakCenter}>J. Tempo : {tglJtTempo}</p>
            <p style={cetakCenterBold}>SBG No. {noSbg}</p>
            <p style={cetakCenterBold}>No. Kwt. {noKwitansi}</p>
            <hr />
            <div style={cetakWrapper}>
              <p>Keterangan</p>
              <p>Nominal</p>
            </div>
            <hr />
            <div style={cetakWrapper}>
              <p>Pencairan pinjaman</p>
              <p>{(pinjamanAju + nilaiTopup).toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapper}>
              <p>Total Bunga</p>
              <p>{totalBunga.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapper}>
              <p>Total Denda</p>
              <p>{denda.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapper}>
              <p>Total Potongan</p>
              <p>{potongan.toLocaleString("de-DE")}</p>
            </div>
            <hr />
            <div style={cetakWrapperTotal}>
              <p>Total.</p>
              <p>{(totalPinjaman - potongan).toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapperTotal}>
              <p>Bayar.</p>
              <p>{bayar.toLocaleString("de-DE")}</p>
            </div>
            <div style={cetakWrapperText}>
              <p>Customer,</p>
              <p>{setting.namaPerusahaan} ,</p>
            </div>
            <div style={{ height: "40px" }}></div>
            <div style={{ display: "flex" }}>
              <p style={{ marginLeft: "20px" }}>{namaCustomer.split(" ")[0]}</p>
              <p style={{ marginLeft: "90px" }}>{user.username}</p>
            </div>
          </div>
        </div>
      )}

      <Box sx={buttonModifierContainer}>
        {isPost === false && (
          <>
            <ButtonGroup variant="contained">
              {id && (
                <Button
                  color="error"
                  startIcon={<DeleteOutlineIcon />}
                  sx={{ textTransform: "none" }}
                  onClick={handleClickOpen}
                >
                  Hapus
                </Button>
              )}
            </ButtonGroup>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Hapus Data`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {`Yakin ingin menghapus data ${noSbg}?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deleteTebus(id)}>Ok</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
      <Form>
        <Card>
          <Card.Header>Data Nasabah</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Pengajuan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tanggalAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK / CIF :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="number"
                          value={nikCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                      <Col sm="4">
                        <Form.Control value={cifCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tempatLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tanggalLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisKelaminCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noTeleponCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kas :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeCOA} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={alamatCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kel. / Desa :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeKelurahan}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kecamatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeKecamatan} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kabupaten / Kota :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeKabupaten} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Provinsi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeProvinsi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode POS :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodePos} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={statusPerkawinanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kewarganegaraanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Marketing :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeMarketing} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Resiko :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisResikoAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pekerjaanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Card style={{ marginTop: 10, marginBottom: 10 }}>
                  <Card.Header>Tingkat Risiko</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kelompok Kerja :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tingkatRisiko}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPU :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                tingkatRisikoData.tppuTingkatRisiko
                              )}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPT :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                tingkatRisikoData.tpptTingkatRisiko
                              )}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            PPSPM :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                tingkatRisikoData.ppspmTingkatRisiko
                              )}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={ketResikoAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Pinjaman</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. SBG :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noSbg} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Kwitansi :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noKwitansi} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Kontrak :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglKontrak} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. J. Tempo :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglJtTempo} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jual :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglJual} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Bayar :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglBayar} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Jaminan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaJenis} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pinjaman Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={pinjamanAju.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Top-Up Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={nilaiTopup.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Pinjaman :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(pinjamanAju + nilaiTopup).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bunga / Bln (%) :
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      value={`${bungaPerBulanJenis} %`}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      value={(
                        (bungaPerBulanAju * (pinjamanAju + nilaiTopup)) /
                        100
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Bunga Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalBunga.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Denda Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={denda.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Pinjaman Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalPinjaman.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Potongan Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={potongan.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bayar Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={bayar.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penilaian</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Harga Pasar Setempat/Otr :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={hargaPasarSetempatOtr.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Taksiran :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={taksiran.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Maksimal Pemberian Pinjaman :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={maksimalPemberianPinjaman.toLocaleString(
                            "de-DE"
                          )}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Card.Body>
        </Card>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableApproval
          id={id}
          currentPosts={currentPosts}
          pengajuanId={id}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilTebus;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const cetakContainer = {
  width: "300px",
  fontSize: "16px",
  letterSpacing: "0.01px",
  fontFamily: "sans-serif",
  padding: "20px",
};

const cetakWrapper = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "-10px",
  marginBottom: "-20px",
};

const cetakWrapperTotal = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "-10px",
  marginBottom: "10px",
};

const cetakWrapperText = {
  display: "flex",
  justifyContent: "space-around",
};

const cetakCenter = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
};

const cetakCenterBold = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
  fontWeight: "700",
};

const downloadButtons = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

import React, { useState, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  tempUrlFico,
  useStateContext,
} from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Snackbar,
  Alert,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Container, Card, Form, Row, Col, Table } from "react-bootstrap";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LaporanJurnalUmum = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const tableRefNoBukti = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  const reportTemplateRefNoBukti = useRef(null);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  let nowDate = new Date();
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  const [value, setValue] = useState("perAccount");

  const [jurnalUmumsData, setJurnalUmumsData] = useState([]);
  const [isFetchError, setIsFetchError] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);
  const [previewPdfNoBukti, setPreviewPdfNoBukti] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");

  const handleChangeLaporan = (event) => {
    setValue(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanJurnalUmum",
  });

  const generatePDFNoBukti = useReactToPrint({
    content: () => reportTemplateRefNoBukti.current,
    documentTitle: "LaporanJurnalUmum",
  });

  const tampilPdfPerAccount = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 20;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      setJurnalUmumsData([]);
      if (value === "perAccount") {
        setPreviewPdfNoBukti(false);
        const jurnalUmums = await axios.post(
          `${tempUrlFico}/jurnalUmumLaporanPerAccount`,
          {
            dariTanggal,
            sampaiTanggal,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );
        setJurnalUmumsData(jurnalUmums.data);
        setPreviewPdf(!previewPdf);
      } else {
        setPreviewPdf(false);
        const jurnalUmums = await axios.post(
          `${tempUrlFico}/jurnalUmumLaporanPerNoBukti`,
          {
            dariTanggal,
            sampaiTanggal,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );
        setJurnalUmumsData(jurnalUmums.data);
        setPreviewPdfNoBukti(!previewPdfNoBukti);
      }
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
    border: "1px solid black",
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const tableLastText = {
    borderTop: "1px solid black",
    letterSpacing: "0.01px",
    border: "1px solid black",
  };

  const list = [];

  let keys = Object.keys(jurnalUmumsData);
  let tempDebet = 0;
  let totalDebet = 0;
  let tempKredit = 0;
  let totalKredit = 0;
  let tempSaldo = 0;
  let totalSaldo = 0;

  if (previewPdf) {
    for (let j = 0; j < Object.keys(jurnalUmumsData).length; j++) {
      list.push(
        <tr style={tableText}>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="6"
          >
            Account: {jurnalUmumsData[keys[j]][0].kodeCOA} -{" "}
            {jurnalUmumsData[keys[j]][0].namaCOA}
          </td>
        </tr>
      );
      for (let i = 0; i < jurnalUmumsData[keys[j]].length; i++) {
        let tempTglJurnal = new Date(jurnalUmumsData[keys[j]][i].tglJurnal);
        if (jurnalUmumsData[keys[j]][i].jenis === "D") {
          tempDebet += jurnalUmumsData[keys[j]][i].jumlah;
          totalDebet += jurnalUmumsData[keys[j]][i].jumlah;
          tempSaldo += jurnalUmumsData[keys[j]][i].jumlah;
          totalSaldo += jurnalUmumsData[keys[j]][i].jumlah;
        } else {
          tempKredit += jurnalUmumsData[keys[j]][i].jumlah;
          totalKredit += jurnalUmumsData[keys[j]][i].jumlah;
          tempSaldo -= jurnalUmumsData[keys[j]][i].jumlah;
          totalSaldo -= jurnalUmumsData[keys[j]][i].jumlah;
        }
        list.push(
          <tr style={tableText}>
            <td style={{ border: "1px solid black" }}>{`${tempTglJurnal
              .getDate()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(tempTglJurnal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${tempTglJurnal.getFullYear()}`}</td>
            <td style={{ border: "1px solid black" }}>
              {jurnalUmumsData[keys[j]][i].noBukti}
            </td>
            <td style={{ border: "1px solid black" }}>
              {jurnalUmumsData[keys[j]][i].keterangan.slice(0, 35)}
            </td>
            {jurnalUmumsData[keys[j]][i].jenis === "D" ? (
              <>
                <td style={textTableRight}>
                  {isCetakExcel === false
                    ? jurnalUmumsData[keys[j]][i].jumlah.toLocaleString("de-DE")
                    : jurnalUmumsData[keys[j]][i].jumlah}
                </td>
                <td style={{ border: "1px solid black" }}></td>
              </>
            ) : (
              <>
                <td style={{ border: "1px solid black" }}></td>
                <td style={textTableRight}>
                  {isCetakExcel === false
                    ? jurnalUmumsData[keys[j]][i].jumlah.toLocaleString("de-DE")
                    : jurnalUmumsData[keys[j]][i].jumlah}
                </td>
              </>
            )}
            <td style={textTableRight}>
              {isCetakExcel === false
                ? tempSaldo.toLocaleString("de-DE")
                : tempSaldo}
            </td>
          </tr>
        );

        list.push(
          <tr>
            <td
              style={{ fontWeight: 700, border: "1px solid black" }}
              colspan="3"
            >
              SubTotal Account: {jurnalUmumsData[keys[j]][0].kodeCOA} -{" "}
              {jurnalUmumsData[keys[j]][0].namaCOA}
            </td>
            <td style={textTableRight}>
              {isCetakExcel === false
                ? tempDebet.toLocaleString("de-DE")
                : tempDebet}
            </td>
            <td style={textTableRight}>
              {isCetakExcel === false
                ? tempKredit.toLocaleString("de-DE")
                : tempKredit}
            </td>
            <td style={textTableRight}>
              {isCetakExcel === false
                ? tempSaldo.toLocaleString("de-DE")
                : tempSaldo}
            </td>
          </tr>
        );
        tempDebet = 0;
        tempKredit = 0;
        tempSaldo = 0;
      }
    }

    list.push(
      <tr style={{ borderTop: "1px solid black" }}>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="3">
          Balance:
        </td>
        <td style={textTableRight}>
          {isCetakExcel === false
            ? totalDebet.toLocaleString("de-DE")
            : totalDebet}
        </td>
        <td style={textTableRight}>
          {isCetakExcel === false
            ? totalKredit.toLocaleString("de-DE")
            : totalKredit}
        </td>
        <td style={textTableRight}>
          {isCetakExcel === false
            ? totalSaldo.toLocaleString("de-DE")
            : totalSaldo}
        </td>
      </tr>
    );
  }

  if (previewPdfNoBukti) {
    for (let j = 0; j < Object.keys(jurnalUmumsData).length; j++) {
      list.push(
        <tr style={tableText}>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="6"
          >
            No. Bukti: {jurnalUmumsData[keys[j]][0].noBukti}
          </td>
        </tr>
      );
      for (let i = 0; i < jurnalUmumsData[keys[j]].length; i++) {
        let tempTglJurnal = new Date(jurnalUmumsData[keys[j]][i].tglJurnal);
        if (jurnalUmumsData[keys[j]][i].jenis === "D") {
          tempDebet += jurnalUmumsData[keys[j]][i].jumlah;
          totalDebet += jurnalUmumsData[keys[j]][i].jumlah;
          tempSaldo += jurnalUmumsData[keys[j]][i].jumlah;
          totalSaldo += jurnalUmumsData[keys[j]][i].jumlah;
        } else {
          tempKredit += jurnalUmumsData[keys[j]][i].jumlah;
          totalKredit += jurnalUmumsData[keys[j]][i].jumlah;
          tempSaldo -= jurnalUmumsData[keys[j]][i].jumlah;
          totalSaldo -= jurnalUmumsData[keys[j]][i].jumlah;
        }
        list.push(
          <tr style={tableText}>
            <td style={{ border: "1px solid black" }}>{`${tempTglJurnal
              .getDate()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(tempTglJurnal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${tempTglJurnal.getFullYear()}`}</td>
            <td style={{ border: "1px solid black" }}>
              {jurnalUmumsData[keys[j]][i].noBukti}
            </td>
            <td style={{ border: "1px solid black" }}>
              {jurnalUmumsData[keys[j]][i].keterangan.slice(0, 35)}
            </td>
            {jurnalUmumsData[keys[j]][i].jenis === "D" ? (
              <>
                <td style={textTableRight}>
                  {isCetakExcel === false
                    ? jurnalUmumsData[keys[j]][i].jumlah.toLocaleString("de-DE")
                    : jurnalUmumsData[keys[j]][i].jumlah}
                </td>
                <td style={{ border: "1px solid black" }}></td>
              </>
            ) : (
              <>
                <td style={{ border: "1px solid black" }}></td>
                <td style={textTableRight}>
                  {isCetakExcel === false
                    ? jurnalUmumsData[keys[j]][i].jumlah.toLocaleString("de-DE")
                    : jurnalUmumsData[keys[j]][i].jumlah}
                </td>
              </>
            )}
            <td style={textTableRight}>
              {isCetakExcel === false
                ? tempSaldo.toLocaleString("de-DE")
                : tempSaldo}
            </td>
          </tr>
        );
      }
      list.push(
        <tr>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="3"
          >
            SubTotal No. Bukti: {jurnalUmumsData[keys[j]][0].noBukti}
          </td>
          <td style={textTableRight}>
            {isCetakExcel === false
              ? tempDebet.toLocaleString("de-DE")
              : tempDebet}
          </td>
          <td style={textTableRight}>
            {isCetakExcel === false
              ? tempKredit.toLocaleString("de-DE")
              : tempKredit}
          </td>
          <td style={textTableRight}>
            {isCetakExcel === false
              ? tempSaldo.toLocaleString("de-DE")
              : tempSaldo}
          </td>
        </tr>
      );
      tempDebet = 0;
      tempKredit = 0;
      tempSaldo = 0;
    }

    list.push(
      <tr style={{ borderTop: "1px solid black" }}>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="3">
          Balance:
        </td>
        <td style={textTableRight}>
          {isCetakExcel === false
            ? totalDebet.toLocaleString("de-DE")
            : totalDebet}
        </td>
        <td style={textTableRight}>
          {isCetakExcel === false
            ? totalKredit.toLocaleString("de-DE")
            : totalKredit}
        </td>
        <td style={textTableRight}>
          {isCetakExcel === false
            ? totalSaldo.toLocaleString("de-DE")
            : totalSaldo}
        </td>
      </tr>
    );
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanJurnalUmum",
    sheet: "LaporanJurnalUmum",
  });

  const { onDownloadNoBukti } = useDownloadExcel({
    currentTableRef: tableRefNoBukti.current,
    filename: "LaporanJurnalUmum",
    sheet: "LaporanJurnalUmum",
  });

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Jurnal Umum</h5>
      <Typography sx={subTitleText}>
        Periode : {user.tutupperiode.namaPeriode}
      </Typography>
      <hr />

      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Laporan</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="perAccount"
          value={value}
          onChange={handleChangeLaporan}
        >
          <FormControlLabel
            value="perAccount"
            control={<Radio />}
            label="Per Account"
          />
          <FormControlLabel
            value="perNoBukti"
            control={<Radio />}
            label="Per No Bukti"
          />
        </RadioGroup>
      </FormControl>

      <Card>
        <Card.Header>Rentang Tanggal</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={tampilPdfPerAccount}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Dari :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={dariTanggal}
                      minDate={new Date(user.tutupperiode.dariTanggal)}
                      maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setDariTanggal(date);
                        setPreviewPdf(false);
                        setPreviewPdfNoBukti(false);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Sampai :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTanggal}
                      minDate={new Date(user.tutupperiode.dariTanggal)}
                      maxDate={new Date(user.tutupperiode.sampaiTanggal)}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setSampaiTanggal(date);
                        setPreviewPdf(false);
                        setPreviewPdfNoBukti(false);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                startIcon={<PrintIcon />}
                type="submit"
              >
                TAMPIL PDF
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getSeconds()} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Jurnal Umum Per Account
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${dariTanggal.getFullYear()} `}
            S/D :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${sampaiTanggal.getFullYear()}`}
          </p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={tableTitle}>Tg.</th>
                <th style={tableTitle}>No. Bukti</th>
                <th style={tableTitle}>Keterangan Jurnal</th>
                <th style={tableTitleRight}>Debet</th>
                <th style={tableTitleRight}>Kredit</th>
                <th style={tableTitleRight}>Saldo</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>

      {previewPdfNoBukti && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDFNoBukti}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownloadNoBukti();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRefNoBukti} id="content" style={pdfContainer}>
        <div
          style={{
            visibility: previewPdfNoBukti === true ? "visible" : "hidden",
          }}
        >
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${nowDate.getSeconds()} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Jurnal Umum Per No. Bukti
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${dariTanggal.getFullYear()} `}
            S/D :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${sampaiTanggal.getFullYear()}`}
          </p>
          <table class="styled-table" ref={tableRefNoBukti}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>Tg.</th>
                <th style={{ border: "1px solid black" }}>No. Bukti</th>
                <th style={{ border: "1px solid black" }}>Keterangan Jurnal</th>
                <th style={{ border: "1px solid black" }}>Debet</th>
                <th style={{ border: "1px solid black" }}>Kredit</th>
                <th style={{ border: "1px solid black" }}>Saldo</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>

      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default LaporanJurnalUmum;

const subTitleText = {
  fontWeight: "900",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const tableTitle = {
  padding: "10px",
  border: "1px solid black",
};

const tableTitleRight = {
  padding: "10px",
  textAlign: "right",
  border: "1px solid black",
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

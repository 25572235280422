import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

const LaporanPiutangLunasGadai = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  let [sampaiTanggal, setSampaiTanggal] = useState(new Date());
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [marketingId, setMarketingId] = useState("");
  const [namaMarketing, setNamaMarketing] = useState("");
  const [kategoriJaminanId, setKategoriJaminanId] = useState("");
  const [namaKategoriJaminan, setNamaKategoriJaminan] = useState("");
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [grouping, setGrouping] = useState("KATEGORI");
  const [groupJtTempo, setGroupJtTempo] = useState("SEMUA");

  const [marketings, setMarketings] = useState([]);
  const [kategoriJaminans, setKategoriJaminans] = useState([]);
  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapPiutangLunassData, setLapPiutangLunassData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);
  const [error, setError] = useState(false);

  const jenisResikoOption = ["RENDAH", "SEDANG", "TINGGI"];
  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
    id: marketing.id,
  }));
  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));
  let kategoriJaminanOptions = kategoriJaminans.map((kategoriJaminan) => ({
    label: kategoriJaminan.namaKategori,
    id: kategoriJaminan.id,
  }));

  const handleChangeGrouping = (event) => {
    setGrouping(event.target.value);
    setPreviewPdf(false);
  };

  const handleChangeGroupJtTempo = (event) => {
    setGroupJtTempo(event.target.value);
    setPreviewPdf(false);
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "l",
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.4 },
    });
  };

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let laporanPiutangLunasGadai = await axios.post(
        `${tempUrl}/laporanPiutangLunasGadai`,
        {
          kategoriJaminanId,
          marketingId,
          jenisResikoAju,
          grouping,
          groupJtTempo,
          dariTanggal,
          sampaiTanggal,
          kodeCabang,
          _id: user.id,
          token: user.token,
        }
      );
      setLapPiutangLunassData(laporanPiutangLunasGadai.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getMarketingsData();
    getKategoriJaminansData();
    getCabangsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getMarketingsData = async () => {
    setMarketingId("");
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
    setJenisResikoAju("");
  };

  const getKategoriJaminansData = async () => {
    setKategoriJaminanId("");
    const response = await axios.post(`${tempUrl}/kategoriJaminans`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKategoriJaminans(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const list = [];
  let tempNo = 0;
  let tempJmlPinjaman = 0;
  let tempTotalJmlPinjaman = 0;
  let tempTopUp = 0;
  let tempTotalTopUp = 0;
  let tempSaldo = 0;
  let tempTotalSaldo = 0;
  let tempNilaiAgunan = 0;
  let tempTotalNilaiAgunan = 0;

  let keys = Object.keys(lapPiutangLunassData);

  if (grouping === "KATEGORI") {
    for (let j = 0; j < Object.keys(lapPiutangLunassData).length; j++) {
      list.push(
        <tr style={tableText}>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="19"
          >
            {lapPiutangLunassData[keys[j]][0].groupKategoriJaminan} :
          </td>
        </tr>
      );
      let groupBy = lapPiutangLunassData[keys[j]].reduce((group, pengajuan) => {
        const { groupKategoriJaminan } = pengajuan;
        group[groupKategoriJaminan] = group[groupKategoriJaminan] ?? [];
        group[groupKategoriJaminan].push(pengajuan);
        return group;
      }, {});
      let keysSubGroup = Object.keys(groupBy);

      for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
        for (let k = 0; k < groupBy[keysSubGroup[j]].length; k++) {
          tempJmlPinjaman += groupBy[keysSubGroup[j]][k].pinjamanAju;
          tempTotalJmlPinjaman += groupBy[keysSubGroup[j]][k].pinjamanAju;
          tempTopUp += groupBy[keysSubGroup[j]][k].nilaiTopup;
          tempTotalTopUp += groupBy[keysSubGroup[j]][k].nilaiTopup;
          tempSaldo +=
            groupBy[keysSubGroup[j]][k].pinjamanAju +
            groupBy[keysSubGroup[j]][k].nilaiTopup -
            groupBy[keysSubGroup[j]][k].bayarPinjamanAju;
          if (tempSaldo < 0) {
            tempSaldo = 0;
          }
          tempTotalSaldo += tempSaldo;
          tempNilaiAgunan += groupBy[keysSubGroup[j]][k].nilaiJaminan;
          tempTotalNilaiAgunan += groupBy[keysSubGroup[j]][k].nilaiJaminan;

          tempNo++;
          list.push(
            <tr style={tableText}>
              <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
                {tempNo}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.namaCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.alamatCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.pekerjaanCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.noTeleponCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].noSbg}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].tglBayarFormatted}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].pinjamanAju +
                  groupBy[keysSubGroup[j]][k].nilaiTopup -
                  groupBy[keysSubGroup[j]][k].bayarPinjamanAju <=
                0
                  ? "LUNAS"
                  : "AKTIF"}
              </td>
              <td style={{ border: "1px solid black" }}></td>
              <td style={{ border: "1px solid black" }}></td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? groupBy[keysSubGroup[j]][k].pinjamanAju.toLocaleString(
                      "en-EN"
                    )
                  : groupBy[keysSubGroup[j]][k].pinjamanAju}
              </td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? groupBy[keysSubGroup[j]][k].nilaiTopup.toLocaleString(
                      "en-EN"
                    )
                  : groupBy[keysSubGroup[j]][k].nilaiTopup}
              </td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? tempSaldo.toLocaleString("en-EN")
                  : tempSaldo}
              </td>
              <td style={textNumberRight}>
                {`${
                  isCetakExcel === false
                    ? groupBy[keysSubGroup[j]][
                        k
                      ].bungaPerBulanAju.toLocaleString("en-EN")
                    : groupBy[keysSubGroup[j]][k].bungaPerBulanAju
                } %`}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].jaminans.map((user, index) => (
                  <>
                    <b>{user.namaJam}</b>
                    <p>{user.ketJam}</p>
                  </>
                ))}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].jenisResikoAju}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].marketing.namaMarketing}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].userInput.username}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].userApproval.username}
              </td>
            </tr>
          );
        }
      }
      list.push(
        <tr style={tableText}>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="10"
          >
            Subtotal :
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempJmlPinjaman.toLocaleString("en-EN")
              : tempJmlPinjaman}
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempTopUp.toLocaleString("en-EN")
              : tempTopUp}
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempSaldo.toLocaleString("en-EN")
              : tempSaldo}
          </td>
          <td style={{ border: "1px solid black" }} colspan="6"></td>
        </tr>
      );
      tempJmlPinjaman = 0;
      tempTopUp = 0;
      tempSaldo = 0;
      tempNilaiAgunan = 0;
    }
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="10">
          Total :
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalJmlPinjaman.toLocaleString("en-EN")
            : tempTotalJmlPinjaman}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalTopUp.toLocaleString("en-EN")
            : tempTotalTopUp}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalSaldo.toLocaleString("en-EN")
            : tempTotalSaldo}
        </td>
        <td style={{ border: "1px solid black" }} colspan="6"></td>
      </tr>
    );
  } else if (grouping === "MARKETING") {
    for (let j = 0; j < Object.keys(lapPiutangLunassData).length; j++) {
      list.push(
        <tr style={tableText}>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="19"
          >
            {lapPiutangLunassData[keys[j]][0].groupMarketing} :
          </td>
        </tr>
      );
      let groupBy = lapPiutangLunassData[keys[j]].reduce((group, pengajuan) => {
        const { groupMarketing } = pengajuan;
        group[groupMarketing] = group[groupMarketing] ?? [];
        group[groupMarketing].push(pengajuan);
        return group;
      }, {});
      let keysSubGroup = Object.keys(groupBy);

      for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
        for (let k = 0; k < groupBy[keysSubGroup[j]].length; k++) {
          tempJmlPinjaman += groupBy[keysSubGroup[j]][k].pinjamanAju;
          tempTotalJmlPinjaman += groupBy[keysSubGroup[j]][k].pinjamanAju;
          tempTopUp += groupBy[keysSubGroup[j]][k].nilaiTopup;
          tempTotalTopUp += groupBy[keysSubGroup[j]][k].nilaiTopup;
          tempSaldo +=
            groupBy[keysSubGroup[j]][k].pinjamanAju +
            groupBy[keysSubGroup[j]][k].nilaiTopup -
            groupBy[keysSubGroup[j]][k].bayarPinjamanAju;
          tempTotalSaldo +=
            groupBy[keysSubGroup[j]][k].pinjamanAju +
            groupBy[keysSubGroup[j]][k].nilaiTopup -
            groupBy[keysSubGroup[j]][k].bayarPinjamanAju;
          tempNilaiAgunan += groupBy[keysSubGroup[j]][k].nilaiJaminan;
          tempTotalNilaiAgunan += groupBy[keysSubGroup[j]][k].nilaiJaminan;

          tempNo++;
          list.push(
            <tr style={tableText}>
              <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
                {tempNo}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.namaCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.alamatCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.pekerjaanCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.noTeleponCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].noSbg}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].tglJtTempo}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].pinjamanAju -
                  groupBy[keysSubGroup[j]][k].bayarPinjamanAju ===
                0
                  ? "LUNAS"
                  : "AKTIF"}
              </td>
              <td style={{ border: "1px solid black" }}></td>
              <td style={{ border: "1px solid black" }}></td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? groupBy[keysSubGroup[j]][k].pinjamanAju.toLocaleString(
                      "en-EN"
                    )
                  : groupBy[keysSubGroup[j]][k].pinjamanAju}
              </td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? groupBy[keysSubGroup[j]][k].nilaiTopup.toLocaleString(
                      "en-EN"
                    )
                  : groupBy[keysSubGroup[j]][k].nilaiTopup}
              </td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? (
                      groupBy[keysSubGroup[j]][k].pinjamanAju +
                      groupBy[keysSubGroup[j]][k].nilaiTopup -
                      groupBy[keysSubGroup[j]][k].bayarPinjamanAju
                    ).toLocaleString("en-EN")
                  : groupBy[keysSubGroup[j]][k].pinjamanAju +
                    groupBy[keysSubGroup[j]][k].nilaiTopup -
                    groupBy[keysSubGroup[j]][k].bayarPinjamanAju}
              </td>
              <td style={textNumberRight}>
                {`${
                  isCetakExcel === false
                    ? groupBy[keysSubGroup[j]][
                        k
                      ].bungaPerBulanAju.toLocaleString("en-EN")
                    : groupBy[keysSubGroup[j]][k].bungaPerBulanAju
                } %`}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].jaminans.map((user, index) => (
                  <>
                    <b>{user.namaJam}</b>
                    <p>{user.ketJam}</p>
                  </>
                ))}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].jenisResikoAju}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].marketing.namaMarketing}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].userInput.username}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].userApproval.username}
              </td>
            </tr>
          );
        }
      }
      list.push(
        <tr style={tableText}>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="10"
          >
            Subtotal :
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempJmlPinjaman.toLocaleString("en-EN")
              : tempJmlPinjaman}
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempTopUp.toLocaleString("en-EN")
              : tempTopUp}
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempSaldo.toLocaleString("en-EN")
              : tempSaldo}
          </td>
          <td style={{ border: "1px solid black" }} colspan="6"></td>
        </tr>
      );
      tempJmlPinjaman = 0;
      tempTopUp = 0;
      tempSaldo = 0;
      tempNilaiAgunan = 0;
    }
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="10">
          Total :
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalJmlPinjaman.toLocaleString("en-EN")
            : tempTotalJmlPinjaman}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalTopUp.toLocaleString("en-EN")
            : tempTotalTopUp}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalSaldo.toLocaleString("en-EN")
            : tempTotalSaldo}
        </td>
        <td style={{ border: "1px solid black" }} colspan="6"></td>
      </tr>
    );
  } else if (grouping === "RESIKO") {
    for (let j = 0; j < Object.keys(lapPiutangLunassData).length; j++) {
      list.push(
        <tr style={tableText}>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="19"
          >
            {lapPiutangLunassData[keys[j]][0].jenisResikoAju} :
          </td>
        </tr>
      );
      let groupBy = lapPiutangLunassData[keys[j]].reduce((group, pengajuan) => {
        const { jenisResikoAju } = pengajuan;
        group[jenisResikoAju] = group[jenisResikoAju] ?? [];
        group[jenisResikoAju].push(pengajuan);
        return group;
      }, {});
      let keysSubGroup = Object.keys(groupBy);

      for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
        for (let k = 0; k < groupBy[keysSubGroup[j]].length; k++) {
          tempJmlPinjaman += groupBy[keysSubGroup[j]][k].pinjamanAju;
          tempTotalJmlPinjaman += groupBy[keysSubGroup[j]][k].pinjamanAju;
          tempTopUp += groupBy[keysSubGroup[j]][k].nilaiTopup;
          tempTotalTopUp += groupBy[keysSubGroup[j]][k].nilaiTopup;
          tempSaldo +=
            groupBy[keysSubGroup[j]][k].pinjamanAju +
            groupBy[keysSubGroup[j]][k].nilaiTopup -
            groupBy[keysSubGroup[j]][k].bayarPinjamanAju;
          tempTotalSaldo +=
            groupBy[keysSubGroup[j]][k].pinjamanAju +
            groupBy[keysSubGroup[j]][k].nilaiTopup -
            groupBy[keysSubGroup[j]][k].bayarPinjamanAju;
          tempNilaiAgunan += groupBy[keysSubGroup[j]][k].nilaiJaminan;
          tempTotalNilaiAgunan += groupBy[keysSubGroup[j]][k].nilaiJaminan;

          tempNo++;
          list.push(
            <tr style={tableText}>
              <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
                {tempNo}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.namaCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.alamatCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.pekerjaanCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].customer.noTeleponCustomer}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].noSbg}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].tglJtTempo}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].pinjamanAju -
                  groupBy[keysSubGroup[j]][k].bayarPinjamanAju ===
                0
                  ? "LUNAS"
                  : "AKTIF"}
              </td>
              <td style={{ border: "1px solid black" }}></td>
              <td style={{ border: "1px solid black" }}></td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? groupBy[keysSubGroup[j]][k].pinjamanAju.toLocaleString(
                      "en-EN"
                    )
                  : groupBy[keysSubGroup[j]][k].pinjamanAju}
              </td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? groupBy[keysSubGroup[j]][k].nilaiTopup.toLocaleString(
                      "en-EN"
                    )
                  : groupBy[keysSubGroup[j]][k].nilaiTopup}
              </td>
              <td style={textNumberRight}>
                {isCetakExcel === false
                  ? (
                      groupBy[keysSubGroup[j]][k].pinjamanAju +
                      groupBy[keysSubGroup[j]][k].nilaiTopup -
                      groupBy[keysSubGroup[j]][k].bayarPinjamanAju
                    ).toLocaleString("en-EN")
                  : groupBy[keysSubGroup[j]][k].pinjamanAju +
                    groupBy[keysSubGroup[j]][k].nilaiTopup -
                    groupBy[keysSubGroup[j]][k].bayarPinjamanAju}
              </td>
              <td style={textNumberRight}>
                {`${
                  isCetakExcel === false
                    ? groupBy[keysSubGroup[j]][
                        k
                      ].bungaPerBulanAju.toLocaleString("en-EN")
                    : groupBy[keysSubGroup[j]][k].bungaPerBulanAju
                } %`}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].jaminans.map((user, index) => (
                  <>
                    <b>{user.namaJam}</b>
                    <p>{user.ketJam}</p>
                  </>
                ))}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].jenisResikoAju}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].marketing.namaMarketing}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].userInput.username}
              </td>
              <td style={{ border: "1px solid black" }}>
                {groupBy[keysSubGroup[j]][k].userApproval.username}
              </td>
            </tr>
          );
        }
      }
      list.push(
        <tr style={tableText}>
          <td
            style={{ fontWeight: 700, border: "1px solid black" }}
            colspan="10"
          >
            Subtotal :
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempJmlPinjaman.toLocaleString("en-EN")
              : tempJmlPinjaman}
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempTopUp.toLocaleString("en-EN")
              : tempTopUp}
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? tempSaldo.toLocaleString("en-EN")
              : tempSaldo}
          </td>
          <td style={{ border: "1px solid black" }} colspan="6"></td>
        </tr>
      );
      tempJmlPinjaman = 0;
      tempTopUp = 0;
      tempSaldo = 0;
      tempNilaiAgunan = 0;
    }
    list.push(
      <tr style={tableText}>
        <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="10">
          Total :
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalJmlPinjaman.toLocaleString("en-EN")
            : tempTotalJmlPinjaman}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalTopUp.toLocaleString("en-EN")
            : tempTotalTopUp}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalSaldo.toLocaleString("en-EN")
            : tempTotalSaldo}
        </td>
        <td style={{ border: "1px solid black" }} colspan="6"></td>
      </tr>
    );
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanPiutangLunas",
    sheet: "LaporanPiutangLunas",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Piutang Lunas</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <FormControl sx={{ marginTop: 1 }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Grouping
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue="KATEGORI"
                value={grouping}
                onChange={handleChangeGrouping}
              >
                <FormControlLabel
                  value="KATEGORI"
                  control={<Radio />}
                  label="Kategori"
                />
                <FormControlLabel
                  value="MARKETING"
                  control={<Radio />}
                  label="Marketing"
                />
                <FormControlLabel
                  value="RESIKO"
                  control={<Radio />}
                  label="Resiko"
                />
              </RadioGroup>
            </FormControl>
          </Row>
          <Row>
            <FormControl sx={{ marginTop: 1 }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Filter
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue="JTTEMPO"
                value={groupJtTempo}
                onChange={handleChangeGroupJtTempo}
              >
                <FormControlLabel
                  value="JTTEMPO"
                  control={<Radio />}
                  label="Jt. Tempo"
                />
                <FormControlLabel
                  value="BELUM"
                  control={<Radio />}
                  label="Belum"
                />
                <FormControlLabel
                  value="SEMUA"
                  control={<Radio />}
                  label="Semua"
                />
              </RadioGroup>
            </FormControl>
          </Row>
          <hr />
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kategori Jaminan :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kategoriJaminanOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kategoriJaminanId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KATEGORI JAMINAN"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKategoriJaminanId(value.id);
                        setNamaKategoriJaminan(value.label);
                      } else {
                        setKategoriJaminanId("");
                        setNamaKategoriJaminan("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={namaKategoriJaminan}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Marketing :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={marketingOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && marketingId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA MARKETING"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setMarketingId(value.id);
                        setNamaMarketing(value.label);
                      } else {
                        setMarketingId("");
                        setNamaMarketing("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={marketingId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Jenis Resiko :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={jenisResikoOption}
                    renderInput={(params) => (
                      <TextField
                        error={error && jenisResikoAju.length === 0 && true}
                        size="small"
                        placeholder="SEMUA JENIS RESIKO"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      setJenisResikoAju(value);
                      setPreviewPdf(false);
                    }}
                    defaultValue={jenisResikoAju}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={handleGeneratePdf}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}

      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Piutang Lunas
          </h5>
          <p>
            Sampai Tanggal :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${sampaiTanggal.getFullYear()}`}
          </p>
          <p>Cabang : {namaCabang}</p>
          <p>Kategori : {namaKategoriJaminan}</p>
          <p>Marketing : {namaMarketing}</p>
          <p>Resiko : {jenisResikoAju}</p>
          <p>Grouping : {grouping}</p>
          <p>Group Jt.Tempo : {groupJtTempo}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No</th>
                <th style={{ border: "1px solid black" }}>Nama</th>
                <th style={{ border: "1px solid black" }}>Alamat</th>
                <th style={{ border: "1px solid black" }}>Pekerjaan</th>
                <th style={{ border: "1px solid black" }}>No. Telp</th>
                <th style={{ border: "1px solid black" }}>No. SBG</th>
                <th style={{ border: "1px solid black" }}>Tgl. Bayar</th>
                <th style={{ border: "1px solid black" }}>Status</th>
                <th style={{ border: "1px solid black" }}>Perp</th>
                <th style={{ border: "1px solid black" }}>Tgl. Perp</th>
                <th style={{ border: "1px solid black" }}>Jml. Pinjaman</th>
                <th style={{ border: "1px solid black" }}>Top Up</th>
                <th style={{ border: "1px solid black" }}>Saldo</th>
                <th style={{ border: "1px solid black" }}>Suku Bunga</th>
                <th style={{ border: "1px solid black" }}>Agunan</th>
                <th style={{ border: "1px solid black" }}>Resiko</th>
                <th style={{ border: "1px solid black" }}>Marketing</th>
                <th style={{ border: "1px solid black" }}>User</th>
                <th style={{ border: "1px solid black" }}>Approval</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanPiutangLunasGadai;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
  display: "table",
};

// formatDate function will return dd-mm-yyyy for UI Indonesia
export const formatDate = (date) => {
  let tempDate = new Date(date);
  return `${tempDate.getDate().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}/${(tempDate.getMonth() + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}/${tempDate.getFullYear()}`;
};

export const formatTime = (date) => {
  const hours = String(date.getHours()).padStart(2, "0"); // Get hours and pad with "0" if necessary
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Get minutes and pad with "0"
  const seconds = String(date.getSeconds()).padStart(2, "0"); // Get seconds and pad with "0"

  return `${hours}:${minutes}:${seconds}`;
};

export const customRound = (value) => {
  let integerPart = Math.floor(value);
  let decimalPart = value - integerPart;
  if (decimalPart >= 0.5) {
    return Math.ceil(value);
  } else {
    return integerPart;
  }
};

export const findMonth = (date) => {
  let month = new Date(date);
  month = month.getMonth() + 1;
  let tempDateName;
  switch (month) {
    case 1:
      tempDateName = "JANUARI";
      break;
    case 2:
      tempDateName = "FEBRUARI";
      break;
    case 3:
      tempDateName = "MARET";
      break;
    case 4:
      tempDateName = "APRIL";
      break;
    case 5:
      tempDateName = "MEI";
      break;
    case 6:
      tempDateName = "JUNI";
      break;
    case 7:
      tempDateName = "JULI";
      break;
    case 8:
      tempDateName = "AGUSTUS";
      break;
    case 9:
      tempDateName = "SEPTEMBER";
      break;
    case 10:
      tempDateName = "OKTOBER";
      break;
    case 11:
      tempDateName = "NOVEMBER";
      break;
    case 12:
      tempDateName = "DESEMBER";
      break;
    default:
      break;
  }
  console.log(tempDateName);
  return tempDateName;
};

export const findYear = (date) => {
  let year = new Date(date);
  year = year.getFullYear();
  return year;
};

export const findTingkatRisiko = (tingkatRisiko) => {
  let pilihanTingkatRisikos = [
    {
      id: 1,
      nama: "Tinggi",
    },
    {
      id: 2,
      nama: "Sedang",
    },
    {
      id: 3,
      nama: "Rendah",
    },
  ];
  let formatTingkatRisiko = "";

  if (tingkatRisiko) {
    let getTingkatRisiko = pilihanTingkatRisikos.find(
      (pilihanTingkatRisiko) => pilihanTingkatRisiko.id == tingkatRisiko
    );
    formatTingkatRisiko = `${getTingkatRisiko.id} - ${getTingkatRisiko.nama}`;
  }

  return formatTingkatRisiko;
};

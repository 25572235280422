import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import DatePicker from "react-datepicker";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahSp1FidusiaOtomatis = () => {
  const { screenSize } = useStateContext();
  const { user, setting, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  // Data SP1
  const [tglTunggakan, setTglTunggakan] = useState(new Date());
  const [tglSurat, setTglSurat] = useState(new Date());

  const [pengajuanFidusias, setPengajuanFidusias] = useState([]);
  const [error, setError] = useState(false);
  const [searchTermPengajuan, setSearchTermPengajuan] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getPengajuanFidusia();
  }, [page, searchTermPengajuan]);

  const getPengajuanFidusia = async () => {
    setLoading(true);
    const response = await axios.post(
      `${tempUrl}/pengajuanFidusiasApprovedTelatForSp1PerCabangCustomPagination?search_query=${searchTermPengajuan}&page=${page}&limit=${limit}`,
      {
        tglTunggakan,
        perTanggal: new Date(),
        // perTanggal: new Date("2024-10-23"),
        lamaSp: setting.sp1,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setPengajuanFidusias(response.data);
    setLoading(false);
  };

  const saveSp1Otomatis = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveSp1Otomatis`, {
          tglTunggakan,
          tglSurat,
          pengajuanFidusias,
          persenDendaFidusia: setting.persenDendaFidusia,
          toleransiFidusia: setting.toleransiFidusia,

          kodeCabang: user.cabang.id,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarSp1Fidusia");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Fidusia</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah SP1 Otomatis</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveSp1Otomatis}>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data SP-1</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Tunggakan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control />}
                      selected={tglTunggakan}
                      onChange={(date) => {
                        setTglTunggakan(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={getPengajuanFidusia}
                >
                  Cari Tunggakan
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Surat <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      customInput={<Form.Control />}
                      selected={tglSurat}
                      onChange={(date) => {
                        setTglSurat(date);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarSp1Fidusia")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Card.Body>
        </Card>

        <Box sx={dialogContainer}>
          <TableContainer component={Paper} sx={dialogWrapper}>
            <Table aria-label="simple table">
              <TableHead className={classes.root}>
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    className={classes.tableRightBorder}
                  >
                    No. Pengajuan
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    className={classes.tableRightBorder}
                  >
                    No. Perjanjian
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    className={classes.tableRightBorder}
                  >
                    Tgl.JT
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pengajuanFidusias.map((response, index) => (
                  <TableRow
                    key={response._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": { bgcolor: Colors.grey300 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {response.pengajuanfidusia.noAju}
                    </TableCell>
                    <TableCell>
                      {response.pengajuanfidusia.noPerjanjian}
                    </TableCell>
                    <TableCell>
                      {formatDate(response.pengajuanfidusia.tglJatuhTempo)}
                    </TableCell>
                    <TableCell>
                      {response.pengajuanfidusia.customer.namaCustomer}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahSp1FidusiaOtomatis;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

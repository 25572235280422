import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LaporanPinjamanJatuhTempo = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [perTanggal, setPerTanggal] = useState(new Date());
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [lebihDari, setLebihDari] = useState("1");
  const [sampaiDengan, setSampaiDengan] = useState("30");

  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapTunggakanRincisData, setLapTunggakanRincisData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);
  const [error, setError] = useState(false);

  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanPinjamanJatuhTempo",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let laporanPinjamanJatuhTempo = await axios.post(
        `${tempUrl}/laporanPinjamanJatuhTempo`,
        {
          perTanggal,
          kodeCabang,
          lebihDari,
          sampaiDengan,
          _id: user.id,
          token: user.token,
        }
      );
      setLapTunggakanRincisData(laporanPinjamanJatuhTempo.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getCabangsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const list = [];
  let tempNo = 0;
  let tempJmlPinjaman = 0;
  let tempTotalJmlPinjaman = 0;
  let tempSewaModal = 0;
  let tempTotalSewaModal = 0;
  let tempDenda = 0;
  let tempTotalDenda = 0;

  let keys = Object.keys(lapTunggakanRincisData);

  for (let j = 0; j < Object.keys(lapTunggakanRincisData).length; j++) {
    let groupBy = lapTunggakanRincisData[keys[j]].reduce((group, pengajuan) => {
      const { groupKategoriJaminan } = pengajuan;
      group[groupKategoriJaminan] = group[groupKategoriJaminan] ?? [];
      group[groupKategoriJaminan].push(pengajuan);
      return group;
    }, {});
    let keysSubGroup = Object.keys(groupBy);

    for (let j = 0; j < Object.keys(keysSubGroup).length; j++) {
      for (let k = 0; k < groupBy[keysSubGroup[j]].length; k++) {
        let hitungDendaPerHari = 0;
        let hitungTotalDenda = 0;
        let newTglJtTempo = new Date(
          groupBy[keysSubGroup[j]][k].tglJtTempoTerakhirDate
        );
        newTglJtTempo.setHours(0, 0, 0, 0);
        let tglBayar = new Date(perTanggal);
        tglBayar.setHours(0, 0, 0, 0);
        // console.log(tglBayar);
        // console.log(newTglJtTempo);
        const diffTime = Math.abs(tglBayar - newTglJtTempo);
        // console.log(diffTime);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (tglBayar < newTglJtTempo) {
          diffDays = -diffDays;
        }
        let tempTotalPiutang =
          groupBy[keysSubGroup[j]][k].pinjamanAju +
          groupBy[keysSubGroup[j]][k].nilaiTopup;
        // console.log(groupBy[keysSubGroup[j]][k].noSbg);
        // console.log(diffDays);
        // console.log("-------");

        if (diffDays > 0) {
          if (tempTotalPiutang < setting.batasPinjamanDenda) {
            hitungTotalDenda = setting.denda1 * diffDays;
            hitungDendaPerHari = setting.denda1;
          } else {
            hitungTotalDenda = setting.denda2 * diffDays;
            hitungDendaPerHari = setting.denda2;
          }
        } else {
          hitungTotalDenda = 0;
          hitungDendaPerHari = 0;
        }

        tempJmlPinjaman += tempTotalPiutang;
        tempTotalJmlPinjaman += tempTotalPiutang;
        tempSewaModal += 0;
        tempTotalSewaModal += 0;
        tempDenda += hitungTotalDenda;
        tempTotalDenda += hitungTotalDenda;

        tempNo++;
        list.push(
          <tr style={tableText}>
            <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
              {tempNo}
            </td>
            <td style={{ border: "1px solid black" }}>
              <div>{groupBy[keysSubGroup[j]][k].customer.namaCustomer}</div>
              <div>
                <b>{groupBy[keysSubGroup[j]][k].noSbg}</b>
              </div>
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].noSbg}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].tanggalAju}
            </td>
            <td style={textNumberRight}>
              {isCetakExcel === false
                ? tempTotalPiutang.toLocaleString("en-EN")
                : tempTotalPiutang}
            </td>
            <td style={textNumberRight}></td>
            <td style={textNumberRight}>
              {isCetakExcel === false
                ? hitungTotalDenda.toLocaleString("en-EN")
                : hitungTotalDenda}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].tglJtTempoTerakhir}
            </td>
            <td style={textNumberRight}>
              {isCetakExcel === false
                ? diffDays.toLocaleString("en-EN")
                : diffDays}
            </td>
            <td style={{ border: "1px solid black" }}>
              {groupBy[keysSubGroup[j]][k].jaminans.map((user, index) => (
                <>
                  <b>{user.namaJam}</b>
                  <p>{user.ketJam}</p>
                </>
              ))}
            </td>
          </tr>
        );
      }
    }
  }
  list.push(
    <tr style={tableText}>
      <td style={{ fontWeight: 700, border: "1px solid black" }} colspan="4">
        Total :
      </td>
      <td style={textNumberRight}>
        {isCetakExcel === false
          ? tempTotalJmlPinjaman.toLocaleString("en-EN")
          : tempTotalJmlPinjaman}
      </td>
      <td style={textNumberRight}>
        {isCetakExcel === false
          ? tempTotalSewaModal.toLocaleString("en-EN")
          : tempTotalSewaModal}
      </td>
      <td style={textNumberRight}>
        {isCetakExcel === false
          ? tempTotalDenda.toLocaleString("en-EN")
          : tempTotalDenda}
      </td>
      <td style={{ border: "1px solid black" }} colspan="3"></td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanPinjamanJatuhTempo",
    sheet: "LaporanPinjamanJatuhTempo",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Pinjaman Jatuh Tempo</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Lebih Dari :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={lebihDari}
                    onChange={(e) => {
                      setLebihDari(e.target.value.toUpperCase());
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai Dengan :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={sampaiDengan}
                    onChange={(e) => {
                      setSampaiDengan(e.target.value.toUpperCase());
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Pinjaman Jatuh Tempo
          </h5>
          <p>
            Sampai Tanggal :
            {` ${perTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(perTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${perTanggal.getFullYear()}`}
          </p>
          <p>Cabang : {namaCabang}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No</th>
                <th style={{ border: "1px solid black" }}>Nama</th>
                <th style={{ border: "1px solid black" }}>No. SBG</th>
                <th style={{ border: "1px solid black" }}>Tanggal</th>
                <th style={{ border: "1px solid black" }}>Jml. Pinjaman</th>
                <th style={{ border: "1px solid black" }}>Sewa Modal</th>
                <th style={{ border: "1px solid black" }}>Jml. Denda</th>
                <th style={{ border: "1px solid black" }}>Jatuh Tempo</th>
                <th style={{ border: "1px solid black" }}>Keterlambatan</th>
                <th style={{ border: "1px solid black" }}>Agunan</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanPinjamanJatuhTempo;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
  display: "table",
};

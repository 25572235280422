import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import { formatDate } from "../../../constants/helper";

const LaporanPendapatanByadGadai = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [perTanggal, setPerTanggal] = useState(new Date());
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [marketingId, setMarketingId] = useState("");
  const [namaMarketing, setNamaMarketing] = useState("");
  const [kategoriJaminanId, setKategoriJaminanId] = useState("");
  const [namaKategoriJaminan, setNamaKategoriJaminan] = useState("");
  const [jenisResikoAju, setJenisResikoAju] = useState("");

  const [marketings, setMarketings] = useState([]);
  const [kategoriJaminans, setKategoriJaminans] = useState([]);
  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapTunggakanRincisData, setLapTunggakanRincisData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);
  const [error, setError] = useState(false);

  const jenisResikoOption = ["RENDAH", "SEDANG", "TINGGI"];
  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
    id: marketing.id,
  }));
  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));
  let kategoriJaminanOptions = kategoriJaminans.map((kategoriJaminan) => ({
    label: kategoriJaminan.namaKategori,
    id: kategoriJaminan.id,
  }));

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanPendapatanByadGadai",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let laporanPendapatanByadGadai = await axios.post(
        `${tempUrl}/laporanPendapatanByadGadai`,
        {
          kategoriJaminanId,
          marketingId,
          jenisResikoAju,
          perTanggal,
          kodeCabang,
          _id: user.id,
          token: user.token,
        }
      );
      setLapTunggakanRincisData(laporanPendapatanByadGadai.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getMarketingsData();
    getKategoriJaminansData();
    getCabangsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getMarketingsData = async () => {
    setMarketingId("");
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
    setJenisResikoAju("");
  };

  const getKategoriJaminansData = async () => {
    setKategoriJaminanId("");
    const response = await axios.post(`${tempUrl}/kategoriJaminans`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKategoriJaminans(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const list = [];
  let tempNo = 0;
  let tempJmlPinjaman = 0;
  let tempSisaPinjaman = 0;
  let tempTotalByad = 0;

  for (let i = 0; i < lapTunggakanRincisData.length; i++) {
    tempJmlPinjaman += lapTunggakanRincisData[i].pinjamanAju;
    tempSisaPinjaman += lapTunggakanRincisData[i].sisaPinjaman;
    tempTotalByad += lapTunggakanRincisData[i].byad;

    tempNo++;
    list.push(
      <tr style={tableText}>
        <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
          {tempNo}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTunggakanRincisData[i].customer.namaCustomer} (
          {lapTunggakanRincisData[i].noSbg})
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTunggakanRincisData[i].pinjamanAju.toLocaleString("en-EN")
            : lapTunggakanRincisData[i].pinjamanAju}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTunggakanRincisData[i].sisaPinjaman.toLocaleString("en-EN")
            : lapTunggakanRincisData[i].sisaPinjaman}
        </td>
        <td style={{ border: "1px solid black" }}>
          {formatDate(lapTunggakanRincisData[i].tglApproval)}
        </td>
        <>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? lapTunggakanRincisData[i].hr.toLocaleString("en-EN")
              : lapTunggakanRincisData[i].hr}
          </td>
          <td style={textNumberRight}>
            {isCetakExcel === false
              ? lapTunggakanRincisData[i].bungaPerBulanAju.toLocaleString(
                  "en-EN"
                )
              : lapTunggakanRincisData[i].bungaPerBulanAju}
          </td>
        </>
        <td style={{ border: "1px solid black" }}>
          {lapTunggakanRincisData[i].jenisjaminan.kategorijaminan.namaKategori}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTunggakanRincisData[i].byad.toLocaleString("en-EN")
            : lapTunggakanRincisData[i].byad}
        </td>
      </tr>
    );
  }
  list.push(
    <tr style={tableText}>
      <td style={{ fontWeight: 700, border: "1px solid black" }}>Total :</td>
      <td style={{ border: "1px solid black" }}></td>
      <>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempJmlPinjaman.toLocaleString("en-EN")
            : tempJmlPinjaman}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempSisaPinjaman.toLocaleString("en-EN")
            : tempSisaPinjaman}
        </td>
        <td style={{ border: "1px solid black" }} colspan="4"></td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? tempTotalByad.toLocaleString("en-EN")
            : tempTotalByad}
        </td>
      </>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanPendapatanByadGadai",
    sheet: "LaporanPendapatanByadGadai",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Pendapatan BYAD Gadai</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kategori Jaminan :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kategoriJaminanOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kategoriJaminanId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KATEGORI JAMINAN"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKategoriJaminanId(value.id);
                        setNamaKategoriJaminan(value.label);
                      } else {
                        setKategoriJaminanId("");
                        setNamaKategoriJaminan("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={namaKategoriJaminan}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Marketing :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={marketingOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && marketingId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA MARKETING"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setMarketingId(value.id);
                        setNamaMarketing(value.label);
                      } else {
                        setMarketingId("");
                        setNamaMarketing("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={marketingId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Jenis Resiko :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={jenisResikoOption}
                    renderInput={(params) => (
                      <TextField
                        error={error && jenisResikoAju.length === 0 && true}
                        size="small"
                        placeholder="SEMUA JENIS RESIKO"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      setJenisResikoAju(value);
                      setPreviewPdf(false);
                    }}
                    defaultValue={jenisResikoAju}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Pendapatan BYAD Gadai
          </h5>
          <p>
            Per Tanggal :
            {` ${perTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(perTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${perTanggal.getFullYear()}`}
          </p>
          <p>Cabang : {namaCabang}</p>
          <p>Kategori : {namaKategoriJaminan}</p>
          <p>Marketing : {namaMarketing}</p>
          <p>Resiko : {jenisResikoAju}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No</th>
                <th style={{ border: "1px solid black" }}>Nama (No SBG)</th>
                <th style={{ border: "1px solid black" }}>Pinjaman Awal</th>
                <th style={{ border: "1px solid black" }}>Sisa Pinjaman</th>
                <th style={{ border: "1px solid black" }}>Tgl Cair Awal</th>
                <th style={{ border: "1px solid black" }}>
                  Jangka Waktu ( 1 bulan=30 hari)
                </th>
                <th style={{ border: "1px solid black" }}>
                  Suku Bunga (1 bulan) (%)
                </th>
                <th style={{ border: "1px solid black" }}>Jenis Jaminan</th>
                <th style={{ border: "1px solid black" }}>Bunga</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanPendapatanByadGadai;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
  display: "table",
};

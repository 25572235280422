import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableUser } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Pagination,
  Button,
  ButtonGroup,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const DaftarUser = () => {
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  const [username, setUsername] = useState("");
  const [tipeUser, setTipeUser] = useState("");
  const [periode, setPeriode] = useState("");
  const [kodeKwitansi, setKodeKwitansi] = useState("");
  const [noTerakhir, setNoTerakhir] = useState("");
  const [coaKasir, setCoaKasir] = useState("");

  // Akses Master
  const [jaminan, setJaminan] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [bukuBesar, setBukuBesar] = useState(false);
  const [area, setArea] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [surveyor, setSurveyor] = useState(false);
  const [eksekutor, setEksekutor] = useState(false);
  const [tipe, setTipe] = useState(false);
  const [warna, setWarna] = useState(false);
  const [tingkatRisiko, setTingkatRisiko] = useState(false);
  const [cabang, setCabang] = useState(false);

  // Akses Gadai
  const [pengajuan, setPengajuan] = useState(false);
  const [approval, setApproval] = useState(false);
  const [buktiPencairan, setBuktiPencairan] = useState(false);
  const [topup, setTopup] = useState(false);
  const [tebus, setTebus] = useState(false);
  const [perpanjangan, setPerpanjangan] = useState(false);
  const [cicilan, setCicilan] = useState(false);
  const [jualAgunan, setJualAgunan] = useState(false);
  const [infoNasabahGadai, setInfoNasabahGadai] = useState(false);

  // Akses Laporan Gadai
  const [lapTunggakan, setLapTunggakan] = useState(false);
  const [lapTunggakanRinci, setLapTunggakanRinci] = useState(false);
  const [lapOutstanding, setLapOutstanding] = useState(false);
  const [lapPinjamanJatuhTempo, setLapPinjamanJatuhTempo] = useState(false);
  const [lapStockSiapDijual, setLapStockSiapDijual] = useState(false);
  const [lapPenerimaan, setLapPenerimaan] = useState(false);
  const [lapJualBarang, setLapJualBarang] = useState(false);
  const [lapOutstandingGadai, setLapOutstandingGadai] = useState(false);
  const [lapPiutangLunasGadai, setLapPiutangLunasGadai] = useState(false);
  const [lapPendapatanByadGadai, setLapPendapatanByadGadai] = useState(false);

  // Akses Fidusia
  const [pengajuanFidusia, setPengajuanFidusia] = useState(false);
  const [approvalFidusia, setApprovalFidusia] = useState(false);
  const [buktiPencairanFidusia, setBuktiPencairanFidusia] = useState(false);
  const [angsuranFidusia, setAngsuranFidusia] = useState(false);
  const [percepatanFidusia, setPercepatanFidusia] = useState(false);
  const [infoNasabahFidusia, setInfoNasabahFidusia] = useState(false);
  const [penarikanFidusia, setPenarikanFidusia] = useState(false);
  const [sp1, setSp1] = useState(false);
  const [sp2, setSp2] = useState(false);
  const [sp3, setSp3] = useState(false);
  const [sPanggilan, setSPanggilan] = useState(false);

  // Akses Laporan Fidusia
  const [lapTunggakanFidusia, setLapTunggakanFidusia] = useState(false);
  const [lapTunggakanFidusiaRinci, setLapTunggakanFidusiaRinci] =
    useState(false);
  const [lapTotalPiutangFidusia, setLapTotalPiutangFidusia] = useState(false);
  const [lapPinjamanJatuhTempoFidusia, setLapPinjamanJatuhTempoFidusia] =
    useState(false);
  const [lapPenerimaanFidusia, setLapPenerimaanFidusia] = useState(false);
  const [lapOutstandingFidusia, setLapOutstandingFidusia] = useState(false);
  const [lapPiutangLunasFidusia, setLapPiutangLunasFidusia] = useState(false);
  const [lapPenarikanFidusia, setLapPenarikanFidusia] = useState(false);
  const [lapPendapatanByadFidusia, setLapPendapatanByadFidusia] =
    useState(false);

  // Finance
  const [kasMasuk, setKasMasuk] = useState(false);
  const [kasKeluar, setKasKeluar] = useState(false);
  const [bankMasuk, setBankMasuk] = useState(false);
  const [bankKeluar, setBankKeluar] = useState(false);

  // Accounting
  const [posting, setPosting] = useState(false);
  const [unposting, setUnposting] = useState(false);
  const [aktivitasBukuBesar, setAktivitasBukuBesar] = useState(false);
  const [neracaSaldo, setNeracaSaldo] = useState(false);
  const [labaRugiRekap, setLabaRugiRekap] = useState(false);
  const [labaRugi, setLabaRugi] = useState(false);
  const [neraca, setNeraca] = useState(false);
  const [arusKasRekap, setArusKasRekap] = useState(false);
  const [jurnalUmum, setJurnalUmum] = useState(false);
  const [jurnal, setJurnal] = useState(false);

  // Accounting Ojk
  const [labaRugiOjk, setLabaRugiOjk] = useState(false);
  const [neracaOjk, setNeracaOjk] = useState(false);
  const [arusKasOjk, setArusKasOjk] = useState(false);

  // Akses Utility
  const [profilUser, setProfilUser] = useState(false);
  const [daftarUser, setDaftarUser] = useState(false);
  const [tutupPeriode, setTutupPeriode] = useState(false);
  const [gantiPeriode, setGantiPeriode] = useState(false);
  const [settingAkses, setSettingAkses] = useState(false);

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUser] = useState([]);
  const [usersPagination, setUsersPagination] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    if (user.tipeUser === "OWNER/DIREKSI") {
      getUsersAllCabangPagination();
    } else {
      getUsersPagination();
    }
    id && getUserById();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [id, page, searchTerm]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/usersPerCabang`, {
        tipeAdmin: user.tipeUser,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setUser(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUsersPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/usersPerCabangExceptOwnerPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setUsersPagination(response.data.users);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUsersAllCabang = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/users`, {
        tipeAdmin: user.tipeUser,
        _id: user.id,
        token: user.token,
      });
      setUser(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUsersAllCabangPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/usersPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setUsersPagination(response.data.users);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getUserById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/findUser/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setUsername(response.data.username);
      setTipeUser(response.data.tipeUser);
      setPeriode(response.data.tutupperiode.namaPeriode);
      setKodeKwitansi(response.data.kodeKwitansi);
      setNoTerakhir(response.data.noTerakhir);
      setKodeCabang(response.data.cabang);
      setCoaKasir(
        `${response.data.coa.kodeCOA} - ${response.data.coa.namaCOA}`
      );

      // Akses Master
      setJaminan(response.data.akses.jaminan);
      setMarketing(response.data.akses.marketing);
      setBukuBesar(response.data.akses.bukuBesar);
      setArea(response.data.akses.area);
      setCustomer(response.data.akses.customer);
      setSurveyor(response.data.akses.surveyor);
      setEksekutor(response.data.akses.eksekutor);
      setTipe(response.data.akses.tipe);
      setWarna(response.data.akses.warna);
      setTingkatRisiko(response.data.akses.tingkatRisiko);
      setCabang(response.data.akses.cabang);

      // Akses Gadai
      setPengajuan(response.data.akses.pengajuan);
      setApproval(response.data.akses.approval);
      setBuktiPencairan(response.data.akses.buktiPencairan);
      setTopup(response.data.akses.topup);
      setTebus(response.data.akses.tebus);
      setPerpanjangan(response.data.akses.perpanjangan);
      setCicilan(response.data.akses.cicilan);
      setJualAgunan(response.data.akses.jualAgunan);
      setInfoNasabahGadai(response.data.akses.infoNasabahGadai);

      // Akses Laporan Gadai
      setLapTunggakan(response.data.akses.lapTunggakan);
      setLapTunggakanRinci(response.data.akses.lapTunggakanRinci);
      setLapOutstanding(response.data.akses.lapOutstanding);
      setLapPinjamanJatuhTempo(response.data.akses.lapPinjamanJatuhTempo);
      setLapStockSiapDijual(response.data.akses.lapStockSiapDijual);
      setLapPenerimaan(response.data.akses.lapPenerimaan);
      setLapJualBarang(response.data.akses.lapJualBarang);
      setLapOutstandingGadai(response.data.akses.lapOutstandingGadai);
      setLapPiutangLunasGadai(response.data.akses.lapPiutangLunasGadai);
      setLapPendapatanByadGadai(response.data.akses.lapPendapatanByadGadai);

      // Akses Fidusia
      setPengajuanFidusia(response.data.akses.pengajuanFidusia);
      setApprovalFidusia(response.data.akses.approvalFidusia);
      setBuktiPencairanFidusia(response.data.akses.buktiPencairanFidusia);
      setAngsuranFidusia(response.data.akses.angsuranFidusia);
      setPercepatanFidusia(response.data.akses.percepatanFidusia);
      setInfoNasabahFidusia(response.data.akses.infoNasabahFidusia);
      setPenarikanFidusia(response.data.akses.penarikanFidusia);
      setSp1(response.data.akses.sp1);
      setSp2(response.data.akses.sp2);
      setSp3(response.data.akses.sp3);
      setSPanggilan(response.data.akses.sPanggilan);

      // Akses Laporan Fidusia
      setLapTunggakanFidusia(response.data.akses.lapTunggakanFidusia);
      setLapTunggakanFidusiaRinci(response.data.akses.lapTunggakanFidusiaRinci);
      setLapTotalPiutangFidusia(response.data.akses.lapTotalPiutangFidusia);
      setLapPinjamanJatuhTempoFidusia(
        response.data.akses.lapPinjamanJatuhTempoFidusia
      );
      setLapPenerimaanFidusia(response.data.akses.lapPenerimaanFidusia);
      setLapOutstandingFidusia(response.data.akses.lapOutstandingFidusia);
      setLapPiutangLunasFidusia(response.data.akses.lapPiutangLunasFidusia);
      setLapPenarikanFidusia(response.data.akses.lapPenarikanFidusia);
      setLapPendapatanByadFidusia(response.data.akses.lapPendapatanByadFidusia);

      // Finance
      setKasMasuk(response.data.akses.kasMasuk);
      setKasKeluar(response.data.akses.kasKeluar);
      setBankMasuk(response.data.akses.bankMasuk);
      setBankKeluar(response.data.akses.bankKeluar);

      // Accounting
      setPosting(response.data.akses.posting);
      setUnposting(response.data.akses.unposting);
      setAktivitasBukuBesar(response.data.akses.aktivitasBukuBesar);
      setNeracaSaldo(response.data.akses.neracaSaldo);
      setLabaRugi(response.data.akses.labaRugi);
      setLabaRugiRekap(response.data.akses.labaRugiRekap);
      setNeraca(response.data.akses.neraca);
      setArusKasRekap(response.data.akses.arusKasRekap);
      setJurnalUmum(response.data.akses.jurnalUmum);
      setJurnal(response.data.akses.jurnal);

      // Accounting Ojk
      setLabaRugiOjk(response.data.akses.labaRugiOjk);
      setNeracaOjk(response.data.akses.neracaOjk);
      setArusKasOjk(response.data.akses.arusKasOjk);

      // Akses Utility
      setProfilUser(response.data.akses.profilUser);
      setDaftarUser(response.data.akses.daftarUser);
      setTutupPeriode(response.data.akses.tutupPeriode);
      setGantiPeriode(response.data.akses.gantiPeriode);
      setSettingAkses(response.data.akses.setting);
    }
  };

  const deleteUser = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/users/deleteUser/${id}`, {
        tipeAdmin: user.tipeUser,
        _id: user.id,
        token: user.token,
      });
      setSearchTerm("");
      setUsername("");
      setTipeUser("");
      setPeriode("");
      setKodeKwitansi("");
      setNoTerakhir("");
      setKodeCabang("");

      if (user.id == id) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      } else {
        navigate("/daftarUser");
      }
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${username} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date =
      date.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      date.getFullYear();
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kabupatenPerusahaan}`,
      15,
      10
    );
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar User`, 85, 30);
    doc.setFontSize(8);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Daftar User",
    sheet: "DaftarUser",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container ref={ref}>
      <h3>Utility</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar User</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (user.tipeUser === "OWNER/DIREKSI") {
                getUsersAllCabang();
              } else {
                getUsers();
              }
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (user.tipeUser === "OWNER/DIREKSI") {
                getUsersAllCabang();
              } else {
                getUsers();
              }
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Tipe User</th>
                <th>Periode</th>
                <th>Kode Kwitansi</th>
                <th>No Terakhir</th>
                <th>Cabang</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{user.tipeUser}</td>
                  <td>{user.tutupperiode.namaPeriode}</td>
                  <td>{user.kodeKwitansi}</td>
                  <td>{user.noTerakhir}</td>
                  <td>
                    {user.cabang.id} - {user.cabang.namaCabang}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>Username</th>
                <th>Tipe User</th>
                <th>Periode</th>
                <th>Kode Kwitansi</th>
                <th>No Terakhir</th>
                <th>Cabang</th>
              </tr>
              {users.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{user.tipeUser}</td>
                  <td>{user.tutupperiode.namaPeriode}</td>
                  <td>{user.kodeKwitansi}</td>
                  <td>{user.noTerakhir}</td>
                  <td>
                    {user.cabang.id} - {user.cabang.namaCabang}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/daftarUser/tambahUser`}
          editLink={`/daftarUser/${id}/edit`}
          deleteUser={deleteUser}
          nameUser={username}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Username :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={username} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Tipe User :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={tipeUser} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Periode :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={periode} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Cabang :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          value={`${kodeCabang.id} - ${kodeCabang.namaCabang}`}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRightSmall}>
                        Kode Kwitansi :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={kodeKwitansi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        No Terakhir :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={noTerakhir} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        COA Kasir :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control value={coaKasir} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Form>
          {user.tipeUser !== "ADMIN" && (
            <>
              <Typography variant="h5" sx={[labelInput, spacingTop]}>
                Hak Akses User
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Typography variant="p" sx={[spacingTop]}>
                    Master
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jaminan} disabled />}
                      label="Jaminan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={marketing} disabled />}
                      label="Marketing"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bukuBesar} disabled />}
                      label="Buku Besar"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={area} disabled />}
                      label="Area"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={customer} disabled />}
                      label="Customer"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={surveyor} disabled />}
                      label="Surveyor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={eksekutor} disabled />}
                      label="Eksekutor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tipe} disabled />}
                      label="Tipe"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={warna} disabled />}
                      label="Warna"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tingkatRisiko} disabled />}
                      label="Tingkat Risiko"
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={cabang} disabled />}
                      label="Cabang"
                    />
                  </FormGroup> */}
                  <Typography variant="p" sx={[spacingTop]}>
                    Gadai
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pengajuan} disabled />}
                      label="Pengajuan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={approval} disabled />}
                      label="Approval"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={buktiPencairan} disabled />}
                      label="Bukti Pencairan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={topup} disabled />}
                      label="Top-Up"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tebus} disabled />}
                      label="Tebus"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={perpanjangan} disabled />}
                      label="Perpanjangan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={cicilan} disabled />}
                      label="Cicilan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jualAgunan} disabled />}
                      label="Jual Agunan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={infoNasabahGadai} disabled />}
                      label="Info Nasabah"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Laporan Gadai
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapTunggakan} disabled />}
                      label="Lap. Tunggakan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapTunggakanRinci} disabled />
                      }
                      label="Lap. Tunggakan Rinci"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapOutstanding} disabled />}
                      label="Lap. Total Outstanding"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapPinjamanJatuhTempo} disabled />
                      }
                      label="Lap. Pinjaman Jatuh Tempo"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapStockSiapDijual} disabled />
                      }
                      label="Lap. Stock Siap Dijual"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapPenerimaan} disabled />}
                      label="Lap. Penerimaan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapJualBarang} disabled />}
                      label="Lap. Jual Barang"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapOutstandingGadai} disabled />
                      }
                      label="Lap. Outstanding"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapPiutangLunasGadai} disabled />
                      }
                      label="Lap. Piutang Lunas"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapPendapatanByadGadai} disabled />
                      }
                      label="Lap. Pendapatan BYAD"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Fidusia
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pengajuanFidusia} disabled />}
                      label="Pengajuan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={approvalFidusia} disabled />}
                      label="Approval"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={buktiPencairanFidusia} disabled />
                      }
                      label="Bukti Pencairan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={angsuranFidusia} disabled />}
                      label="Angsuran"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={percepatanFidusia} disabled />
                      }
                      label="Percepatan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={infoNasabahFidusia} disabled />
                      }
                      label="Info Nasabah"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={penarikanFidusia} disabled />}
                      label="Penarikan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp1} disabled />}
                      label="SP-1"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp2} disabled />}
                      label="SP-2"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp3} disabled />}
                      label="SP-3"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sPanggilan} disabled />}
                      label="Surat Panggilan"
                    />
                  </FormGroup>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Typography variant="p" sx={[spacingTop]}>
                    Laporan Fidusia
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapTunggakanFidusia} disabled />
                      }
                      label="Lap. Tunggakan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapTunggakanFidusiaRinci} disabled />
                      }
                      label="Lap. Tunggakan Rinci"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapTotalPiutangFidusia} disabled />
                      }
                      label="Lap. Total Outstanding"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lapPinjamanJatuhTempoFidusia}
                          disabled
                        />
                      }
                      label="Lap. Pinjaman Jatuh Tempo"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapPenerimaanFidusia} disabled />
                      }
                      label="Lap. Penerimaan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapOutstandingFidusia} disabled />
                      }
                      label="Lap. Outstanding Fidusia"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapPiutangLunasFidusia} disabled />
                      }
                      label="Lap. Piutang Lunas"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapPenarikanFidusia} disabled />
                      }
                      label="Lap. Penarikan"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={lapPendapatanByadFidusia} disabled />
                      }
                      label="Lap. Pendapatan BYAD"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Finance
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasMasuk} disabled />}
                      label="Kas Masuk"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasKeluar} disabled />}
                      label="Kas Keluar"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bankMasuk} disabled />}
                      label="Bank Masuk"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bankKeluar} disabled />}
                      label="Bank Keluar"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Accounting
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={posting} disabled />}
                      label="Posting"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={unposting} disabled />}
                      label="Unposting"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={aktivitasBukuBesar} disabled />
                      }
                      label="Aktivitas Buku Besar"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neracaSaldo} disabled />}
                      label="Neraca Saldo"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugi} disabled />}
                      label="Laba Rugi"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugiRekap} disabled />}
                      label="Laba Rugi Rekap"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neraca} disabled />}
                      label="Neraca"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={arusKasRekap} disabled />}
                      label="Arus Kas Rekap"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jurnalUmum} disabled />}
                      label="Jurnal Umum"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jurnal} disabled />}
                      label="Jurnal"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Accounting OJK
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugiOjk} disabled />}
                      label="Laba Rugi OJK"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neracaOjk} disabled />}
                      label="Neraca OJK"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={arusKasOjk} disabled />}
                      label="Arus Kas OJK"
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Utility
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={profilUser} disabled />}
                      label="Profil User"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={daftarUser} disabled />}
                      label="Daftar User"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tutupPeriode} disabled />}
                      label="Tutup Periode"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={gantiPeriode} disabled />}
                      label="Ganti Periode"
                    />
                  </FormGroup>
                  {user.tipeUser === "OWNER/DIREKSI" && (
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={settingAkses} disabled />}
                        label="Setting"
                      />
                    </FormGroup>
                  )}
                </Box>
              </Box>
            </>
          )}
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableUser currentPosts={usersPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default DaftarUser;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const showDataContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const checkboxTitle = {
  marginBottom: 0,
};

const secondCheckboxTitle = {
  marginTop: 15,
  marginBottom: 0,
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

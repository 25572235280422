import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahPerpanjangan = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  const [ajuId, setAjuId] = useState("");
  const [noAju, setNoAju] = useState("");
  const [tanggalAju, setTanggalAju] = useState();
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [tingkatRisiko, setTingkatRisiko] = useState("");
  const [tingkatRisikoData, setTingkatRisikoData] = useState({});
  const [ketResikoAju, setKetResikoAju] = useState("");
  const [noSbg, setNoSbg] = useState("");
  const [tglKontrak, setTglKontrak] = useState("");
  const [tglJtTempo, setTglJtTempo] = useState("");
  const [tglJual, setTglJual] = useState("");
  const [bungaPerBulanAju, setBungaPerBulanAju] = useState("");
  const [bungaPerBulanTebus, setBungaPerBulanTebus] = useState("");
  const [pengali, setPengali] = useState("");
  const [jmlBulan, setJmlBulan] = useState(1);
  const [pinjamanAju, setPinjamanAju] = useState("");
  const [bayarPinjamanAju, setBayarPinjamanAju] = useState("");
  const [biayaAdmAju, setBiayaAdmAju] = useState("");

  const [cifCustomer, setCifCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState("");
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");

  const [kodeCOA, setKodeCOA] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [namaJenis, setNamaJenis] = useState("");
  const [lamaJatuhTempo, setLamaJatuhTempo] = useState(0);
  const [sisaSaldo, setSisaSaldo] = useState(0);

  // Topup
  const [noKwitansi, setNoKwitansi] = useState(user.noTerakhir);
  const [nilaiTopup, setNilaiTopup] = useState("");
  const [keterlambatan, setKeterlambatan] = useState("");
  const [denda, setDenda] = useState(0);
  const [dendaPerHari, setDendaPerHari] = useState(0);
  const [totalPiutang, setTotalPiutang] = useState("0");
  const [totalBunga, setTotalBunga] = useState("");
  const [totalPinjaman, setTotalPinjaman] = useState("");

  // Data Penilaian
  const [hargaPasarSetempatOtr, setHargaPasarSetempatOtr] = useState("0");
  const [taksiran, setTaksiran] = useState("0");
  const [maksimalPemberianPinjaman, setMaksimalPemberianPinjaman] =
    useState("0");

  // Perpanjangan
  const [tglPerpanjangan, setTglPerpanjangan] = useState(new Date());
  const [tglJtTempo1, setTglJtTempo1] = useState(new Date());
  const [tglJtTempo2, setTglJtTempo2] = useState(new Date());

  const [pengajuans, setPengajuans] = useState([]);
  const [coas, setCoas] = useState([]);
  const [error, setError] = useState(false);
  const [searchTermPengajuan, setSearchTermPengajuan] = useState("");
  const [openPengajuan, setOpenPengajuan] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openSaldo, setOpenSaldo] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleCloseSaldo = () => {
    setOpenSaldo(false);
  };

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermPengajuan(query);
  };

  const handleClickOpenPengajuan = () => {
    setOpenPengajuan(true);
  };

  const handleClosePengajuan = () => {
    setOpenPengajuan(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));

  useEffect(() => {
    getCoasData();
  }, []);

  useEffect(() => {
    getTebusData();
  }, [page, searchTermPengajuan]);

  const getTebusData = async () => {
    const response = await axios.post(
      `${tempUrl}/pengajuansBelumLunasPerCabangBisaPerpanjangPagination?search_query=${searchTermPengajuan}&page=${page}&limit=${limit}`,
      {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setPengajuans(response.data.pengajuans);
    setPage(response.data.page);
    setPages(response.data.totalPage);
    setRows(response.data.totalRows);
  };

  const getCoasData = async () => {
    const response = await axios.post(`${tempUrl}/COAs`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
    setKodeCOA(setting.kasPenerimaanGadai);
  };

  const savePerpanjangan = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && noAju.length !== 0 && kodeCOA.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/savePerpanjangan`, {
          ajuId,
          noKwitansi,
          kodeCOA,
          noSbg,
          cifCustomer,
          totalPinjaman: totalPinjaman,
          bungaPerBulanAju,
          bungaPerBulanTebus,
          pokok: pinjamanAju,
          bayar: totalPinjaman,

          tglPerpanjangan,
          tglJtTempo1,
          tglJtTempo2,
          persenAdmPerpanjangan: setting.feeAdmPerpanjanganGadai,
          biayaAdmPerpanjangan:
            (setting.feeAdmPerpanjanganGadai *
              (pinjamanAju - bayarPinjamanAju + nilaiTopup)) /
            100,
          bunga:
            (bungaPerBulanTebus *
              (pinjamanAju - bayarPinjamanAju + nilaiTopup) *
              pengali *
              jmlBulan) /
            100,
          denda,

          kodeCabang,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarPerpanjangan");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Gadai</h3>
      <h5 style={{ fontWeight: 400 }}>Entry Perpanjangan</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={savePerpanjangan}>
        <Card>
          <Card.Header>Data Nasabah</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Pengajuan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noAju}
                          readOnly
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenPengajuan()}
                          isInvalid={noAju.length === 0 && true}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tanggalAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK / CIF :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control value={nikCustomer} disabled readOnly />
                      </Col>
                      <Col sm="4">
                        <Form.Control value={cifCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tempatLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tanggalLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisKelaminCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noTeleponCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kas <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={coaOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeCOA.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeCOA(value.split(" ", 1)[0]);
                          }}
                          defaultValue={setting.kasPenerimaanGadai}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={alamatCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kel. / Desa :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeKelurahan}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kecamatan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeKecamatan} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kabupaten / Kota :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeKabupaten} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Provinsi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeProvinsi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode POS :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodePos} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={statusPerkawinanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pekerjaanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kewarganegaraanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Marketing :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeMarketing} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Resiko :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisResikoAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Card style={{ marginTop: 10, marginBottom: 10 }}>
                  <Card.Header>Tingkat Risiko</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kelompok Kerja :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tingkatRisiko}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPU :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tingkatRisikoData.tppuTingkatRisiko}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPT :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tingkatRisikoData.tpptTingkatRisiko}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            PPSPM :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tingkatRisikoData.ppspmTingkatRisiko}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={ketResikoAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>
        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Pinjaman</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Kwitansi :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noKwitansi} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. SBG :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noSbg} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Kontrak :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglKontrak} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. J. Tempo :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglJtTempo} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jual :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglJual} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterlambatan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={`${keterlambatan} Hari`}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jenis Jaminan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaJenis} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pinjaman Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={pinjamanAju.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nilai Topup Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={nilaiTopup.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bayar Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={bayarPinjamanAju.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Sisa Pinjaman Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(pinjamanAju - bayarPinjamanAju).toLocaleString(
                        "de-DE"
                      )}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bunga / Bln Rp. :
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      value={`${bungaPerBulanTebus} %`}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      value={(
                        (bungaPerBulanTebus *
                          (pinjamanAju - bayarPinjamanAju + nilaiTopup)) /
                        100
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Bunga Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(
                        (bungaPerBulanTebus *
                          (pinjamanAju - bayarPinjamanAju + nilaiTopup) *
                          pengali *
                          jmlBulan) /
                        100
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bi. Administrasi :
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      value={`${biayaAdmAju} %`}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      value={(
                        (biayaAdmAju *
                          (pinjamanAju - bayarPinjamanAju + nilaiTopup)) /
                        100
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Denda/hari Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={dendaPerHari.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Denda Rp. <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={denda}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        let denda = values.formattedValue
                          .split(".")
                          .join("")
                          .replace(/,/g, "");

                        setDenda(denda);
                        if (denda.length === 0) {
                          denda = 0;
                        }

                        let tempTotalPinjaman =
                          parseFloat(totalPiutang) +
                          parseFloat(totalBunga) +
                          parseFloat(denda) -
                          parseFloat(bayarPinjamanAju);
                        setTotalPinjaman(tempTotalPinjaman);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Pinjaman Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={totalPinjaman.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penilaian</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Harga Pasar Setempat/Otr :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={hargaPasarSetempatOtr.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Taksiran :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={taksiran.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Maksimal Pemberian Pinjaman :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={maksimalPemberianPinjaman.toLocaleString(
                            "de-DE"
                          )}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Perpanjangan</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Perpanjangan :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={tglPerpanjangan}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setTglPerpanjangan(date);

                        let newTglJtTempoTerakhir = new Date(tglJtTempo1);
                        newTglJtTempoTerakhir.setHours(0, 0, 0, 0);

                        date.setHours(0, 0, 0, 0);
                        const diffTime = Math.abs(date - newTglJtTempoTerakhir);
                        let diffDays = Math.ceil(
                          diffTime / (1000 * 60 * 60 * 24)
                        );
                        if (date < newTglJtTempoTerakhir) {
                          diffDays = -diffDays;
                        }
                        setKeterlambatan(diffDays);

                        let tempJmlBulan = 1;
                        if (diffDays > setting.batasBunga) {
                          tempJmlBulan = 2;
                          setJmlBulan(2);
                        } else {
                          tempJmlBulan = 1;
                          setJmlBulan(1);
                        }

                        let tempTotalDenda = 0;
                        let tempTotalPiutang = pinjamanAju + nilaiTopup;
                        setTotalPiutang(tempTotalPiutang);
                        if (diffDays > 0) {
                          if (tempTotalPiutang < setting.batasPinjamanDenda) {
                            tempTotalDenda = setting.denda1 * diffDays;
                            setDenda(setting.denda1 * diffDays);
                            setDendaPerHari(setting.denda1);
                          } else {
                            tempTotalDenda = setting.denda2 * diffDays;
                            setDenda(setting.denda2 * diffDays);
                            setDendaPerHari(setting.denda2);
                          }
                        } else {
                          setDenda(0);
                          setDendaPerHari(0);
                        }

                        let tempBungaPerBulanTebus = bungaPerBulanAju;
                        setBungaPerBulanTebus(bungaPerBulanAju);

                        date.setHours(0, 0, 0, 0);
                        if (date < newTglJtTempoTerakhir) {
                          diffDays = -diffDays;
                        }

                        if (diffDays >= setting.batasBunga) {
                          tempBungaPerBulanTebus = bungaPerBulanAju * 2;
                          setBungaPerBulanTebus(bungaPerBulanAju * 2);
                        }

                        let tempTotalBunga =
                          (tempBungaPerBulanTebus *
                            (pinjamanAju + nilaiTopup) *
                            pengali) /
                          100;

                        let tempTotalPinjaman =
                          tempTotalPiutang +
                          tempTotalBunga * tempJmlBulan +
                          tempTotalDenda -
                          bayarPinjamanAju;
                        setTotalPinjaman(tempTotalPinjaman);
                        setTotalBunga(tempTotalBunga * tempJmlBulan);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jt. Tempo 1 :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={tglJtTempo1}
                      customInput={<Form.Control required />}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Jt. Tempo 2 :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={tglJtTempo2}
                      customInput={<Form.Control required />}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bi. Adm. Perpanjangan :
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      value={`${setting.feeAdmPerpanjanganGadai} %`}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      value={(
                        (setting.feeAdmPerpanjanganGadai *
                          (pinjamanAju - bayarPinjamanAju + nilaiTopup)) /
                        100
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarPerpanjangan")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Card.Body>
        </Card>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openSaldo}
        onClose={handleCloseSaldo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Tidak Bisa Topup ${noSbg}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Sisa Saldo : ${sisaSaldo}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaldo}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPengajuan}
        onClose={handleClosePengajuan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Pengajuan`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Pengajuan
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pengajuans.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setKodeCabang(user.cabang.id);
                        setAjuId(user.id);
                        setNoAju(user.noAju);
                        setTanggalAju(user.tanggalAju);
                        setJenisResikoAju(user.jenisResikoAju);
                        setTingkatRisiko(
                          `${user.tingkatrisiko.kodeTingkatRisiko} - ${user.tingkatrisiko.namaTingkatRisiko}`
                        );
                        setTingkatRisikoData(user.tingkatrisiko);
                        setKetResikoAju(user.ketResikoAju);
                        setBungaPerBulanAju(user.bungaPerBulanAju);
                        setBungaPerBulanTebus(user.bungaPerBulanAju);
                        setPinjamanAju(user.pinjamanAju);
                        setBayarPinjamanAju(user.bayarPinjamanAju);
                        setBiayaAdmAju(user.biayaAdmAju);
                        setCifCustomer(user.customer.cifCustomer);
                        setNikCustomer(user.customer.nikCustomer);
                        setNamaCustomer(user.customer.namaCustomer);
                        setTempatLahirCustomer(
                          user.customer.tempatLahirCustomer
                        );
                        setTanggalLahirCustomer(
                          formatDate(user.customer.tanggalLahirCustomer)
                        );
                        setJenisKelaminCustomer(
                          user.customer.jenisKelaminCustomer
                        );
                        setNoTeleponCustomer(user.customer.noTeleponCustomer);
                        setAlamatCustomer(user.customer.alamatCustomer);
                        setKodeKelurahan(
                          `${user.customer.kelurahan.id} - ${user.customer.kelurahan.namaKelurahan}`
                        );
                        setKodeKecamatan(
                          `${user.customer.kecamatan.id} - ${user.customer.kecamatan.namaKecamatan}`
                        );
                        setKodeKabupaten(
                          `${user.customer.kabupaten.id} - ${user.customer.kabupaten.namaKabupaten}`
                        );
                        setKodeProvinsi(
                          `${user.customer.provinsis.id} - ${user.customer.provinsis.namaProvinsi}`
                        );
                        setKodePos(user.customer.kelurahan.kodePos);
                        setStatusPerkawinanCustomer(
                          user.customer.statusPerkawinanCustomer
                        );
                        setPekerjaanCustomer(user.customer.pekerjaanCustomer);
                        setKewarganegaraanCustomer(
                          user.customer.kewarganegaraanCustomer
                        );

                        setKodeMarketing(
                          `${user.marketing.kodeMarketing} - ${user.marketing.namaMarketing}`
                        );
                        setJenisResikoAju(user.jenisResikoAju);
                        setKetResikoAju(user.ketResikoAju);

                        setNamaJenis(user.jenisjaminan.namaJenis);
                        setLamaJatuhTempo(user.jenisjaminan.lamaJatuhTempo);
                        setPengali(user.jenisjaminan.pengali);

                        // Data Penilaian
                        setHargaPasarSetempatOtr(user.hargaPasarSetempatOtr);
                        setTaksiran(user.taksiran);
                        setMaksimalPemberianPinjaman(
                          user.maksimalPemberianPinjaman
                        );

                        // Approval
                        setNoSbg(user.noSbg);

                        let newTglKontrak = new Date(user.tglKontrak);
                        setTglKontrak(formatDate(user.tglKontrak));

                        const diffJtTempo = Math.abs(
                          tglPerpanjangan - newTglKontrak
                        );
                        let diffBayarJtTempo = Math.ceil(
                          diffJtTempo / (1000 * 60 * 60 * 24)
                        );
                        if (tglPerpanjangan < newTglKontrak) {
                          diffBayarJtTempo = -diffBayarJtTempo;
                        }

                        let newTglJual = new Date(user.tglJual);
                        let tempTglJual = `${newTglJual
                          .getDate()
                          .toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}/${(newTglJual.getMonth() + 1).toLocaleString(
                          "en-US",
                          {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          }
                        )}/${newTglJual.getFullYear()}`;
                        setTglJual(tempTglJual);

                        let newTglJtTempo = new Date(user.tglJtTempo);
                        let tempTglJtTempo = `${newTglJtTempo
                          .getDate()
                          .toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}/${(newTglJtTempo.getMonth() + 1).toLocaleString(
                          "en-US",
                          {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          }
                        )}/${newTglJtTempo.getFullYear()}`;
                        setTglJtTempo(tempTglJtTempo);

                        let newTglJtTempoTerakhir = new Date(
                          user.tglJtTempoTerakhir
                        );
                        newTglJtTempoTerakhir.setHours(0, 0, 0, 0);
                        setTglJtTempo1(newTglJtTempoTerakhir);

                        let tempTglJtTempo2 = new Date(newTglJtTempoTerakhir);

                        tempTglJtTempo2 = tempTglJtTempo2.setDate(
                          tempTglJtTempo2.getDate() +
                            user.jenisjaminan.lamaJatuhTempo
                        );
                        let tempDateTglJtTempo2 = new Date(tempTglJtTempo2);
                        tempDateTglJtTempo2.setHours(0, 0, 0, 0);
                        setTglJtTempo2(tempDateTglJtTempo2);

                        tglPerpanjangan.setHours(0, 0, 0, 0);
                        const diffTime = Math.abs(
                          tglPerpanjangan - newTglJtTempoTerakhir
                        );
                        let diffDays = Math.ceil(
                          diffTime / (1000 * 60 * 60 * 24)
                        );
                        if (tglPerpanjangan < newTglJtTempoTerakhir) {
                          diffDays = -diffDays;
                        }
                        setKeterlambatan(diffDays);
                        setNilaiTopup(user.nilaiTopup);

                        let tempJmlBulan = 1;
                        if (diffDays > setting.batasBunga) {
                          tempJmlBulan = 2;
                          setJmlBulan(2);
                        } else {
                          tempJmlBulan = 1;
                          setJmlBulan(1);
                        }

                        let tempTotalDenda = 0;
                        let tempTotalPiutang =
                          user.pinjamanAju + user.nilaiTopup;
                        setTotalPiutang(tempTotalPiutang);
                        if (diffDays > 0) {
                          if (tempTotalPiutang < setting.batasPinjamanDenda) {
                            tempTotalDenda = setting.denda1 * diffDays;
                            setDenda(setting.denda1 * diffDays);
                            setDendaPerHari(setting.denda1);
                          } else {
                            tempTotalDenda = setting.denda2 * diffDays;
                            setDenda(setting.denda2 * diffDays);
                            setDendaPerHari(setting.denda2);
                          }
                        } else {
                          setDenda(0);
                          setDendaPerHari(0);
                        }

                        let tempBungaPerBulanTebus = user.bungaPerBulanAju;
                        tglPerpanjangan.setHours(0, 0, 0, 0);
                        if (tglPerpanjangan < newTglJtTempo) {
                          diffDays = -diffDays;
                        }

                        if (diffDays >= setting.batasBunga) {
                          tempBungaPerBulanTebus = user.bungaPerBulanAju * 2;
                          setBungaPerBulanTebus(user.bungaPerBulanAju * 2);
                        }

                        let tempTotalBunga =
                          (tempBungaPerBulanTebus *
                            (user.pinjamanAju + user.nilaiTopup) *
                            user.jenisjaminan.pengali) /
                          100;

                        let tempTotalPinjaman =
                          tempTotalPiutang +
                          tempTotalBunga * tempJmlBulan +
                          tempTotalDenda -
                          user.bayarPinjamanAju;
                        setTotalPinjaman(tempTotalPinjaman);
                        setTotalBunga(tempTotalBunga * tempJmlBulan);

                        handleClosePengajuan();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noAju}
                      </TableCell>
                      <TableCell>{user.tanggalAju}</TableCell>
                      <TableCell>{user.customer.namaCustomer}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahPerpanjangan;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

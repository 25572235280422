import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { customRound, formatDate } from "../../../constants/helper";
import { useReactToPrint } from "react-to-print";

const LaporanPenarikanFidusia = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  const [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  const [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [sisaBulan, setSisaBulan] = useState("");
  const [kasirId, setKasirId] = useState("");
  const [namaKasir, setNamaKasir] = useState("");
  const [marketingId, setMarketingId] = useState("");
  const [namaMarketing, setNamaMarketing] = useState("");
  const [surveyorId, setSurveyorId] = useState("");
  const [namaSurveyor, setNamaSurveyor] = useState("");
  const [eksekutorId, setEksekutorId] = useState("");
  const [namaEksekutor, setNamaEksekutor] = useState("");
  const [kategori, setKategori] = useState("");

  const [cabangs, setCabangs] = useState([]);
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [eksekutors, setEksekutors] = useState([]);
  const [kasirs, setKasirs] = useState([]);
  const [filterPenarikan, setFilterPenarikan] = useState("SEMUA");
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapTotalPiutangData, setLapTotalPiutangData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);
  const [error, setError] = useState(false);

  const kategoriOption = ["KENDARAAN RODA 2", "KENDARAAN RODA 4"];
  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
    id: marketing.id,
  }));
  let surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
    id: surveyor.id,
  }));
  let eksekutorOptions = eksekutors.map((eksekutor) => ({
    label: `${eksekutor.kodeEksekutor} - ${eksekutor.namaEksekutor}`,
    id: eksekutor.id,
  }));
  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));
  let kasirOptions = kasirs.map((kasir) => ({
    label: kasir.username,
    id: kasir.id,
  }));

  const handleChangeFilterPenarikan = (event) => {
    setFilterPenarikan(event.target.value);
    setPreviewPdf(false);
  };

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanPenarikanFidusia",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let totalPiutangs = await axios.post(
        `${tempUrl}/laporanPenarikanFidusia`,
        {
          dariTanggal,
          sampaiTanggal,
          kodeCabang,
          marketingId,
          surveyorId,
          eksekutorId,
          kasirId,
          sisaBulan,
          kategori,
          filterPenarikan,
          _id: user.id,
          token: user.token,
        }
      );
      setLapTotalPiutangData(totalPiutangs.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getCabangsData();
    getKasirsData();
    getMarketingsData();
    getSurveyorsData();
    getEksekutorsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getSurveyorsData = async () => {
    const response = await axios.post(`${tempUrl}/surveyors`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setSurveyors(response.data);
    setKategori("");
  };

  const getEksekutorsData = async () => {
    const response = await axios.post(`${tempUrl}/eksekutors`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setEksekutors(response.data);
    setKategori("");
  };

  const getMarketingsData = async () => {
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
  };

  const getKasirsData = async () => {
    const response = await axios.post(`${tempUrl}/users`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKasirs(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textBold = {
    fontWeight: 700,
    border: "1px solid black",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const textNumberRightBold = {
    textAlign: "right",
    fontWeight: 700,
    border: "1px solid black",
  };

  const list = [];
  let tempNo = 0;
  let tempTotalModal = 0;
  let tempTotalBunga = 0;
  let tempJumlahPinjaman = 0;
  let tempTotalSisaHutang = 0;
  let tempTotalBungaPerTahun = 0;
  let tempTotal = 0;

  for (let j = 0; j < lapTotalPiutangData.length; j++) {
    tempNo += 1;
    tempTotalModal += lapTotalPiutangData[j].angModal;
    tempTotalBunga += lapTotalPiutangData[j].angBunga;
    tempTotal += lapTotalPiutangData[j].angPerBulan;
    tempJumlahPinjaman += lapTotalPiutangData[j].jumlahPinjaman;
    tempTotalSisaHutang +=
      lapTotalPiutangData[j].angModal * lapTotalPiutangData[j].sisaBulan;
    tempTotalBungaPerTahun += lapTotalPiutangData[j].bungaPerTahun;

    list.push(
      <tr style={tableText}>
        <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
          {tempNo}
        </td>
        <td style={{ border: "1px solid black" }}>
          {formatDate(lapTotalPiutangData[j].tglPenarikan)}
        </td>
        <td style={{ border: "1px solid black" }}>
          {`${lapTotalPiutangData[j].eksekutor.kodeEksekutor} - ${lapTotalPiutangData[j].eksekutor.namaEksekutor}`}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].tglApproval}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].customer.namaCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].customer.pekerjaanCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].noPerjanjian}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].customer.noTeleponCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].nopol}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].tipe.kodeTipe}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].tipe.merk}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].tahun}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? customRound(lapTotalPiutangData[j].angModal).toLocaleString(
                "en-EN"
              )
            : customRound(lapTotalPiutangData[j].angModal)}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? parseInt(lapTotalPiutangData[j].angBunga).toLocaleString("en-EN")
            : parseInt(lapTotalPiutangData[j].angBunga)}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].angPerBulan.toLocaleString("en-EN")
            : lapTotalPiutangData[j].angPerBulan}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].sisaBulan.toLocaleString("en-EN")
            : lapTotalPiutangData[j].sisaBulan}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].jumlahPinjaman.toLocaleString("en-EN")
            : lapTotalPiutangData[j].jumlahPinjaman}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? parseInt(
                lapTotalPiutangData[j].angModal *
                  lapTotalPiutangData[j].sisaBulan
              ).toLocaleString("en-EN")
            : parseInt(
                lapTotalPiutangData[j].angModal *
                  lapTotalPiutangData[j].sisaBulan
              )}
        </td>
        <td style={textNumberRight}>
          {`${parseInt(lapTotalPiutangData[j].bungaPerTahun)} %`}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].jenisResikoAju}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].kasir}
        </td>
      </tr>
    );
  }

  list.push(
    <tr style={tableText}>
      <td style={textBold} colspan="12">
        TOTAL :
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? parseInt(tempTotalModal).toLocaleString("en-EN")
          : parseInt(tempTotalModal)}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? parseInt(tempTotalBunga).toLocaleString("en-EN")
          : parseInt(tempTotalBunga)}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? parseInt(tempTotal).toLocaleString("en-EN")
          : parseInt(tempTotal)}
      </td>
      <td></td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempJumlahPinjaman.toLocaleString("en-EN")
          : tempJumlahPinjaman}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? parseInt(tempTotalSisaHutang).toLocaleString("en-EN")
          : parseInt(tempTotalSisaHutang)}
      </td>
      <td style={textNumberRightBold}>
        {`${parseInt(tempTotalBungaPerTahun / tempNo)} %`}
      </td>
      <td style={{ border: "1px solid black" }}></td>
      <td style={{ border: "1px solid black" }}></td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanPenarikanFidusia",
    sheet: "LaporanPenarikanFidusia",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Penarikan Fidusia</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <FormControl sx={{ marginTop: 1 }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Filter
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue="SEMUA"
                value={filterPenarikan}
                onChange={handleChangeFilterPenarikan}
              >
                <FormControlLabel
                  value="SEMUA"
                  control={<Radio />}
                  label="Semua"
                />
                <FormControlLabel
                  value="TIDAKDIBAYAR"
                  control={<Radio />}
                  label="Tidak Dibayar"
                />
              </RadioGroup>
            </FormControl>
          </Row>
          <hr />
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Marketing :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={marketingOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && marketingId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA MARKETING"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setMarketingId(value.id);
                        setNamaMarketing(value.label);
                      } else {
                        setMarketingId("");
                        setNamaMarketing("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={marketingId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Surveyor :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={surveyorOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && surveyorId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA SURVEYOR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setSurveyorId(value.id);
                        setNamaSurveyor(value.label);
                      } else {
                        setSurveyorId("");
                        setNamaSurveyor("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={namaSurveyor}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Eksekutor :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={eksekutorOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && eksekutorId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA EKSEKUTOR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setEksekutorId(value.id);
                        setNamaEksekutor(value.label);
                      } else {
                        setEksekutorId("");
                        setNamaEksekutor("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={namaEksekutor}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kasir :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kasirOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kasirId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KASIR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKasirId(value.id);
                        setNamaKasir(value.label);
                      } else {
                        setKasirId("");
                        setNamaKasir("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kasirId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kategori :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kategoriOption}
                    renderInput={(params) => (
                      <TextField
                        error={error && kategori.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KATEGORI"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => {
                      setKategori(value);
                      setPreviewPdf(false);
                    }}
                    defaultValue={kategori}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sisa Bulan :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={sisaBulan}
                    onChange={(e) => {
                      setSisaBulan(e.target.value.toUpperCase());
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={generatePDF}
            >
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Penarikan Fidusia
          </h5>
          <p>Cabang : {namaCabang}</p>
          <p>Marketing : {namaMarketing}</p>
          <p>Surveyor : {namaSurveyor}</p>
          <p>Eksekutor : {namaEksekutor}</p>
          <p>Kasir : {namaKasir}</p>
          <p>Sisa Bulan : {sisaBulan}</p>
          <p>Kategori : {kategori}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No</th>
                <th style={{ border: "1px solid black" }}>Tgl. Penarikan</th>
                <th style={{ border: "1px solid black" }}>Eksekutor</th>
                <th style={{ border: "1px solid black" }}>Tgl. Approve</th>
                <th style={{ border: "1px solid black" }}>Nama</th>
                <th style={{ border: "1px solid black" }}>Pekerjaan</th>
                <th style={{ border: "1px solid black" }}>No. Perjanjian</th>
                <th style={{ border: "1px solid black" }}>No. Telp</th>
                <th style={{ border: "1px solid black" }}>Nopol</th>
                <th style={{ border: "1px solid black" }}>Tipe</th>
                <th style={{ border: "1px solid black" }}>Merk</th>
                <th style={{ border: "1px solid black" }}>Thn</th>
                <th style={{ border: "1px solid black" }}>P. Modal</th>
                <th style={{ border: "1px solid black" }}>P. Bunga</th>
                <th style={{ border: "1px solid black" }}>Total</th>
                <th style={{ border: "1px solid black" }}>S. Bln</th>
                <th style={{ border: "1px solid black" }}>Jlh Pinjaman</th>
                <th style={{ border: "1px solid black" }}>Sisa Pinjaman</th>
                <th style={{ border: "1px solid black" }}>Bunga Per Tahun</th>
                <th style={{ border: "1px solid black" }}>Jenis Resiko</th>
                <th style={{ border: "1px solid black" }}>Kasir</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanPenarikanFidusia;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

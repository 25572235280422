import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { findTingkatRisiko, formatDate } from "../../../constants/helper";
import DatePicker from "react-datepicker";
import { AuthContext } from "../../../contexts/AuthContext";
import { Colors } from "../../../constants/styles";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader, SearchBar } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Pagination,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahPengajuanFidusia = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  // Data Customer
  const [cifCustomer, setCifCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [noKkCustomer, setNoKkCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState();
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [agamaCustomer, setAgamaCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [alamatTTCustomer, setAlamatTTCustomer] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [kodeProvinsiTT, setKodeProvinsiTT] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [kodeKabupatenTT, setKodeKabupatenTT] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeKecamatanTT, setKodeKecamatanTT] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [kodeKelurahanTT, setKodeKelurahanTT] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [kodePosTT, setKodePosTT] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [kodeTingkatRisiko, setKodeTingkatRisiko] = useState("");
  const [kodeTingkatRisikoData, setKodeTingkatRisikoData] = useState({});
  const [ketResikoAju, setKetResikoAju] = useState("");

  // Data Penjamin
  const [alamatSama, setAlamatSama] = useState(false);
  const [nikPenjamin, setNikPenjamin] = useState("");
  const [noKkPenjamin, setNoKkPenjamin] = useState("");
  const [namaPenjamin, setNamaPenjamin] = useState("");
  const [tempatLahirPenjamin, setTempatLahirPenjamin] = useState("");
  const [tanggalLahirPenjamin, setTanggalLahirPenjamin] = useState(new Date());
  const [jenisKelaminPenjamin, setJenisKelaminPenjamin] = useState("");
  const [agamaPenjamin, setAgamaPenjamin] = useState("");
  const [noTeleponPenjamin, setNoTeleponPenjamin] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [alamatRefRegister, setAlamatRefRegister] = useState("");
  const [teleponRefRegister, setTeleponRefRegister] = useState("");
  const [alamatPenjamin, setAlamatPenjamin] = useState("");
  const [kodeProvinsiPenjamin, setKodeProvinsiPenjamin] = useState("");
  const [ketProvinsiPenjamin, setKetProvinsiPenjamin] = useState("");
  const [kodeKabupatenPenjamin, setKodeKabupatenPenjamin] = useState("");
  const [ketKabupatenPenjamin, setKetKabupatenPenjamin] = useState("");
  const [kodeKecamatanPenjamin, setKodeKecamatanPenjamin] = useState("");
  const [ketKecamatanPenjamin, setKetKecamatanPenjamin] = useState("");
  const [kodeKelurahanPenjamin, setKodeKelurahanPenjamin] = useState("");
  const [ketKelurahanPenjamin, setKetKelurahanPenjamin] = useState("");
  const [kodePosPenjamin, setKodePosPenjamin] = useState("");
  const [statusPerkawinanPenjamin, setStatusPerkawinanPenjamin] = useState("");
  const [pekerjaanPenjamin, setPekerjaanPenjamin] = useState("");
  const [kewarganegaraanPenjamin, setKewarganegaraanPenjamin] = useState("");

  // Data Pinjaman
  const [noAju, setNoAju] = useState("");
  const [noPerjanjian, setNoPerjanjian] = useState("");
  const [kategori, setKategori] = useState("");
  const [pinjamanAju, setPinjamanAju] = useState("0");
  const [angPerBulan, setAngPerBulan] = useState("0");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [tanggalAju, setTanggalAju] = useState(new Date());
  const [tglAng, setTglAng] = useState(new Date());
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [tenor, setTenor] = useState(0);
  const [bungaPerTahun, setBungaPerTahun] = useState("0");
  const [totalPiutang, setTotalPiutang] = useState("0");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");

  // Data Kendaraan
  const [kodeTipe, setKodeTipe] = useState("");
  const [tahun, setTahun] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [kodeWarna, setKodeWarna] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [tglStnk, setTglStnk] = useState(new Date());
  const [bpkbAN, setBpkbAN] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [isi, setIsi] = useState("");
  const [merk, setMerk] = useState("");
  const [nopol, setNopol] = useState("");

  // Data Penilaian
  const [hargaPasarSetempatOtr, setHargaPasarSetempatOtr] = useState("0");
  const [taksiran, setTaksiran] = useState("0");
  const [maksimalPemberianPinjaman, setMaksimalPemberianPinjaman] =
    useState("0");

  // Asuransi
  const [noFidusia, setNoFidusia] = useState("");
  const [nilaiPertanggungan, setNilaiPertanggungan] = useState("0");
  const [noAsuransi, setNoAsuransi] = useState("");

  const [kelurahans, setKelurahans] = useState([]);
  const [kecamatans, setKecamatans] = useState([]);
  const [kabupatens, setKabupatens] = useState([]);
  const [provinsis, setProvinsis] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [marketings, setMarketings] = useState([]);
  const [tingkatRisikos, setTingkatRisikos] = useState([]);
  const [coas, setCoas] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [tipes, setTipes] = useState([]);
  const [warnas, setWarnas] = useState([]);
  const [error, setError] = useState(false);
  const [searchTermCustomer, setSearchTermCustomer] = useState("");
  const [openCustomer, setOpenCustomer] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const classes = useStyles();

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermCustomer(query);
  };

  const handleClickOpenCustomer = () => {
    setOpenCustomer(true);
  };

  const handleCloseCustomer = () => {
    setOpenCustomer(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisKelaminOption = ["LAKI-LAKI", "PEREMPUAN"];
  const agamaOption = [
    "ISLAM",
    "PROTESTAN",
    "KATOLIK",
    "HINDU",
    "BUDHA",
    "KHONGHUCU",
    "LAIN",
  ];
  const statusPerkawinanOption = ["BELUM MENIKAH", "(JANDA/DUDA)", "MENIKAH"];
  const kewarganegaraanOption = ["WNI", "WNA"];
  const kategoriOption = ["KENDARAAN RODA 2", "KENDARAAN RODA 4"];
  const jenisResikoOption = ["RENDAH", "SEDANG", "TINGGI"];

  let kelurahanOptions = kelurahans.map((kelurahan) => ({
    label: `${kelurahan.id} - ${kelurahan.namaKelurahan}`,
  }));
  let kecamatanOptions = kecamatans.map((kecamatan) => ({
    label: `${kecamatan.id} - ${kecamatan.namaKecamatan}`,
  }));
  let kabupatenOptions = kabupatens.map((kabupaten) => ({
    label: `${kabupaten.id} - ${kabupaten.namaKabupaten}`,
  }));
  let provinsiOptions = provinsis.map((provinsi) => ({
    label: `${provinsi.id} - ${provinsi.namaProvinsi}`,
  }));
  let coaOptions = coas.map((coa) => ({
    label: `${coa.kodeCOA} - ${coa.namaCOA}`,
  }));
  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));
  let tingkatRisikoOptions = tingkatRisikos.map((tingkatRisiko) => ({
    label: `${tingkatRisiko.kodeTingkatRisiko} - ${tingkatRisiko.namaTingkatRisiko}`,
  }));
  let surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));
  let tipeOptions = tipes.map((tipe) => ({
    label: `${tipe.kodeTipe} | ${tipe.namaTipe}`,
  }));

  useEffect(() => {
    getAreaData();
    getMarketingsData();
    getTingkatRisikosData();
    getCoasData();
    getSurveyorsData();
    getTipesData();
    getWarnasData();
    getPengajuanFidusiaNextKode();
  }, []);

  useEffect(() => {
    getCustomersData();
  }, [page, searchTermCustomer]);

  const getCoasData = async () => {
    const response = await axios.post(`${tempUrl}/COAsKasBank`, {
      _id: user.id,
      token: user.token,
    });
    setCoas(response.data);
    setKodeCOA(setting.kasPenerimaanFidusia);
    setJenisResikoAju(jenisResikoOption[0]);
  };

  const getAreaData = async () => {
    const allKelurahan = await axios.post(`${tempUrl}/kelurahans`, {
      _id: user.id,
      token: user.token,
    });
    setKelurahans(allKelurahan.data);

    const allKecamatan = await axios.post(`${tempUrl}/kecamatans`, {
      _id: user.id,
      token: user.token,
    });
    setKecamatans(allKecamatan.data);

    const allKabupaten = await axios.post(`${tempUrl}/kabupatens`, {
      _id: user.id,
      token: user.token,
    });
    setKabupatens(allKabupaten.data);

    const allProvinsi = await axios.post(`${tempUrl}/provinsis`, {
      _id: user.id,
      token: user.token,
    });
    setProvinsis(allProvinsi.data);

    setJenisKelaminPenjamin(jenisKelaminOption[0]);
    setAgamaPenjamin(agamaOption[0]);
    setStatusPerkawinanPenjamin(statusPerkawinanOption[0]);
    setKewarganegaraanPenjamin(kewarganegaraanOption[0]);
    setKategori(kategoriOption[0]);
  };

  const findKelurahan = async (kelurahanId) => {
    if (kelurahanId) {
      const response = await axios.post(
        `${tempUrl}/kelurahans/${kelurahanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKelurahanPenjamin(kelurahanId);
      if (kelurahanId == "99999999") {
        setKodeProvinsiPenjamin("");
        setKodeKabupatenPenjamin("");
        setKodeKecamatanPenjamin("");
        setKodePosPenjamin("");
        return;
      }
      if (response.data) {
        setKodeProvinsiPenjamin(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupatenPenjamin(
          `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
        );
        setKodeKecamatanPenjamin(
          `${response.data.kecamatan.id} - ${response.data.kecamatan.namaKecamatan}`
        );
        setKodePosPenjamin(response.data.kodePos);
      }
    }
  };

  const findKecamatan = async (kecamatanId) => {
    kecamatanId = kecamatanId.split(" ", 1)[0];
    if (kecamatanId) {
      const response = await axios.post(
        `${tempUrl}/kecamatans/${kecamatanId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKecamatanPenjamin(kecamatanId);
      if (kecamatanId == "999999") {
        setKodeProvinsiPenjamin("");
        setKodeKabupatenPenjamin("");
        setKodeKecamatanPenjamin(
          `${response.data.id} - ${response.data.namaKecamatan}`
        );
        return;
      }
      if (response.data) {
        setKodeProvinsiPenjamin(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupatenPenjamin(
          `${response.data.kabupaten.id} - ${response.data.kabupaten.namaKabupaten}`
        );
        setKodeKecamatanPenjamin(
          `${response.data.id} - ${response.data.namaKecamatan}`
        );
      }
    } else {
      setKodeKecamatanPenjamin("");
      setKodeKabupatenPenjamin("");
      setKodeProvinsiPenjamin("");
    }
  };

  const findKabupaten = async (kabupatenId) => {
    kabupatenId = kabupatenId.split(" ", 1)[0];
    if (kabupatenId) {
      const response = await axios.post(
        `${tempUrl}/kabupatens/${kabupatenId}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeKabupatenPenjamin(kabupatenId);
      if (kabupatenId == "9999") {
        setKodeProvinsiPenjamin("");
        setKodeKabupatenPenjamin(
          `${response.data.id} - ${response.data.namaKabupaten}`
        );
        return;
      }
      if (response.data) {
        setKodeProvinsiPenjamin(
          `${response.data.provinsis.id} - ${response.data.provinsis.namaProvinsi}`
        );
        setKodeKabupatenPenjamin(
          `${response.data.id} - ${response.data.namaKabupaten}`
        );
      }
    } else {
      setKodeKabupatenPenjamin("");
      setKodeProvinsiPenjamin("");
    }
  };

  const findProvinsi = async (provinsiId) => {
    provinsiId = provinsiId.split(" ", 1)[0];
    if (provinsiId) {
      const response = await axios.post(`${tempUrl}/provinsis/${provinsiId}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeProvinsiPenjamin(provinsiId);
      if (response.data) {
        setKodeProvinsiPenjamin(
          `${response.data.id} - ${response.data.namaProvinsi}`
        );
      }
    } else {
      setKodeProvinsiPenjamin("");
    }
  };

  const findTipe = async (id) => {
    id = id.split(" |", 1)[0];
    if (id) {
      const response = await axios.post(`${tempUrl}/tipeByKodeTipe`, {
        kodeTipe: id,
        _id: user.id,
        token: user.token,
      });
      setKodeTipe(id);
      setNoRangka(response.data.noRangka);
      setMerk(response.data.merk);
      setIsi(response.data.isi);
      setNoMesin(response.data.noMesin);
    }
  };

  const getCustomersData = async () => {
    const response = await axios.post(
      `${tempUrl}/customersPerCabangPagination?search_query=${searchTermCustomer}&page=${page}&limit=${limit}`,
      {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setCustomers(response.data.customers);
    setPage(response.data.page);
    setPages(response.data.totalPage);
    setRows(response.data.totalRows);
  };

  const getMarketingsData = async () => {
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
  };

  const getTingkatRisikosData = async () => {
    const response = await axios.post(`${tempUrl}/tingkatRisikos`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setTingkatRisikos(response.data);
  };

  const getSurveyorsData = async () => {
    const response = await axios.post(`${tempUrl}/surveyors`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setSurveyors(response.data);
  };

  const getTipesData = async () => {
    const response = await axios.post(`${tempUrl}/tipes`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setTipes(response.data);
  };

  const getWarnasData = async () => {
    setKodeWarna("");
    const response = await axios.post(`${tempUrl}/warnas`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setWarnas(response.data);
    setKodeWarna(response.data[0].id);
  };

  const getPengajuanFidusiaNextKode = async () => {
    const response = await axios.post(`${tempUrl}/pengajuanFidusiaNextKode`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setNoAju(response.data);
  };

  const findTingkatRisikoByKode = async (kodeTingkatRisiko) => {
    const response = await axios.post(`${tempUrl}/tingkatRisikoByKode`, {
      kodeTingkatRisiko,
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    if (response.data) {
      setKodeTingkatRisikoData(response.data);
    } else {
      setKodeTingkatRisikoData({
        tppuTingkatRisiko: "",
        tpptTingkatRisiko: "",
        ppspmTingkatRisiko: "",
      });
    }
  };

  function dateWithMonthsDelay(input, months) {
    const date = new Date(input);
    date.setMonth(date.getMonth() + months);

    return date;
  }

  const savePengajuanFidusia = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      cifCustomer.length !== 0 &&
      kodeKelurahanPenjamin.length !== 0 &&
      kodeMarketing.length !== 0 &&
      kodeTingkatRisiko.length !== 0 &&
      kodeSurveyor.length !== 0 &&
      kodeCOA.length !== 0 &&
      kodeTipe.length !== 0
    ) {
      try {
        const findPengajuanFidusiaByNoPerjanjian = await axios.post(
          `${tempUrl}/pengajuanFidusiaByNoPerjanjian`,
          {
            noPerjanjian,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          }
        );
        if (findPengajuanFidusiaByNoPerjanjian.data) {
          alert(`No. Perjanjian ${noPerjanjian} sudah ada!`);
          return;
        }

        setLoading(true);

        await axios.post(`${tempUrl}/savePengajuanFidusia`, {
          // Data Customer
          cifCustomer,
          jenisResikoAju,
          kodeTingkatRisiko,
          ketResikoAju,

          //   Data Penjamin
          nikPenjamin,
          noKkPenjamin,
          namaPenjamin,
          tempatLahirPenjamin,
          tanggalLahirPenjamin,
          jenisKelaminPenjamin,
          agamaPenjamin,
          noTeleponPenjamin,
          namaRefRegister,
          alamatRefRegister,
          teleponRefRegister,
          alamatPenjamin,
          statusPerkawinanPenjamin,
          pekerjaanPenjamin,
          kewarganegaraanPenjamin,

          kelurahanIdPenjamin: kodeKelurahanPenjamin,
          provinsiIdPenjamin: kodeProvinsiPenjamin.split(" ", 1)[0],
          kabupatenIdPenjamin: kodeKabupatenPenjamin.split(" ", 1)[0],
          kecamatanIdPenjamin: kodeKecamatanPenjamin.split(" ", 1)[0],

          ketProvinsiPenjamin,
          ketKabupatenPenjamin,
          ketKecamatanPenjamin,
          ketKelurahanPenjamin,
          kodePosPenjamin,

          // Data Pinjaman
          noPerjanjian,
          kategori,
          tglAng,
          tglJatuhTempo: tglAng,
          pinjamanAju: pinjamanAju,
          angPerBulan: angPerBulan,
          kodeMarketing,
          tanggalAju,
          tenor,
          sisaBulan: tenor,
          bungaPerTahun: bungaPerTahun,
          totalPiutang,
          kodeSurveyor,
          kodeCOA,

          // Data Kendaraan
          kodeTipe,
          tahun,
          noRangka,
          kodeWarna,
          noBpkb,
          tglStnk,
          noMesin,
          isi,
          merk,
          nopol,
          bpkbAN,

          // Data Penilaian
          hargaPasarSetempatOtr,
          taksiran,
          maksimalPemberianPinjaman,

          // Data Asuransi
          noFidusia,
          nilaiPertanggungan: nilaiPertanggungan,
          noAsuransi,

          kodeCabang: user.cabang.id,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarPengajuanFidusia");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const countDateDuration = (date) => {
    var tempDate = new Date(date);
    var final = tempDate.setMonth(tempDate.getMonth() + parseInt(tenor) - 1);
    var finalDate = new Date(final);
    setTglAngAkhir(new Date(finalDate));
  };

  const countDateDurationReview = (tenor, date) => {
    var tempDate = new Date(date);
    var final = tempDate.setMonth(tempDate.getMonth() + parseInt(tenor) - 1);
    var finalDate = new Date(final);
    setTglAngAkhir(new Date(finalDate));
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Fidusia</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Pengajuan</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={savePengajuanFidusia}>
        <Card>
          <Card.Header>Data Pribadi Nasabah</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK / CIF <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control value={nikCustomer} disabled readOnly />
                      </Col>
                      <Col sm="4">
                        <Form.Control
                          required
                          value={cifCustomer}
                          readOnly
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenCustomer()}
                          isInvalid={cifCustomer.length === 0 && true}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKkCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tempatLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tanggalLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisKelaminCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={agamaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noTeleponCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={statusPerkawinanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kewarganegaraanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Resiko <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={jenisResikoAju}
                          onChange={(e) => {
                            setJenisResikoAju(e.target.value);
                          }}
                        >
                          {jenisResikoOption.map((jenisResiko) => (
                            <option value={jenisResiko}>{jenisResiko}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pekerjaanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Card style={{ marginTop: 10, marginBottom: 10 }}>
                  <Card.Header>Tingkat Risiko</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kelompok Kerja <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={tingkatRisikoOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeTingkatRisiko.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                setKodeTingkatRisiko(value.split(" ", 1)[0]);
                                findTingkatRisikoByKode(value.split(" ", 1)[0]);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPU :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                kodeTingkatRisikoData.tppuTingkatRisiko
                              )}
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPT :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                kodeTingkatRisikoData.tpptTingkatRisiko
                              )}
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            PPSPM :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                kodeTingkatRisikoData.ppspmTingkatRisiko
                              )}
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={ketResikoAju}
                          onChange={(e) =>
                            setKetResikoAju(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>

              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupaten}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePos} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{ marginTop: "10px" }}>
                  <Card.Header>Tempat Tinggal</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatTTCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupatenTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsiTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePosTT} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penjamin</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nikPenjamin}
                          onChange={(e) =>
                            setNikPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noKkPenjamin}
                          onChange={(e) =>
                            setNoKkPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaPenjamin}
                          onChange={(e) =>
                            setNamaPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tempatLahirPenjamin}
                          onChange={(e) =>
                            setTempatLahirPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          required
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control />}
                          selected={tanggalLahirPenjamin}
                          onChange={(date) => setTanggalLahirPenjamin(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={jenisKelaminPenjamin}
                          onChange={(e) => {
                            setJenisKelaminPenjamin(e.target.value);
                          }}
                        >
                          {jenisKelaminOption.map((jenisKelamin) => (
                            <option value={jenisKelamin}>{jenisKelamin}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={agamaPenjamin}
                          onChange={(e) => {
                            setAgamaPenjamin(e.target.value);
                          }}
                        >
                          {agamaOption.map((agama) => (
                            <option value={agama}>{agama}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noTeleponPenjamin}
                          onChange={(e) =>
                            setNoTeleponPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={statusPerkawinanPenjamin}
                          onChange={(e) => {
                            setStatusPerkawinanPenjamin(e.target.value);
                          }}
                        >
                          {statusPerkawinanOption.map((statusPerkawinan) => (
                            <option value={statusPerkawinan}>
                              {statusPerkawinan}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pekerjaanPenjamin}
                          onChange={(e) =>
                            setPekerjaanPenjamin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={kewarganegaraanPenjamin}
                          onChange={(e) => {
                            setKewarganegaraanPenjamin(e.target.value);
                          }}
                        >
                          {kewarganegaraanOption.map((kewarganegaraan) => (
                            <option value={kewarganegaraan}>
                              {kewarganegaraan}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaRefRegister}
                          onChange={(e) =>
                            setNamaRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={alamatRefRegister}
                          onChange={(e) =>
                            setAlamatRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={teleponRefRegister}
                          onChange={(e) =>
                            setTeleponRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={alamatPenjamin}
                              onChange={(e) =>
                                setAlamatPenjamin(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kelurahanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeKelurahanPenjamin.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                findKelurahan(value.split(" ", 1)[0]);
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              disabled={kodeKelurahanPenjamin !== "99999999"}
                              value={ketKelurahanPenjamin}
                              onChange={(e) =>
                                setKetKelurahanPenjamin(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kecamatanOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeKecamatanPenjamin.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onChange={(e, value) => {
                                if (value) {
                                  findKecamatan(value.label);
                                } else {
                                  findKecamatan("");
                                }
                              }}
                              value={kodeKecamatanPenjamin}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              disabled={
                                kodeKecamatanPenjamin.split(" ", 1)[0] !==
                                "999999"
                              }
                              value={ketKecamatanPenjamin}
                              onChange={(e) =>
                                setKetKecamatanPenjamin(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kabupatenOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeKabupatenPenjamin.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onChange={(e, value) => {
                                if (value) {
                                  findKabupaten(value.label);
                                } else {
                                  findKabupaten("");
                                }
                              }}
                              value={kodeKabupatenPenjamin}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              disabled={
                                kodeKabupatenPenjamin.split(" ", 1)[0] !==
                                "9999"
                              }
                              value={ketKabupatenPenjamin}
                              onChange={(e) =>
                                setKetKabupatenPenjamin(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={provinsiOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeProvinsiPenjamin.length === 0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onChange={(e, value) => {
                                if (value) {
                                  findProvinsi(value.label);
                                } else {
                                  findProvinsi("");
                                }
                              }}
                              value={kodeProvinsiPenjamin}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label
                            column
                            sm="4"
                            style={textRight}
                          ></Form.Label>
                          <Col sm="8">
                            <Form.Control
                              disabled={
                                kodeProvinsiPenjamin.split(" ", 1)[0] !== "99"
                              }
                              value={ketProvinsiPenjamin}
                              onChange={(e) =>
                                setKetProvinsiPenjamin(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              type="number"
                              value={kodePosPenjamin}
                              disabled={
                                kodeKelurahanPenjamin.split(" ", 1)[0] !==
                                "99999999"
                              }
                              onChange={(e) =>
                                setKodePosPenjamin(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Pinjaman</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Pengajuan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Perjanjian :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noPerjanjian}
                          onChange={(e) =>
                            setNoPerjanjian(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pinjaman Rp. <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={pinjamanAju}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setPinjamanAju(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran/Bulan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={angPerBulan}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setAngPerBulan(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );

                            let tempNum = values.formattedValue
                              .split(".")
                              .join("")
                              .replace(/,/g, "");

                            let tempTotalPiutang = parseFloat(tempNum) * tenor;
                            setTotalPiutang(tempTotalPiutang);

                            let tempBungaPerTahun = (
                              ((tempTotalPiutang - pinjamanAju) /
                                pinjamanAju /
                                (tenor / 12)) *
                              100
                            ).toFixed(2);

                            if (
                              isFinite(tempBungaPerTahun) &&
                              !isNaN(tempBungaPerTahun)
                            ) {
                              setBungaPerTahun(tempBungaPerTahun);
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tenor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          type="number"
                          value={tenor}
                          onChange={(e) => {
                            setTenor(e.target.value);
                            let tempTotalPiutang =
                              parseInt(angPerBulan) * e.target.value;
                            setTotalPiutang(tempTotalPiutang);

                            let tempBungaPerTahun = (
                              ((tempTotalPiutang - pinjamanAju) /
                                pinjamanAju /
                                (e.target.value / 12)) *
                              100
                            ).toFixed(2);

                            if (
                              isFinite(tempBungaPerTahun) &&
                              !isNaN(tempBungaPerTahun)
                            ) {
                              setBungaPerTahun(tempBungaPerTahun);
                            }
                            if (e.target.value) {
                              countDateDurationReview(e.target.value, tglAng);
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Bunga/Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={bungaPerTahun.toLocaleString("de-DE")}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          disabled
                          readOnly
                        />

                        {/* <Form.Control
                          required
                          value={bungaPerTahun.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        /> */}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={totalPiutang.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control required />}
                          selected={tanggalAju}
                          onChange={(date) => setTanggalAju(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang 1 <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          required
                          dateFormat="dd/MM/yyyy"
                          selected={tglAng}
                          customInput={<Form.Control required />}
                          onChange={(date) => {
                            setTglAng(date);
                            countDateDuration(date);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang Akhir :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          required
                          dateFormat="dd/MM/yyyy"
                          selected={tglAngAkhir}
                          customInput={
                            <Form.Control required disabled readOnly />
                          }
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kategori <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={kategori}
                          onChange={(e) => {
                            setKategori(e.target.value);
                          }}
                        >
                          {kategoriOption.map((kategori) => (
                            <option value={kategori}>{kategori}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Marketing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={marketingOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error && kodeMarketing.length === 0 && true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeMarketing(value.split(" ", 1)[0]);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Surveyor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={surveyorOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeSurveyor.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeSurveyor(value.split(" ", 1)[0]);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kas <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={coaOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeCOA.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setKodeCOA(value.split(" ", 1)[0]);
                          }}
                          defaultValue={setting.kasPenerimaanFidusia}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Kendaraan</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={tipeOptions}
                          renderInput={(params) => (
                            <TextField
                              error={error && kodeTipe.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onChange={(e, value) => {
                            if (value) {
                              findTipe(value.label);
                            } else {
                              findTipe("");
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          type="number"
                          value={tahun}
                          onChange={(e) => {
                            if (e.target.value.toString().length <= 4) {
                              setTahun(e.target.value.toUpperCase());
                            }
                          }}
                          isInvalid={tahun.length != 4 && true}
                        />
                        <Form.Control.Feedback type="invalid">
                          Tahun harus diisi dan harus 4 digit angka!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noRangka}
                          onChange={(e) =>
                            setNoRangka(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Mesin <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noMesin}
                          onChange={(e) =>
                            setNoMesin(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Polisi <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nopol}
                          onChange={(e) =>
                            setNopol(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Warna <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          value={kodeWarna}
                          onChange={(e) => {
                            setKodeWarna(e.target.value);
                          }}
                        >
                          {warnas.map((warna, index) => (
                            <option value={warna.id}>{warna.namaWarna}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Merk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={merk}
                          onChange={(e) =>
                            setMerk(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        CC :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={isi}
                          onChange={(e) => setIsi(e.target.value.toUpperCase())}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Stnk <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          required
                          dateFormat="dd/MM/yyyy"
                          customInput={<Form.Control />}
                          selected={tglStnk}
                          onChange={(date) => setTglStnk(date)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. BPKP <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noBpkb}
                          onChange={(e) =>
                            setNoBpkb(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        BPKP A/N <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bpkbAN}
                          onChange={(e) =>
                            setBpkbAN(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penilaian</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Harga Pasar Setempat/Otr <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={hargaPasarSetempatOtr}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setHargaPasarSetempatOtr(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Taksiran <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={taksiran}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setTaksiran(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Maksimal Pemberian Pinjaman <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <NumericFormat
                          required
                          value={maksimalPemberianPinjaman}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setMaksimalPemberianPinjaman(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Asuransi</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noFidusia}
                          onChange={(e) =>
                            setNoFidusia(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nilai Pertanggungan :
                      </Form.Label>
                      <Col sm="8">
                        <NumericFormat
                          required
                          value={nilaiPertanggungan}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            setNilaiPertanggungan(
                              values.formattedValue
                                .split(".")
                                .join("")
                                .replace(/,/g, "")
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Asuransi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noAsuransi}
                          onChange={(e) =>
                            setNoAsuransi(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarPengajuanFidusia")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Card.Body>
        </Card>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openCustomer}
        onClose={handleCloseCustomer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Customer`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Alamat
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      No. Telp / HP
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer, index) => (
                    <TableRow
                      key={customer._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        const findTotalPengajuanPerCustomer = await axios.post(
                          `${tempUrl}/pengajuanByCustomerId`,
                          {
                            customerId: customer.id,
                            _id: user.id,
                            token: user.token,
                            kodeCabang: user.cabang.id,
                          }
                        );
                        if (findTotalPengajuanPerCustomer.data.length >= 3) {
                          alert(
                            `Customer ${customer.cifCustomer} telah memiliki pengajuan yang belum approve 3 kali!`
                          );
                        } else {
                          setCifCustomer(customer.cifCustomer);
                          setNikCustomer(customer.nikCustomer);
                          setNoKkCustomer(customer.noKkCustomer);
                          setNamaCustomer(customer.namaCustomer);
                          setTempatLahirCustomer(customer.tempatLahirCustomer);
                          setTanggalLahirCustomer(
                            formatDate(customer.tanggalLahirCustomer)
                          );
                          setJenisKelaminCustomer(
                            customer.jenisKelaminCustomer
                          );
                          setAgamaCustomer(customer.agamaCustomer);
                          setNoTeleponCustomer(customer.noTeleponCustomer);
                          setAlamatCustomer(customer.alamatCustomer);
                          setAlamatTTCustomer(customer.alamatTTCustomer);
                          setKodeKelurahan(
                            `${customer.kelurahan.id} - ${customer.kelurahan.namaKelurahan}`
                          );
                          setKodeKelurahanTT(
                            `${customer.kelurahanTT.id} - ${customer.kelurahanTT.namaKelurahan}`
                          );
                          setKodeKecamatan(
                            `${customer.kecamatan.id} - ${customer.kecamatan.namaKecamatan}`
                          );
                          setKodeKecamatanTT(
                            `${customer.kecamatanTT.id} - ${customer.kecamatanTT.namaKecamatan}`
                          );
                          setKodeKabupaten(
                            `${customer.kabupaten.id} - ${customer.kabupaten.namaKabupaten}`
                          );
                          setKodeKabupatenTT(
                            `${customer.kabupatenTT.id} - ${customer.kabupatenTT.namaKabupaten}`
                          );
                          setKodeProvinsi(
                            `${customer.provinsis.id} - ${customer.provinsis.namaProvinsi}`
                          );
                          setKodeProvinsiTT(
                            `${customer.provinsisTT.id} - ${customer.provinsisTT.namaProvinsi}`
                          );
                          setKodePos(customer.kelurahan.kodePos);
                          setKodePosTT(customer.kelurahanTT.kodePos);
                          setStatusPerkawinanCustomer(
                            customer.statusPerkawinanCustomer
                          );
                          setPekerjaanCustomer(customer.pekerjaanCustomer);
                          setKewarganegaraanCustomer(
                            customer.kewarganegaraanCustomer
                          );
                          countDateDurationReview(tenor, tglAng);
                        }
                        handleCloseCustomer();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {customer.namaCustomer}
                      </TableCell>
                      <TableCell>{customer.alamatCustomer}</TableCell>
                      <TableCell>{customer.noTeleponCustomer}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TambahPengajuanFidusia;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

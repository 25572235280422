import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LaporanPenerimaanFidusia = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  const [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  const [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  const [kasirId, setKasirId] = useState("");
  const [namaKasir, setNamaKasir] = useState("");
  const [marketingId, setMarketingId] = useState("");
  const [namaMarketing, setNamaMarketing] = useState("");
  const [surveyorId, setSurveyorId] = useState("");
  const [namaSurveyor, setNamaSurveyor] = useState("");
  const [kategori, setKategori] = useState("");

  const [cabangs, setCabangs] = useState([]);
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [kasirs, setKasirs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapTotalPiutangData, setLapTotalPiutangData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);
  const [error, setError] = useState(false);

  const kategoriOption = ["KENDARAAN RODA 2", "KENDARAAN RODA 4"];
  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
    id: marketing.id,
  }));
  let surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
    id: surveyor.id,
  }));
  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));
  let kasirOptions = kasirs.map((kasir) => ({
    label: kasir.username,
    id: kasir.id,
  }));

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanPenerimaanFidusia",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let penerimaanFidusias = await axios.post(
        `${tempUrl}/laporanPenerimaanFidusia`,
        {
          dariTanggal,
          sampaiTanggal,
          kodeCabang,
          marketingId,
          surveyorId,
          kasirId,
          kategori,
          _id: user.id,
          token: user.token,
        }
      );
      setLapTotalPiutangData(penerimaanFidusias.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getCabangsData();
    getKasirsData();
    getMarketingsData();
    getSurveyorsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getSurveyorsData = async () => {
    const response = await axios.post(`${tempUrl}/surveyors`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setSurveyors(response.data);
  };

  const getMarketingsData = async () => {
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
  };

  const getKasirsData = async () => {
    const response = await axios.post(`${tempUrl}/users`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKasirs(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textBold = {
    fontWeight: 700,
    border: "1px solid black",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const textNumberRightBold = {
    textAlign: "right",
    fontWeight: 700,
    border: "1px solid black",
  };

  const list = [];
  let tempTotalModal = 0;
  let tempTotalBunga = 0;
  let tempTotalDenda = 0;
  let tempTotalPotongan = 0;
  let tempTotal = 0;

  for (let j = 0; j < lapTotalPiutangData.length; j++) {
    tempTotalModal += lapTotalPiutangData[j].angModal;
    tempTotalBunga += lapTotalPiutangData[j].angBunga;
    tempTotalDenda += lapTotalPiutangData[j].denda;
    tempTotalPotongan += lapTotalPiutangData[j].potongan;
    tempTotal += lapTotalPiutangData[j].totalBayar;

    list.push(
      <tr style={tableText}>
        <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
          {lapTotalPiutangData[j].no}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].noKwitansi}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].keterangan}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].angModal.toLocaleString("en-EN")
            : lapTotalPiutangData[j].angModal}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].angBunga.toLocaleString("en-EN")
            : lapTotalPiutangData[j].angBunga}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].denda.toLocaleString("en-EN")
            : lapTotalPiutangData[j].denda}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].potongan.toLocaleString("en-EN")
            : lapTotalPiutangData[j].potongan}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].totalBayar.toLocaleString("en-EN")
            : lapTotalPiutangData[j].totalBayar}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].kasir}
        </td>
      </tr>
    );
  }

  list.push(
    <tr style={tableText}>
      <td style={textBold} colspan="3">
        TOTAL :
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalModal.toLocaleString("en-EN")
          : tempTotalModal}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalBunga.toLocaleString("en-EN")
          : tempTotalBunga}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalDenda.toLocaleString("en-EN")
          : tempTotalDenda}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalPotongan.toLocaleString("en-EN")
          : tempTotalPotongan}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false ? tempTotal.toLocaleString("en-EN") : tempTotal}
      </td>
      <td></td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanPenerimaanFidusia",
    sheet: "LaporanPenerimaanFidusia",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Penerimaan Fidusia</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Marketing :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={marketingOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && marketingId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA MARKETING"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setMarketingId(value.id);
                        setNamaMarketing(value.label);
                      } else {
                        setMarketingId("");
                        setNamaMarketing("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={marketingId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Surveyor :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={surveyorOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && surveyorId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA SURVEYOR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setSurveyorId(value.id);
                        setNamaSurveyor(value.label);
                      } else {
                        setSurveyorId("");
                        setNamaSurveyor("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={surveyorId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kasir :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kasirOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kasirId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KASIR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKasirId(value.id);
                        setNamaKasir(value.label);
                      } else {
                        setKasirId("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kasirId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kategori :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kategoriOption}
                    renderInput={(params) => (
                      <TextField
                        error={error && kategori.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KATEGORI"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => {
                      setKategori(value);
                      setPreviewPdf(false);
                    }}
                    defaultValue={kategori}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Total Piutang
          </h5>
          <p>Cabang : {namaCabang}</p>
          <p>Marketing : {namaMarketing}</p>
          <p>Surveyor : {namaSurveyor}</p>
          <p>Kasir : {namaKasir}</p>
          <p>Kategori : {kategori}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No</th>
                <th style={{ border: "1px solid black" }}>Kwitansi</th>
                <th style={{ border: "1px solid black" }}>Keterangan</th>
                <th style={{ border: "1px solid black" }}>A. Pokok</th>
                <th style={{ border: "1px solid black" }}>A. Bunga</th>
                <th style={{ border: "1px solid black" }}>Denda</th>
                <th style={{ border: "1px solid black" }}>Potongan</th>
                <th style={{ border: "1px solid black" }}>Total</th>
                <th style={{ border: "1px solid black" }}>Kasir</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanPenerimaanFidusia;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

import { useContext, useState, useEffect, useRef } from "react";
import "../../../constants/report.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { findTingkatRisiko, formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableAngsuran } from "../../../components/ShowTable";
import { Loader, usePagination } from "../../../components";
import { Colors } from "../../../constants/styles";
import { Container, Card, Form, Row, Col, Table } from "react-bootstrap";
import { Box, ButtonGroup, Button, Pagination, Chip } from "@mui/material";
import jsPDF from "jspdf";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";

const TampilAngsuranFidusia = () => {
  const { screenSize } = useStateContext();
  const reportTemplateRefRekap = useRef(null);
  const reportTemplateRefRinci = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);

  // Data Customer
  const [cifCustomer, setCifCustomer] = useState("");
  const [nikCustomer, setNikCustomer] = useState("");
  const [noKkCustomer, setNoKkCustomer] = useState("");
  const [namaCustomer, setNamaCustomer] = useState("");
  const [tempatLahirCustomer, setTempatLahirCustomer] = useState("");
  const [tanggalLahirCustomer, setTanggalLahirCustomer] = useState("");
  const [jenisKelaminCustomer, setJenisKelaminCustomer] = useState("");
  const [agamaCustomer, setAgamaCustomer] = useState("");
  const [noTeleponCustomer, setNoTeleponCustomer] = useState("");
  const [alamatCustomer, setAlamatCustomer] = useState("");
  const [alamatTTCustomer, setAlamatTTCustomer] = useState("");
  const [kodeProvinsi, setKodeProvinsi] = useState("");
  const [kodeProvinsiTT, setKodeProvinsiTT] = useState("");
  const [kodeKabupaten, setKodeKabupaten] = useState("");
  const [kodeKabupatenTT, setKodeKabupatenTT] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeKecamatanTT, setKodeKecamatanTT] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [kodeKelurahanTT, setKodeKelurahanTT] = useState("");
  const [kodePos, setKodePos] = useState("");
  const [kodePosTT, setKodePosTT] = useState("");
  const [statusPerkawinanCustomer, setStatusPerkawinanCustomer] = useState("");
  const [pekerjaanCustomer, setPekerjaanCustomer] = useState("");
  const [kewarganegaraanCustomer, setKewarganegaraanCustomer] = useState("");
  const [jenisResikoAju, setJenisResikoAju] = useState("");
  const [tingkatRisiko, setTingkatRisiko] = useState("");
  const [tingkatRisikoData, setTingkatRisikoData] = useState({});
  const [ketResikoAju, setKetResikoAju] = useState("");

  // Data Penjamin
  const [nikPenjamin, setNikPenjamin] = useState("");
  const [noKkPenjamin, setNoKkPenjamin] = useState("");
  const [namaPenjamin, setNamaPenjamin] = useState("");
  const [tempatLahirPenjamin, setTempatLahirPenjamin] = useState("");
  const [tanggalLahirPenjamin, setTanggalLahirPenjamin] = useState("");
  const [jenisKelaminPenjamin, setJenisKelaminPenjamin] = useState("");
  const [agamaPenjamin, setAgamaPenjamin] = useState("");
  const [noTeleponPenjamin, setNoTeleponPenjamin] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [alamatRefRegister, setAlamatRefRegister] = useState("");
  const [teleponRefRegister, setTeleponRefRegister] = useState("");
  const [alamatPenjamin, setAlamatPenjamin] = useState("");
  const [kodeProvinsiPenjamin, setKodeProvinsiPenjamin] = useState("");
  const [kodeKabupatenPenjamin, setKodeKabupatenPenjamin] = useState("");
  const [kodeKecamatanPenjamin, setKodeKecamatanPenjamin] = useState("");
  const [kodeKelurahanPenjamin, setKodeKelurahanPenjamin] = useState("");
  const [kodePosPenjamin, setKodePosPenjamin] = useState("");
  const [statusPerkawinanPenjamin, setStatusPerkawinanPenjamin] = useState("");
  const [pekerjaanPenjamin, setPekerjaanPenjamin] = useState("");
  const [kewarganegaraanPenjamin, setKewarganegaraanPenjamin] = useState("");

  // Data Pinjaman
  const [noAju, setNoAju] = useState("");
  const [noPerjanjian, setNoPerjanjian] = useState("");
  const [kategori, setKategori] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [pinjamanAju, setPinjamanAju] = useState(0);
  const [angPerBulan, setAngPerBulan] = useState(0);
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [tanggalAju, setTanggalAju] = useState("");
  const [tenor, setTenor] = useState(0);
  const [bungaPerTahun, setBungaPerTahun] = useState(0);
  const [totalPiutang, setTotalPiutang] = useState(0);
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");

  // Data Kendaraan
  const [kodeTipe, setKodeTipe] = useState("");
  const [tahun, setTahun] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [kodeWarna, setKodeWarna] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [tglStnk, setTglStnk] = useState("");
  const [bpkbAN, setBpkbAN] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [isi, setIsi] = useState("");
  const [merk, setMerk] = useState("");
  const [nopol, setNopol] = useState("");

  // Data Penilaian
  const [hargaPasarSetempatOtr, setHargaPasarSetempatOtr] = useState("");
  const [taksiran, setTaksiran] = useState("");
  const [maksimalPemberianPinjaman, setMaksimalPemberianPinjaman] =
    useState("");

  // Data Approval
  const [noSbg, setNoSbg] = useState("");
  const [tglKontrak, setTglKontrak] = useState("");
  const [noKwitansi, setNoKwitansi] = useState();

  // Asuransi
  const [noFidusia, setNoFidusia] = useState("");
  const [nilaiPertanggungan, setNilaiPertanggungan] = useState(0);
  const [noAsuransi, setNoAsuransi] = useState("");

  const [angsurans, setAngsurans] = useState([]);
  const [isPost, setIsPost] = useState("");
  const navigate = useNavigate();
  const [previewPdfRekap, setPreviewPdfRekap] = useState(false);
  const [previewPdfRinci, setPreviewPdfRinci] = useState(false);

  const [historyTransaksi, setHistoryTransaksi] = useState([]);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 20;

  // Get current posts
  const indexOfLastPost = page * PER_PAGE;
  const indexOfFirstPost = indexOfLastPost - PER_PAGE;
  const currentPosts = angsurans.slice(indexOfFirstPost, indexOfLastPost);

  const count = Math.ceil(angsurans.length / PER_PAGE);
  const _DATA = usePagination(angsurans, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const countDateDuration = (tenor, date) => {
    var tempDate = new Date(date);
    var final = tempDate.setMonth(tempDate.getMonth() + parseInt(tenor));
    setTglAngAkhir(formatDate(final));
  };

  const handleGeneratePdfRekap = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRefRekap.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.4 },
    });
  };

  const handleGeneratePdfRinci = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRefRinci.current, {
      async callback(doc) {
        window.open(URL.createObjectURL(doc.output("blob")));
      },
      html2canvas: { scale: 0.4 },
    });
  };

  useEffect(() => {
    getPengajuanById();
  }, []);

  const getPengajuanById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/pengajuanFidusias/${id}`, {
      _id: user.id,
      token: user.token,
    });
    // Data Customer
    setCifCustomer(response.data.customer.cifCustomer);
    setNikCustomer(response.data.customer.nikCustomer);
    setNoKkCustomer(response.data.customer.noKkCustomer);
    setNamaCustomer(response.data.customer.namaCustomer);
    setTempatLahirCustomer(response.data.customer.tempatLahirCustomer);
    setTanggalLahirCustomer(
      formatDate(response.data.customer.tanggalLahirCustomer)
    );
    setJenisKelaminCustomer(response.data.customer.jenisKelaminCustomer);
    setAgamaCustomer(response.data.customer.agamaCustomer);
    setNoTeleponCustomer(response.data.customer.alamatCustomer);
    setAlamatCustomer(response.data.customer.alamatCustomer);
    setAlamatTTCustomer(response.data.customer.alamatTTCustomer);
    setKodeProvinsi(response.data.customer.provinsis.namaProvinsi);
    setKodeProvinsiTT(response.data.customer.provinsisTT.namaProvinsi);
    setKodeKabupaten(response.data.customer.kabupaten.namaKabupaten);
    setKodeKabupatenTT(response.data.customer.kabupatenTT.namaKabupaten);
    setKodeKecamatan(response.data.customer.kecamatan.namaKecamatan);
    setKodeKecamatanTT(response.data.customer.kecamatanTT.namaKecamatan);
    setKodeKelurahan(response.data.customer.kelurahan.namaKelurahan);
    setKodeKelurahanTT(response.data.customer.kelurahanTT.namaKelurahan);
    setKodePos(response.data.customer.kelurahan.kodePos);
    setKodePosTT(response.data.customer.kelurahanTT.kodePos);
    setStatusPerkawinanCustomer(
      response.data.customer.statusPerkawinanCustomer
    );
    setPekerjaanCustomer(response.data.customer.pekerjaanCustomer);
    setKewarganegaraanCustomer(response.data.customer.kewarganegaraanCustomer);
    setJenisResikoAju(response.data.jenisResikoAju);
    setTingkatRisiko(
      `${response.data.tingkatrisiko.kodeTingkatRisiko} - ${response.data.tingkatrisiko.namaTingkatRisiko}`
    );
    setTingkatRisikoData(response.data.tingkatrisiko);
    setKetResikoAju(response.data.ketResikoAju);

    // Data Penjamin
    setNikPenjamin(response.data.nikPenjamin);
    setNoKkPenjamin(response.data.noKkPenjamin);
    setNamaPenjamin(response.data.namaPenjamin);
    setTempatLahirPenjamin(response.data.tempatLahirPenjamin);
    setTanggalLahirPenjamin(formatDate(response.data.tanggalLahirPenjamin));
    setJenisKelaminPenjamin(response.data.jenisKelaminPenjamin);
    setAgamaPenjamin(response.data.agamaPenjamin);
    setNoTeleponPenjamin(response.data.noTeleponPenjamin);
    setNamaRefRegister(response.data.namaRefRegister);
    setAlamatRefRegister(response.data.alamatRefRegister);
    setTeleponRefRegister(response.data.teleponRefRegister);
    setAlamatPenjamin(response.data.alamatPenjamin);
    setKodeProvinsiPenjamin(response.data.provinsisPenjamin.namaProvinsi);
    setKodeKabupatenPenjamin(response.data.kabupatenPenjamin.namaKabupaten);
    setKodeKecamatanPenjamin(response.data.kecamatanPenjamin.namaKecamatan);
    setKodeKelurahanPenjamin(response.data.kelurahanPenjamin.namaKelurahan);
    setKodePosPenjamin(response.data.kelurahanPenjamin.kodePos);
    setStatusPerkawinanPenjamin(response.data.statusPerkawinanPenjamin);
    setPekerjaanPenjamin(response.data.pekerjaanPenjamin);
    setKewarganegaraanPenjamin(response.data.kewarganegaraanPenjamin);

    // Data Pinjaman
    setNoAju(response.data.noAju);
    setNoPerjanjian(response.data.noPerjanjian);
    setKategori(response.data.kategori);
    setPinjamanAju(response.data.pinjamanAju);
    setAngPerBulan(response.data.angPerBulan);
    setKodeMarketing(
      `${response.data.marketing.kodeMarketing} - ${response.data.marketing.namaMarketing}`
    );
    setTanggalAju(formatDate(response.data.tanggalAju));
    setTenor(response.data.tenor);
    setBungaPerTahun(response.data.bungaPerTahun);
    setTotalPiutang(response.data.totalPiutang);
    setKodeSurveyor(
      `${response.data.surveyor.kodeSurveyor}- ${response.data.surveyor.namaSurveyor}`
    );
    setKodeCOA(`${response.data.coa.kodeCOA} - ${response.data.coa.namaCOA}`);

    // Data Kendaraan
    setKodeTipe(response.data.tipe.kodeTipe);
    setTahun(response.data.tahun);
    setNoRangka(response.data.noRangka);
    setKodeWarna(response.data.warna.namaWarna);
    setNoBpkb(response.data.noBpkb);
    setTglStnk(formatDate(response.data.tglStnk));
    setBpkbAN(response.data.bpkbAN);
    setNoMesin(response.data.noMesin);
    setIsi(response.data.isi);
    setMerk(response.data.merk);
    setNopol(response.data.nopol);

    // Data Penilaian
    setHargaPasarSetempatOtr(response.data.hargaPasarSetempatOtr);
    setTaksiran(response.data.taksiran);
    setMaksimalPemberianPinjaman(response.data.maksimalPemberianPinjaman);

    // Data Asuransi
    setNoFidusia(response.data.noFidusia);
    setNilaiPertanggungan(response.data.nilaiPertanggungan);
    setNoAsuransi(response.data.noAsuransi);

    // Data Approval
    setNoSbg(response.data.noSbg);
    setTglKontrak(formatDate(response.data.tglKontrak));
    setTglAng(formatDate(response.data.tglAng));
    setNoKwitansi(response.data.noKwitansi);
    countDateDuration(response.data.tenor, response.data.tanggalAju);

    setIsPost(response.data.isPost);

    const allAngsuransByNoJual = await axios.post(
      `${tempUrl}/angsuranFidusiasByPengajuanFidusiaId`,
      {
        pengajuanFidusiaId: id,
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      }
    );
    setAngsurans(allAngsuransByNoJual.data);

    const historyTransaksi = await axios.post(`${tempUrl}/historyTransaksi`, {
      noPerjanjian: response.data.noPerjanjian,
      piutangNasabahFidusia: setting.piutangNasabahFidusia,
      pendapatanBungaPinjFidusia: setting.pendapatanBungaPinjFidusia,
      pendDendaPiutangFidusia: setting.pendDendaPiutangFidusia,
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setHistoryTransaksi(historyTransaksi.data);
    setLoading(false);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textNumberRight = {
    textAlign: "right",
  };

  const textBold = {
    fontWeight: 700,
  };

  const textNumberRightBold = {
    textAlign: "right",
    fontWeight: 700,
  };

  const listRekap = [];
  let tempTotalSaldo = totalPiutang;

  for (let j = 0; j < angsurans.length; j++) {
    tempTotalSaldo -= angsurans[j].angPerBulan;
    listRekap.push(
      <tr style={tableText}>
        <td>{angsurans[j].tglBayar}</td>
        <td>{angsurans[j].tglJatuhTempo}</td>
        <td>{angsurans[j].keterlambatan} hari</td>
        <td>{angsurans[j].noKwitansi}</td>
        <td>{angsurans[j].no}</td>
        <td>{angsurans[j].keterangan}</td>
        <td style={textNumberRight}>
          {angsurans[j].hutangDenda.toLocaleString("de-DE")}
        </td>
        <td style={textNumberRight}>
          {angsurans[j].bayar.toLocaleString("de-DE")}
        </td>
        <td style={textNumberRight}>
          {tempTotalSaldo.toLocaleString("de-DE")}
        </td>
      </tr>
    );
  }

  const listRinci = [];
  let tempTotalSaldoModal = pinjamanAju;
  let tempTotalSaldoBunga = totalPiutang - pinjamanAju;
  let tempTotalSaldoBayar = totalPiutang;

  for (let j = 0; j < angsurans.length; j++) {
    tempTotalSaldoModal -= angsurans[j].angModal;
    tempTotalSaldoBunga -= angsurans[j].angBunga;
    tempTotalSaldoBayar -= angsurans[j].angPerBulan;
    listRinci.push(
      <tr style={tableText}>
        <td>{angsurans[j].tglBayar}</td>
        <td>{angsurans[j].tglJatuhTempo}</td>
        <td>{angsurans[j].keterlambatan} hari</td>
        <td>{angsurans[j].noKwitansi}</td>
        <td>{angsurans[j].no}</td>
        <td style={textNumberRight}>
          {angsurans[j].hutangDenda.toLocaleString("de-DE")}
        </td>
        <td style={textNumberRight}>
          {angsurans[j].angModal.toLocaleString("de-DE")}
        </td>
        <td style={textNumberRight}>
          {(tempTotalSaldoModal > 10 ? tempTotalSaldoModal : 0).toLocaleString(
            "de-DE"
          )}
        </td>
        <td style={textNumberRight}>
          {angsurans[j].angBunga.toLocaleString("de-DE")}
        </td>
        <td style={textNumberRight}>
          {(tempTotalSaldoBunga > 10
            ? tempTotalSaldoBunga
            : tempTotalSaldoBunga
          ).toLocaleString("de-DE")}
        </td>
        <td style={textNumberRight}>
          {angsurans[j].bayar.toLocaleString("de-DE")}
        </td>
        <td style={textNumberRight}>
          {tempTotalSaldoBayar.toLocaleString("de-DE")}
        </td>
      </tr>
    );
  }

  const list = [];
  let tempTotalDebet = 0;
  let tempTotalKredit = 0;
  let tempNumber = 1;

  for (let j = 0; j < historyTransaksi.length; j++) {
    for (let i = 0; i < historyTransaksi[j].length; i++) {
      if (historyTransaksi[j][i].jumlah > 0) {
        if (historyTransaksi[j][i].jenis == "D") {
          tempTotalDebet += historyTransaksi[j][i].jumlah;
          list.push(
            <tr style={tableText}>
              <td style={{ paddingLeft: "15px" }}>{tempNumber}</td>
              <td style={{ paddingLeft: "15px" }}>
                {historyTransaksi[j][i].tglJurnalFormat}
              </td>
              <td>{historyTransaksi[j][i].noKwitansi}</td>
              <td>{historyTransaksi[j][i].noBukti}</td>
              <td>{historyTransaksi[j][i].noPerjanjian}</td>
              <td>{historyTransaksi[j][i].coa}</td>
              <td style={textNumberRight}>
                {historyTransaksi[j][i].jumlah.toLocaleString("de-DE")}
              </td>
              <td></td>
              <td style={textNumberRight}></td>
              <td>{historyTransaksi[j][i].keterangan}</td>
              <td>{historyTransaksi[j][i].kasir}</td>
            </tr>
          );
          tempNumber++;
        } else {
          tempTotalKredit += historyTransaksi[j][i].jumlah;
          list.push(
            <tr style={tableText}>
              <td style={{ paddingLeft: "15px" }}>{tempNumber}</td>
              <td style={{ paddingLeft: "15px" }}>
                {historyTransaksi[j][i].tglJurnalFormat}
              </td>
              <td>{historyTransaksi[j][i].noKwitansi}</td>
              <td>{historyTransaksi[j][i].noBukti}</td>
              <td>{historyTransaksi[j][i].noPerjanjian}</td>
              <td></td>
              <td style={textNumberRight}></td>
              <td>{historyTransaksi[j][i].coa}</td>
              <td style={textNumberRight}>
                {historyTransaksi[j][i].jumlah.toLocaleString("de-DE")}
              </td>
              <td>{historyTransaksi[j][i].keterangan}</td>
              <td>{historyTransaksi[j][i].kasir}</td>
            </tr>
          );
          tempNumber++;
        }
      }
    }
  }

  list.push(
    <tr style={textBold}>
      <td style={{ paddingLeft: "15px" }} colspan="6">
        Total
      </td>
      <td style={textNumberRightBold}>
        {tempTotalDebet.toLocaleString("de-DE")}
      </td>
      <td></td>
      <td style={textNumberRightBold}>
        {tempTotalKredit.toLocaleString("de-DE")}
      </td>
      <td colspan="2"></td>
    </tr>
  );

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Fidusia</h3>
      <h5 style={{ fontWeight: 400 }}>Data Angsuran</h5>
      <hr />
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate("/daftarAngsuranFidusia")}
        sx={{ marginLeft: 2, marginTop: 4, marginBottom: 4 }}
      >
        {"< Kembali"}
      </Button>

      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              setPreviewPdfRekap(!previewPdfRekap);
              setPreviewPdfRinci(false);
            }}
          >
            REKAP
          </Button>
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              setPreviewPdfRinci(!previewPdfRinci);
              setPreviewPdfRekap(false);
            }}
          >
            RINCI
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              navigate(`/daftarAngsuranFidusia/angsuranFidusia/${id}/sp`)
            }
          >
            {"SP >"}
          </Button>
        </ButtonGroup>
      </Box>

      {previewPdfRekap && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdfRekap}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRefRekap} style={cetakContainer}>
            <p style={cetakText}>{setting.namaPerusahaan}</p>
            <p style={cetakText}>{setting.alamatPerusahaan}</p>
            <p style={cetakText}>({setting.kabupatenPerusahaan})</p>
            <p style={cetakText}>{setting.kotaPerusahaan}</p>
            <p style={cetakText}>NO. TELP. {setting.teleponPerusahaan}</p>
            <p style={cetakCenterBold}>REKAP ANGSURAN</p>
            <div style={cetakWrapper}>
              <div style={cetakWrap}>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Nama</p>
                  </div>
                  <div style={{ marginLeft: "80px" }}>
                    <p style={cetakText}>: {namaCustomer}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Alamat</p>
                  </div>
                  <div style={{ marginLeft: "74px" }}>
                    <p style={cetakText}>: {alamatCustomer}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Tgl. Kontrak</p>
                  </div>
                  <div style={{ marginLeft: "36px" }}>
                    <p style={cetakText}>: {tglKontrak}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Pinjaman</p>
                  </div>
                  <div style={{ marginLeft: "56px" }}>
                    <p style={cetakText}>
                      : {pinjamanAju.toLocaleString("de-DE")}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Angs. / Bulan</p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <p style={cetakText}>
                      : {angPerBulan.toLocaleString("de-DE")}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Thn. / Warna</p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <p style={cetakText}>
                      : {tahun} / {kodeWarna}
                    </p>
                  </div>
                </div>
              </div>
              <div style={cetakWrapSecond}>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Tipe</p>
                  </div>
                  <div style={{ marginLeft: "80px" }}>
                    <p style={cetakText}>: {kodeTipe}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>No. Rangka</p>
                  </div>
                  <div style={{ marginLeft: "28px" }}>
                    <p style={cetakText}>: {noRangka}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>No. Mesin</p>
                  </div>
                  <div style={{ marginLeft: "40px" }}>
                    <p style={cetakText}>: {noMesin}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Nopol</p>
                  </div>
                  <div style={{ marginLeft: "70px" }}>
                    <p style={cetakText}>: {nopol}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>No. Pengajuan</p>
                  </div>
                  <div style={{ marginLeft: "60px" }}>
                    <p style={cetakText}>: {noAju}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Tgl. Angs. I</p>
                  </div>
                  <div style={{ marginLeft: "32px" }}>
                    <p style={cetakText}>: {tglAng}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Tgl. Angs. Akhir</p>
                  </div>
                  <div style={{ marginLeft: "2px" }}>
                    <p style={cetakText}>: {tglAngAkhir}</p>
                  </div>
                </div>
              </div>
            </div>
            <table class="styled-table">
              <thead>
                <tr>
                  <th>Tgl. Bayar</th>
                  <th>TJ. Tempo</th>
                  <th>Keterlambatan</th>
                  <th>No. Kwitansi</th>
                  <th>Ke</th>
                  <th>Keterangan</th>
                  <th>Hutang Denda</th>
                  <th>Bayar</th>
                  <th>Saldo</th>
                </tr>
              </thead>
              <tbody>{listRekap}</tbody>
            </table>
          </div>
        </div>
      )}

      {previewPdfRinci && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdfRinci}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRefRinci} style={cetakContainer}>
            <p style={cetakText}>{setting.namaPerusahaan}</p>
            <p style={cetakText}>{setting.alamatPerusahaan}</p>
            <p style={cetakText}>({setting.kabupatenPerusahaan})</p>
            <p style={cetakText}>{setting.kotaPerusahaan}</p>
            <p style={cetakText}>NO. TELP. {setting.teleponPerusahaan}</p>
            <p style={cetakCenterBold}>RINCI ANGSURAN</p>
            <div style={cetakWrapper}>
              <div style={cetakWrap}>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Nama</p>
                  </div>
                  <div style={{ marginLeft: "80px" }}>
                    <p style={cetakText}>: {namaCustomer}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Alamat</p>
                  </div>
                  <div style={{ marginLeft: "74px" }}>
                    <p style={cetakText}>: {alamatCustomer}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Tgl. Kontrak</p>
                  </div>
                  <div style={{ marginLeft: "36px" }}>
                    <p style={cetakText}>: {tglKontrak}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Pinjaman</p>
                  </div>
                  <div style={{ marginLeft: "56px" }}>
                    <p style={cetakText}>
                      : {pinjamanAju.toLocaleString("de-DE")}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Angs. / Bulan</p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <p style={cetakText}>
                      : {angPerBulan.toLocaleString("de-DE")}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Thn. / Warna</p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <p style={cetakText}>
                      : {tahun} / {kodeWarna}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Pinjaman</p>
                  </div>
                  <div style={{ marginLeft: "60px" }}>
                    <p style={cetakText}>
                      : {pinjamanAju.toLocaleString("de-DE")}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Bunga</p>
                  </div>
                  <div style={{ marginLeft: "80px" }}>
                    <p style={cetakText}>
                      : {(totalPiutang - pinjamanAju).toLocaleString("de-DE")}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Total Piutang</p>
                  </div>
                  <div style={{ marginLeft: "36px" }}>
                    <p style={cetakText}>
                      : {totalPiutang.toLocaleString("de-DE")}
                    </p>
                  </div>
                </div>
              </div>
              <div style={cetakWrapSecond}>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Tipe</p>
                  </div>
                  <div style={{ marginLeft: "80px" }}>
                    <p style={cetakText}>: {kodeTipe}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>No. Rangka</p>
                  </div>
                  <div style={{ marginLeft: "28px" }}>
                    <p style={cetakText}>: {noRangka}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>No. Mesin</p>
                  </div>
                  <div style={{ marginLeft: "40px" }}>
                    <p style={cetakText}>: {noMesin}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Nopol</p>
                  </div>
                  <div style={{ marginLeft: "70px" }}>
                    <p style={cetakText}>: {nopol}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>No. Pengajuan</p>
                  </div>
                  <div style={{ marginLeft: "60px" }}>
                    <p style={cetakText}>: {noAju}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Tgl. Angs. I</p>
                  </div>
                  <div style={{ marginLeft: "32px" }}>
                    <p style={cetakText}>: {tglAng}</p>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <p style={cetakText}>Tgl. Angs. Akhir</p>
                  </div>
                  <div style={{ marginLeft: "2px" }}>
                    <p style={cetakText}>: {tglAngAkhir}</p>
                  </div>
                </div>
              </div>
            </div>
            <table style={{ fontSize: "10px" }} class="styled-table">
              <thead>
                <tr>
                  <th>Tgl. Bayar</th>
                  <th>TJ. Tempo</th>
                  <th>Keterlambatan</th>
                  <th>No. Kwitansi</th>
                  <th>Ke</th>
                  <th>Hutang Denda</th>
                  <th>A. Modal</th>
                  <th>Saldo</th>
                  <th>A. Bunga</th>
                  <th>Saldo</th>
                  <th>Bayar</th>
                  <th>Saldo</th>
                </tr>
              </thead>
              <tbody>{listRinci}</tbody>
            </table>
          </div>
        </div>
      )}

      <Form>
        <Card>
          <Card.Header>Data Pribadi Nasabah</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK / CIF :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control value={nikCustomer} disabled readOnly />
                      </Col>
                      <Col sm="4">
                        <Form.Control
                          required
                          value={cifCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKkCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tempatLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tanggalLahirCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisKelaminCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={agamaCustomer} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noTeleponCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={statusPerkawinanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kewarganegaraanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Resiko :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jenisResikoAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={pekerjaanCustomer}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Card style={{ marginTop: 10, marginBottom: 10 }}>
                  <Card.Header>Tingkat Risiko</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kelompok Kerja :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={tingkatRisiko}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPU :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                tingkatRisikoData.tppuTingkatRisiko
                              )}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            TPPT :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                tingkatRisikoData.tpptTingkatRisiko
                              )}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            PPSPM :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={findTingkatRisiko(
                                tingkatRisikoData.ppspmTingkatRisiko
                              )}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={ketResikoAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupaten}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePos} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{ marginTop: "10px" }}>
                  <Card.Header>Tempat Tinggal</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={alamatTTCustomer}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKelurahanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKecamatanTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeKabupatenTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={kodeProvinsiTT}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={kodePosTT} disabled readOnly />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penjamin</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        NIK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nikPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noKkPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tempat Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tempatLahirPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Lahir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tanggalLahirPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Kelamin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={jenisKelaminPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Agama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={agamaPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Telp / HP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noTeleponPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Status Perkawinan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={statusPerkawinanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pekerjaan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pekerjaanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kewarganegaraan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kewarganegaraanPenjamin}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={namaRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={alamatRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={teleponRefRegister}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Card>
                  <Card.Header>Alamat (Identitas)</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              required
                              value={alamatPenjamin}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kel. / Desa :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKelurahanPenjamin}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kecamatan :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKecamatanPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kabupaten / Kota :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeKabupatenPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Provinsi :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodeProvinsiPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kode POS :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kodePosPenjamin}
                              readOnly
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Pinjaman</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Pengajuan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noAju} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Pinjaman Rp. :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={pinjamanAju.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran/Bulan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={angPerBulan.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tenor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tenor}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Bunga/Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bungaPerTahun.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={totalPiutang.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tanggalAju}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang 1 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tglAng}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Ang Akhir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tglAngAkhir}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kategori :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kategori}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Surveyor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeSurveyor}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Marketing :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeMarketing}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kas :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeCOA} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Kendaraan</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeTipe}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tahun}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noRangka} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Mesin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noMesin} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Polisi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nopol}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Warna :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={kodeWarna}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Merk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={merk} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        CC :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={isi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Stnk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={tglStnk}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. BPKP :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={noBpkb}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        BPKP A/N :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={bpkbAN}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Penilaian</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Harga Pasar Setempat/Otr :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={hargaPasarSetempatOtr.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Taksiran :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={taksiran.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="7" style={textRight}>
                        Maksimal Pemberian Pinjaman :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          required
                          value={maksimalPemberianPinjaman.toLocaleString(
                            "de-DE"
                          )}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}></Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Asuransi</Card.Header>
          <Card.Body>
            <Box sx={textFieldContainer}>
              <Box sx={textFieldWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Fidusia :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noFidusia} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nilai Pertanggungan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          required
                          value={nilaiPertanggungan.toLocaleString("de-DE")}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[textFieldWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Asuransi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noAsuransi} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Card.Body>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <Card.Header>Data Approval</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    No. Perjanjian :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={noPerjanjian} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Kontrak :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglKontrak} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Ang 1 :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglAng} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tenor :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tenor} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tgl. Ang Akhir :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={tglAngAkhir} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Pinjaman Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={pinjamanAju.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Bunga / Tahun Rp. :
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      value={`${bungaPerTahun} %`}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      value={(
                        (parseFloat(bungaPerTahun) * parseFloat(pinjamanAju)) /
                        100
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Total Bunga Rp. :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={(
                        (angPerBulan - pinjamanAju / tenor) *
                        tenor
                      ).toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>

      <Card style={{ marginTop: 10 }}>
        <Card.Header>Tabel Angsuran</Card.Header>
        <Card.Body>
          <Chip
            label="Percepatan"
            sx={{
              backgroundColor: Colors.blue100,
              mt: 2,
            }}
          />
          <Box sx={tableContainer}>
            <ShowTableAngsuran
              id={id}
              currentPosts={currentPosts}
              totalSaldo={totalPiutang + angPerBulan}
              dataStart={page > 1 ? PER_PAGE : 0}
            />
          </Box>
          <Box sx={tableContainer}>
            <Pagination
              count={count}
              page={page}
              onChange={handleChange}
              color="primary"
              size={screenSize <= 600 ? "small" : "large"}
            />
          </Box>
        </Card.Body>
      </Card>

      <Card style={{ marginTop: 10 }}>
        <Card.Header>History Transaksi</Card.Header>
        <Card.Body>
          <table class="styled-table" style={{ fontSize: "10px" }}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>No. Kwitansi</th>
                <th></th>
                <th></th>
                <th colspan="2">Debet</th>
                <th colspan="2">Kredit</th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>No</th>
                <th>Tanggal</th>
                <th>Bukti</th>
                <th>No. SBG</th>
                <th>No. Perjanjian</th>
                <th>Akun</th>
                <th>Jumlah</th>
                <th>Akun</th>
                <th>Jumlah</th>
                <th>Keterangan</th>
                <th>Kasir</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TampilAngsuranFidusia;

const buttonModifierContainer = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  mb: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const cetakContainer = {
  width: "1100px",
  fontSize: "16px",
  letterSpacing: "0.01px",
  fontFamily: "sans-serif",
  width: "1000px",
  padding: "20px",
};

const cetakWrapper = {
  display: "flex",
  flexDirection: "row",
  marginTop: "20px",
  marginBottom: "20px",
};

const cetakWrap = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};

const cetakWrapSecond = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginLeft: "4px",
};

const cetakText = {
  marginTop: "0px",
  marginBottom: "0px",
};

const cetakCenterBold = {
  textAlign: "center",
  marginTop: "0px",
  marginBottom: "0px",
  fontWeight: "700",
};

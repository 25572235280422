import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LaporanJualBarang = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  let [sampaiTanggal, setSampaiTanggal] = useState(
    new Date(user.tutupperiode.sampaiTanggal)
  );
  let [dariTanggal, setDariTanggal] = useState(
    new Date(user.tutupperiode.dariTanggal)
  );
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");

  const [cabangs, setCabangs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapJualBarangsData, setLapJualBarangsData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);
  const [error, setError] = useState(false);

  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanJualBarang",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let laporanJualBarang = await axios.post(`${tempUrl}/laporanJualBarang`, {
        dariTanggal,
        sampaiTanggal,
        kodeCabang,
        _id: user.id,
        token: user.token,
      });
      setLapJualBarangsData(laporanJualBarang.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getCabangsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textBold = {
    fontWeight: 700,
    border: "1px solid black",
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const textNumberRightBold = {
    textAlign: "right",
    fontWeight: 700,
    border: "1px solid black",
  };

  const list = [];

  let tempTotalNilaiAgunan = 0;
  let tempTotalNilaiJual = 0;
  let tempTotalPokok = 0;
  let tempTotalBunga = 0;
  let tempTotalDenda = 0;
  let tempTotalPendapatanPenjualan = 0;
  let tempTotalTitipanKonsumen = 0;

  for (let j = 0; j < lapJualBarangsData.length; j++) {
    tempTotalNilaiAgunan += lapJualBarangsData[j].jaminan.nilaiJual;
    tempTotalNilaiJual += lapJualBarangsData[j].nilaiJual;
    tempTotalPokok += lapJualBarangsData[j].pokok;
    tempTotalBunga += lapJualBarangsData[j].bunga;
    tempTotalDenda += lapJualBarangsData[j].denda;
    tempTotalPendapatanPenjualan += lapJualBarangsData[j].pendapatanPenjualan;
    tempTotalTitipanKonsumen += lapJualBarangsData[j].titipanKonsumen;
    list.push(
      <tr style={tableText}>
        <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
          {lapJualBarangsData[j].no}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapJualBarangsData[j].noJual}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapJualBarangsData[j].tglJualFormat}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapJualBarangsData[j].jaminan.pengajuan.noSbg}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapJualBarangsData[j].jaminan.pengajuan.customer.namaCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapJualBarangsData[j].tglApprovalFormat}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapJualBarangsData[j].jaminan.nilaiJual.toLocaleString("en-EN")
            : lapJualBarangsData[j].jaminan.nilaiJual}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapJualBarangsData[j].nilaiJual.toLocaleString("en-EN")
            : lapJualBarangsData[j].nilaiJual}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapJualBarangsData[j].pokok.toLocaleString("en-EN")
            : lapJualBarangsData[j].pokok}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapJualBarangsData[j].bunga.toLocaleString("en-EN")
            : lapJualBarangsData[j].bunga}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapJualBarangsData[j].denda.toLocaleString("en-EN")
            : lapJualBarangsData[j].denda}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapJualBarangsData[j].pendapatanPenjualan.toLocaleString("en-EN")
            : lapJualBarangsData[j].pendapatanPenjualan}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapJualBarangsData[j].titipanKonsumen.toLocaleString("en-EN")
            : lapJualBarangsData[j].titipanKonsumen}
        </td>
      </tr>
    );
  }

  list.push(
    <tr style={tableText}>
      <td style={textBold} colspan="6">
        TOTAL :
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalNilaiAgunan.toLocaleString("en-EN")
          : tempTotalNilaiAgunan}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalNilaiJual.toLocaleString("en-EN")
          : tempTotalNilaiJual}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalPokok.toLocaleString("en-EN")
          : tempTotalPokok}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalBunga.toLocaleString("en-EN")
          : tempTotalBunga}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalDenda.toLocaleString("en-EN")
          : tempTotalDenda}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalPendapatanPenjualan.toLocaleString("en-EN")
          : tempTotalPendapatanPenjualan}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalTitipanKonsumen.toLocaleString("en-EN")
          : tempTotalTitipanKonsumen}
      </td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanJualBarang",
    sheet: "LaporanJualBarang",
  });

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Penjualan Barang</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Dari :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={dariTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setDariTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={sampaiTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setSampaiTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Penjualan Barang
          </h5>
          <p>
            Dari Tanggal :
            {` ${dariTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(dariTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${dariTanggal.getFullYear()}`}
          </p>
          <p>
            Sampai Tanggal :
            {` ${sampaiTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(sampaiTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${sampaiTanggal.getFullYear()}`}
          </p>
          <p>Cabang : {namaCabang}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No.</th>
                <th style={{ border: "1px solid black" }}>No. Jual</th>
                <th style={{ border: "1px solid black" }}>Tgl. Jual</th>
                <th style={{ border: "1px solid black" }}>No. Sbg</th>
                <th style={{ border: "1px solid black" }}>Nama Konsumen</th>
                <th style={{ border: "1px solid black" }}>Tgl. App</th>
                <th style={{ border: "1px solid black" }}>Nilai Agunan</th>
                <th style={{ border: "1px solid black" }}>Nilai Jual</th>
                <th style={{ border: "1px solid black" }}>Pokok</th>
                <th style={{ border: "1px solid black" }}>Bunga</th>
                <th style={{ border: "1px solid black" }}>Denda</th>
                <th style={{ border: "1px solid black" }}>Pend. Penjualan</th>
                <th style={{ border: "1px solid black" }}>Titipan</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanJualBarang;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "10px",
  fontFamily: "sans-serif",
  width: "1000px",
};

import React, { useState, useEffect, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Container, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const LaporanTunggakanFidusiaRinci = () => {
  const { screenSize } = useStateContext();
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  let nowDate = new Date();
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaCabang, setNamaCabang] = useState("");
  let [perTanggal, setPerTanggal] = useState(new Date());
  const [sisaBulan, setSisaBulan] = useState("");
  const [kasirId, setKasirId] = useState("");
  const [namaKasir, setNamaKasir] = useState("");
  const [marketingId, setMarketingId] = useState("");
  const [namaMarketing, setNamaMarketing] = useState("");
  const [surveyorId, setSurveyorId] = useState("");
  const [namaSurveyor, setNamaSurveyor] = useState("");
  const [kategori, setKategori] = useState("");
  const [lebihDari, setLebihDari] = useState("1");
  const [sampaiDengan, setSampaiDengan] = useState("30");

  const [cabangs, setCabangs] = useState([]);
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [kasirs, setKasirs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const [lapTotalPiutangData, setLapTotalPiutangData] = useState([]);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [isCetakExcel, setIsCetakExcel] = useState(false);
  const [error, setError] = useState(false);

  const kategoriOption = ["KENDARAAN RODA 2", "KENDARAAN RODA 4"];
  let marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
    id: marketing.id,
  }));
  let surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
    id: surveyor.id,
  }));
  let cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang.id} - ${cabang.namaCabang}`,
    id: `${cabang.id} - ${cabang.namaCabang}`,
  }));
  let kasirOptions = kasirs.map((kasir) => ({
    label: kasir.username,
    id: kasir.id,
  }));

  const generatePDF = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "LaporanTunggakanFidusiaRinci",
  });

  const tampilPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      let tempPersenLoading = parseInt(persenLoading);

      setTimeout(() => {
        tempPersenLoading += 40;
        setPersenLoading(tempPersenLoading.toString());
      }, "1000");

      let totalPiutangs = await axios.post(
        `${tempUrl}/laporanTunggakanFidusiaRinci`,
        {
          kodeCabang,
          perTanggal,
          marketingId,
          surveyorId,
          kasirId,
          sisaBulan,
          kategori,
          lebihDari,
          sampaiDengan,
          _id: user.id,
          token: user.token,
        }
      );
      setLapTotalPiutangData(totalPiutangs.data);
      setPreviewPdf(!previewPdf);
      setLoading(false);
      setTimeout(() => {
        tempPersenLoading = 0;
        setPersenLoading(tempPersenLoading.toString());
      }, "2000");
    }
  };

  useEffect(() => {
    getCabangsData();
    getKasirsData();
    getMarketingsData();
    getSurveyorsData();
    if (user.tipeUser !== "OWNER/DIREKSI") {
      setKodeCabang(user.cabang.id);
      setNamaCabang(`${user.cabang.id} - ${user.cabang.namaCabang}`);
    }
  }, []);

  const getSurveyorsData = async () => {
    const response = await axios.post(`${tempUrl}/surveyors`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setSurveyors(response.data);
  };

  const getMarketingsData = async () => {
    const response = await axios.post(`${tempUrl}/marketings`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setMarketings(response.data);
  };

  const getKasirsData = async () => {
    const response = await axios.post(`${tempUrl}/users`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKasirs(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setCabangs(response.data);
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const textBold = {
    fontWeight: 700,
    border: "1px solid black",
  };

  const textNumberRight = {
    textAlign: "right",
    border: "1px solid black",
  };

  const textNumberRightBold = {
    textAlign: "right",
    fontWeight: 700,
    border: "1px solid black",
  };

  const list = [];
  let tempNo = 1;
  let tempTotalModal = 0;
  let tempTotalBunga = 0;
  let tempTotalSisaHutang = 0;
  let tempTotalSisaAngsuran = 0;
  let tempTotalOutstanding = 0;
  let tempTotal = 0;

  for (let j = 0; j < lapTotalPiutangData.length; j++) {
    tempTotalModal += lapTotalPiutangData[j].angModal;
    tempTotalBunga += lapTotalPiutangData[j].angBunga;
    tempTotal += lapTotalPiutangData[j].angPerBulan;
    tempTotalSisaHutang +=
      lapTotalPiutangData[j].angModal * lapTotalPiutangData[j].sisaBulan;
    tempTotalSisaAngsuran +=
      lapTotalPiutangData[j].angPerBulan * lapTotalPiutangData[j].sisaBulan;
    tempTotalOutstanding = lapTotalPiutangData[j].totalOutstanding;

    let totalSisaHutang =
      lapTotalPiutangData[j].angModal * lapTotalPiutangData[j].sisaBulan;
    let totalSisaAngsuran =
      lapTotalPiutangData[j].angPerBulan * lapTotalPiutangData[j].sisaBulan;

    list.push(
      <tr style={tableText}>
        <td style={{ paddingLeft: "15px", border: "1px solid black" }}>
          {tempNo}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].tglApproval}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].customer.namaCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].noPerjanjian}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].customer.alamatTTCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].customer.noTeleponCustomer}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].nopol}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].noMesin}
        </td>
        <td style={{ border: "1px solid black" }}>
          {`${lapTotalPiutangData[j].tipe.merk} - ${lapTotalPiutangData[j].tipe.kodeTipe}`}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].tahun}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].angModal.toLocaleString("en-EN")
            : lapTotalPiutangData[j].angModal}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].angBunga.toLocaleString("en-EN")
            : lapTotalPiutangData[j].angBunga}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].angPerBulan.toLocaleString("en-EN")
            : lapTotalPiutangData[j].angPerBulan}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].tenor.toLocaleString("en-EN")
            : lapTotalPiutangData[j].tenor}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? lapTotalPiutangData[j].sisaBulan.toLocaleString("en-EN")
            : lapTotalPiutangData[j].sisaBulan}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? (
                lapTotalPiutangData[j].tenor - lapTotalPiutangData[j].sisaBulan
              ).toLocaleString("en-EN")
            : lapTotalPiutangData[j].tenor - lapTotalPiutangData[j].sisaBulan}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? totalSisaHutang.toLocaleString("en-EN")
            : totalSisaHutang}
        </td>
        <td style={textNumberRight}>
          {isCetakExcel === false
            ? `${(
                (totalSisaHutang / lapTotalPiutangData[j].totalOutstanding) *
                100
              )
                .toFixed(2)
                .toLocaleString("en-EN")}%`
            : (
                (totalSisaHutang / lapTotalPiutangData[j].totalOutstanding) *
                100
              ).toFixed(2)}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].tglJtTempo}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].hr}
        </td>
        <td style={{ border: "1px solid black" }}>
          {`${lapTotalPiutangData[j].marketing.kodeMarketing} - ${lapTotalPiutangData[j].marketing.namaMarketing}`}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].surveyor &&
            `${lapTotalPiutangData[j].surveyor.kodeSurveyor} - ${lapTotalPiutangData[j].surveyor.namaSurveyor}`}
        </td>
        <td style={{ border: "1px solid black" }}>
          {lapTotalPiutangData[j].kasir}
        </td>
      </tr>
    );

    tempNo++;
  }

  list.push(
    <tr style={tableText}>
      <td style={textBold} colspan="16">
        TOTAL :
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? tempTotalSisaHutang.toLocaleString("en-EN")
          : tempTotalSisaHutang}
      </td>
      <td style={textNumberRightBold}>
        {isCetakExcel === false
          ? `${((tempTotalSisaHutang / tempTotalOutstanding) * 100)
              .toFixed(2)
              .toLocaleString("en-EN")}%`
          : ((tempTotalSisaHutang / tempTotalOutstanding) * 100).toFixed(2)}
      </td>
      <td style={{ border: "1px solid black" }} colSpan="5"></td>
    </tr>
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "LaporanTunggakanFidusiaRinci",
    sheet: "LaporanTunggakanFidusiaRinci",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Container>
      <h3>Laporan</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Total Tunggakan Fidusia Rinci</h5>
      <hr />
      <Box sx={spacingTop}>
        <Form onSubmit={tampilPdf}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Cabang :
                </Form.Label>
                <Col sm="8">
                  {user.tipeUser === "OWNER/DIREKSI" ? (
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cabangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeCabang.length === 0 && true}
                          size="small"
                          placeholder="SEMUA CABANG"
                          {...params}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          setKodeCabang(value.id.split(" ", 1)[0]);
                          setNamaCabang(value.id);
                        } else {
                          setKodeCabang("");
                          setNamaCabang("");
                        }
                        setPreviewPdf(false);
                      }}
                      defaultValue={kodeCabang}
                    />
                  ) : (
                    <Form.Control value={kodeCabang} disabled readOnly />
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Per Tanggal :
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    required
                    dateFormat="dd/MM/yyyy"
                    selected={perTanggal}
                    customInput={<Form.Control required />}
                    onChange={(date) => {
                      setPerTanggal(date);
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Marketing :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={marketingOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && marketingId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA MARKETING"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setMarketingId(value.id);
                        setNamaMarketing(value.label);
                      } else {
                        setMarketingId("");
                        setNamaMarketing("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={marketingId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Surveyor :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={surveyorOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && surveyorId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA SURVEYOR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setSurveyorId(value.id);
                        setNamaSurveyor(value.label);
                      } else {
                        setSurveyorId("");
                        setNamaSurveyor("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={namaSurveyor}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kasir :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kasirOptions}
                    renderInput={(params) => (
                      <TextField
                        error={error && kasirId.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KASIR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKasirId(value.id);
                        setNamaKasir(value.label);
                      } else {
                        setKasirId("");
                        setNamaKasir("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kasirId}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kategori :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={kategoriOption}
                    renderInput={(params) => (
                      <TextField
                        error={error && kategori.length === 0 && true}
                        size="small"
                        placeholder="SEMUA KATEGORI"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      if (value) {
                        setKategori(value);
                      } else {
                        setKategori("");
                      }
                      setPreviewPdf(false);
                    }}
                    defaultValue={kategori}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sisa Bulan :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={sisaBulan}
                    onChange={(e) => {
                      setSisaBulan(e.target.value.toUpperCase());
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Lebih Dari :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={lebihDari}
                    onChange={(e) => {
                      setLebihDari(e.target.value.toUpperCase());
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Sampai Dengan :
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={sampaiDengan}
                    onChange={(e) => {
                      setSampaiDengan(e.target.value.toUpperCase());
                      setPreviewPdf(false);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            type="submit"
            style={{ marginTop: "20px" }}
          >
            LIHAT
          </Button>
        </Form>
      </Box>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <ButtonGroup variant="outlined" color="secondary">
            <Button startIcon={<PrintIcon />} onClick={generatePDF}>
              PDF
            </Button>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsCetakExcel(true);
                setTimeout(() => {
                  onDownload();
                }, "1000");
              }}
            >
              Excel
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div ref={reportTemplateRef} id="content" style={pdfContainer}>
        <div style={{ visibility: previewPdf === true ? "visible" : "hidden" }}>
          <p>
            {setting.namaPerusahaan} - {setting.alamatPerusahaan}
          </p>
          <p>({setting.kabupatenPerusahaan})</p>
          <p>{setting.kotaPerusahaan}</p>
          <p>NO. TELP. {setting.teleponPerusahaan}</p>
          <p>
            Dicetak Oleh: {user.username} | Tanggal:
            {` ${nowDate.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(nowDate.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${nowDate.getFullYear()} `}{" "}
            | Jam:{" "}
            {` ${nowDate.getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}:${nowDate.getSeconds().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })} `}
          </p>
          <h5 style={{ textAlign: "center", fontWeight: "700" }}>
            Laporan Total Tunggakan Fidusia Rinci
          </h5>
          <p>Cabang : {namaCabang}</p>
          <p>
            Per Tanggal :{" "}
            {` ${perTanggal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${(perTanggal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${perTanggal.getFullYear()} `}
          </p>
          <p>Marketing : {namaMarketing}</p>
          <p>Surveyor : {namaSurveyor}</p>
          <p>Kasir : {namaKasir}</p>
          <p>Sisa Bulan : {sisaBulan}</p>
          <p>Kategori : {kategori}</p>
          <p></p>
          <table class="styled-table" ref={tableRef}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black" }}>No</th>
                <th style={{ border: "1px solid black" }}>Tgl.</th>
                <th style={{ border: "1px solid black" }}>Nama</th>
                <th style={{ border: "1px solid black" }}>No. Perjanjian</th>
                <th style={{ border: "1px solid black" }}>Alamat</th>
                <th style={{ border: "1px solid black" }}>No. Telp</th>
                <th style={{ border: "1px solid black" }}>Nopol</th>
                <th style={{ border: "1px solid black" }}>No. Mesin</th>
                <th style={{ border: "1px solid black" }}>Tipe</th>
                <th style={{ border: "1px solid black" }}>Thn</th>
                <th style={{ border: "1px solid black" }}>P. Modal</th>
                <th style={{ border: "1px solid black" }}>P. Bunga</th>
                <th style={{ border: "1px solid black" }}>Total</th>
                <th style={{ border: "1px solid black" }}>Tenor</th>
                <th style={{ border: "1px solid black" }}>S. Bln</th>
                <th style={{ border: "1px solid black" }}>Ang. Ke-</th>
                <th style={{ border: "1px solid black" }}>Sisa Hutang</th>
                <th style={{ border: "1px solid black" }}>NPL</th>
                <th style={{ border: "1px solid black" }}>Tgl. Jt Tempo</th>
                <th style={{ border: "1px solid black" }}>Hr</th>
                <th style={{ border: "1px solid black" }}>Marketing</th>
                <th style={{ border: "1px solid black" }}>Surveyor</th>
                <th style={{ border: "1px solid black" }}>Kasir</th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default LaporanTunggakanFidusiaRinci;

const spacingTop = {
  mt: 4,
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};

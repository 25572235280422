import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Alert, Button, Snackbar } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const TambahTingkatRisiko = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeTingkatRisiko, setKodeTingkatRisiko] = useState("");
  const [namaTingkatRisiko, setNamaTingkatRisiko] = useState("");
  const [tppuTingkatRisiko, setTppuTingkatRisiko] = useState("1");
  const [tpptTingkatRisiko, setTpptTingkatRisiko] = useState("1");
  const [ppspmTingkatRisiko, setPpspmTingkatRisiko] = useState("1");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let pilihanTingkatRisikos = [
    {
      id: 1,
      nama: "Tinggi",
    },
    {
      id: 2,
      nama: "Sedang",
    },
    {
      id: 3,
      nama: "Rendah",
    },
  ];

  useEffect(() => {
    getTingkatRisikoNextKode();
  }, []);

  const getTingkatRisikoNextKode = async () => {
    const response = await axios.post(`${tempUrl}/tingkatRisikoNextKode`, {
      _id: user.id,
      token: user.token,
      kodeCabang: user.cabang.id,
    });
    setKodeTingkatRisiko(response.data);
  };

  const saveTingkatRisiko = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveTingkatRisiko`, {
          kodeTingkatRisiko,
          namaTingkatRisiko,
          tppuTingkatRisiko,
          tpptTingkatRisiko,
          ppspmTingkatRisiko,
          kodeCabang: user.cabang.id,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
        });
        setLoading(false);
        navigate("/tingkatRisiko");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Tingkat Risiko</h5>
      <hr />
      <Card>
        <Card.Header>Tingkat Risiko</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveTingkatRisiko}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Kode :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={kodeTingkatRisiko}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      value={namaTingkatRisiko}
                      onChange={(e) => setNamaTingkatRisiko(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    TPPU <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      required
                      value={tppuTingkatRisiko}
                      onChange={(e) => {
                        setTppuTingkatRisiko(e.target.value);
                      }}
                    >
                      {pilihanTingkatRisikos.map(
                        (pilihanTingkatRisiko, index) => (
                          <option value={pilihanTingkatRisiko.id}>
                            {pilihanTingkatRisiko.id} -{" "}
                            {pilihanTingkatRisiko.nama}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    TPPT <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      required
                      value={tpptTingkatRisiko}
                      onChange={(e) => {
                        setTpptTingkatRisiko(e.target.value);
                      }}
                    >
                      {pilihanTingkatRisikos.map(
                        (pilihanTingkatRisiko, index) => (
                          <option value={pilihanTingkatRisiko.id}>
                            {pilihanTingkatRisiko.id} -{" "}
                            {pilihanTingkatRisiko.nama}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    PPSPM <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      required
                      value={ppspmTingkatRisiko}
                      onChange={(e) => {
                        setPpspmTingkatRisiko(e.target.value);
                      }}
                    >
                      {pilihanTingkatRisikos.map(
                        (pilihanTingkatRisiko, index) => (
                          <option value={pilihanTingkatRisiko.id}>
                            {pilihanTingkatRisiko.id} -{" "}
                            {pilihanTingkatRisiko.nama}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/tingkatRisiko")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahTingkatRisiko;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};

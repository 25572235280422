import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

// export const tempUrl = "https://gadaiapi.techkuid.com";
// export const tempUrl = "https://gadaiapi.megagroupbatam.com";
export const tempUrl = "https://gadaiapi.megagroupbatam1.com";
// export const tempUrl = "http://localhost:5000";
// export const tempUrlFico = "https://gadaificoapi.techkuid.com";
// export const tempUrlFico = "https://gadaificoapi.megagroupbatam.com";
export const tempUrlFico = "https://gadaificoapi.megagroupbatam1.com";
// export const tempUrlFico = "http://localhost:5001";

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);

  return (
    <StateContext.Provider
      value={{
        screenSize,
        setScreenSize,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
